<div class="container-for-mobile">
  @if (showOtherFilters) {
    <div class="search-elements-container">
      <div class="mobile-search-header">
        <div>
          <h2>Rechercher</h2>
          <img
            ngSrc="assets/icons/close.svg"
            alt="Close"
            height="30"
            width="30"
            tabindex="0"
            (click)="toggleOtherFilters()"
            (keyup.enter)="toggleOtherFilters()" />
        </div>
        <div class="separator"></div>
      </div>

      <h4>Activité :</h4>
      <span class="span-filter">
        <app-select
          [(ngModel)]="this.params.activityOption"
          (changed)="onActivityChange($event.id)"
          [items]="activitesObject"
          size="fit" />
      </span>

      <div class="extraction">
        <h4>Extraction :</h4>
        <div class="check-box">
          <label for="checkbox-mobile">
            <mat-checkbox
              type="checkbox"
              id="checkbox-mobile"
              [(ngModel)]="this.params.checkboxValues.with"
              (change)="
                onCheckboxChange($event.checked ? 'with' : 'without')
              " />
          </label>
        </div>
      </div>

      <h4>Formule juridique :</h4>
      <span class="span-filter">
        <app-select
          [(ngModel)]="this.params.legalformOption"
          [items]="legalFormObject"
          (changed)="onLegalformChange($event.id)"
          size="fit" />
      </span>

      <h4>Statut :</h4>
      <span class="span-filter">
        <app-select
          [(ngModel)]="this.params.statusOption"
          (changed)="onStatusChange($event)"
          [items]="activeMandatStateObject"
          size="fit" />
      </span>

      <h4>Consultant :</h4>
      <span class="span-filter">
        <app-select
          [(ngModel)]="this.params.selectedConsultant"
          (changed)="onConsultantChange(+$event.id)"
          [items]="consultants"
          size="fit" />
      </span>
      <div class="separator"></div>

      <h4>Prix :</h4>
      <div class="price-filter margin-left-right">
        <app-input-slide
          inputType="price"
          placeholder="Prix min"
          width="fit"
          [(ngModel)]="this.params.minPrice"
          (input)="onMinPriceChange($any($event.target).value)"
          id="min-price"
          type="number" />

        <app-input-slide
          inputType="price"
          placeholder="Prix max"
          width="fit"
          (input)="onMaxPriceChange($any($event.target).value)"
          [(ngModel)]="this.params.maxPrice"
          id="max-price"
          type="number" />
      </div>

      <h4>Surface :</h4>
      <div class="price-filter margin-left-right">
        <app-input-slide
          inputType="surface"
          placeholder="Surface min"
          width="fit"
          [(ngModel)]="this.params.minSurface"
          (input)="onMinSurfaceChange($any($event.target).value)"
          id="min-surface"
          type="number" />

        <app-input-slide
          inputType="surface"
          placeholder="Surface max"
          width="fit"
          [(ngModel)]="this.params.maxSurface"
          (input)="onMaxSurfaceChange($any($event.target).value)"
          type="number"
          id="max-surface" />
      </div>
      <div class="separator"></div>

      <h4>Ville :</h4>
      <span class="span-filter">
        <app-input
          size="fit"
          (input)="onCityChange($any($event.target).value)"
          [(ngModel)]="this.params.cityValue" />
      </span>

      <h4>Rue :</h4>
      <span class="span-filter">
        <app-input
          size="fit"
          (input)="onStreetChange($any($event.target).value)"
          [(ngModel)]="this.params.streetValue" />
      </span>
    </div>
    <div class="d-flex justify-evenly mobile-search-bar">
      <app-btn
        (btnClick)="resetFilters()"
        class="validate-button"
        color="alerte"
        size="small"
        text="Effacer" />
      <app-btn
        color="validate"
        size="medium"
        [text]="rechercheText"
        (btnClick)="toggleOtherFilters()"
        class="validate-button"
        >Validate
      </app-btn>
    </div>
  }

  @if (!showOtherFilters) {
    <div class="mobile-header">
      <p class="text-center">
        {{ filteredMandats.length }}
        {{ isProspection ? 'fiches de prospection' : 'mandats actifs' }}
      </p>
    </div>
    <div class="search-bar mt-10">
      <app-search-bar
        [(ngModel)]="params.searchValue"
        (search)="onSearchChange($event)"></app-search-bar>
      <div style="position: relative; display: inline-block">
        <img
          ngSrc="assets/icons/filter.svg"
          alt="Filter"
          height="34"
          width="34"
          tabindex="0"
          (keyup.enter)="toggleOtherFilters()"
          (click)="toggleOtherFilters()" />
        @if (this.activeFilterCount > 0) {
          <span
            (click)="toggleOtherFilters()"
            tabindex="1"
            (keyup.enter)="toggleOtherFilters()"
            class="filter-count"
            >{{ activeFilterCount }}</span
          >
        }
      </div>
    </div>
  }
</div>

<div class="container-for-desktop">
  <div class="first-selection-container">
    <div class="select-container">
      <app-select
        (changed)="onActivityChange($event.id)"
        [(ngModel)]="this.params.activityOption"
        [items]="activitesObject"
        fontSize="small"
        placeholder="Activités" />

      <app-select
        (changed)="onLegalformChange($event.id)"
        [(ngModel)]="this.params.legalformOption"
        [items]="legalFormObject"
        fontSize="small"
        placeholder="Formule juridique" />
    </div>
    <div class="select-container">
      <div *ngIf="showStatus">
        <app-select
          (changed)="onStatusChange($event)"
          [(ngModel)]="this.params.statusOption"
          [items]="activeMandatStateObject"
          fontSize="small"
          placeholder="Statut" />
      </div>
      <app-select
        (changed)="onConsultantChange(+$event.id)"
        [(ngModel)]="this.params.selectedConsultant"
        [items]="consultants"
        fontSize="small"
        placeholder="Consultant" />
    </div>
  </div>

  <div class="second-selection-container">
    <div class="price-filter">
      <app-input-slide
        (input)="onMinPriceChange($any($event.target).value)"
        [(ngModel)]="this.params.minPrice"
        [forDesktop]="true"
        inputType="price"
        placeholder="Prix min"
        type="number"
        width="fit" />

      <app-input-slide
        (input)="onMaxPriceChange($any($event.target).value)"
        [(ngModel)]="this.params.maxPrice"
        [forDesktop]="true"
        inputType="price"
        placeholder="Prix max"
        type="number"
        width="fit" />
    </div>
    <div class="surface-filter">
      <app-input-slide
        (input)="onMinSurfaceChange($any($event.target).value)"
        [(ngModel)]="this.params.minSurface"
        [forDesktop]="true"
        inputType="surface"
        placeholder="Surface min"
        type="number"
        width="fit" />

      <app-input-slide
        (input)="onMaxSurfaceChange($any($event.target).value)"
        [(ngModel)]="this.params.maxSurface"
        [forDesktop]="true"
        inputType="surface"
        placeholder="Surface max"
        type="number"
        width="fit" />
    </div>
  </div>
  <div class="third-selection-container">
    <app-input-slide
      (input)="onStreetChange($any($event.target).value)"
      [(ngModel)]="this.params.streetValue"
      [forDesktop]="true"
      inputType="text"
      placeholder="Rue"
      type="text"
      width="fit" />
    <app-input-slide
      (input)="onCityChange($any($event.target).value)"
      [(ngModel)]="this.params.cityValue"
      [forDesktop]="true"
      fontSize="small"
      inputType="text"
      placeholder="Ville"
      type="text"
      width="fit" />
  </div>
</div>
<div class="desktop-search-bar">
  <app-search-bar
    (search)="onSearchChange($event)"
    [(ngModel)]="params.searchValue"
    [forDesktop]="true"></app-search-bar>
</div>
