<div class="agencies-header">
  <p>Liste des agences</p>
</div>
<app-agencies-filter
  (filterChange)="setDateRange($event)"
  (filterTrigger)="filterTrigger($event)" />

<table [dataSource]="tableDataSource" mat-table>
  <ng-container matColumnDef="id">
    <th *matHeaderCellDef class="id" mat-header-cell>Id</th>
    <td *matCellDef="let element" class="id" mat-cell>{{ element?.id }}</td>
  </ng-container>

  <ng-container matColumnDef="nom">
    <th *matHeaderCellDef class="nom" mat-header-cell>Nom</th>
    <td *matCellDef="let element" class="nom" mat-cell>
      {{ element?.nom }}
    </td>
  </ng-container>

  <ng-container matColumnDef="responsable">
    <th *matHeaderCellDef class="responsable" mat-header-cell>Responsable</th>
    <td *matCellDef="let element" class="responsable" mat-cell>
      {{ element?.prenom_responsable_cabinet }}
      {{ element?.nom_responsable_cabinet }}
    </td>
  </ng-container>

  <ng-container matColumnDef="raison">
    <th *matHeaderCellDef class="raison" mat-header-cell>Raison sociale</th>
    <td *matCellDef="let element" class="raison" mat-cell>
      {{ element?.raison_sociale }}
    </td>
  </ng-container>

  <ng-container matColumnDef="cheque">
    <th *matHeaderCellDef class="cheque" mat-header-cell>Chèque versement</th>
    <td *matCellDef="let element" class="cheque" mat-cell>
      {{ element?.cheque_versement }}
    </td>
  </ng-container>

  <ng-container matColumnDef="ville">
    <th *matHeaderCellDef class="ville" mat-header-cell>Ville</th>
    <td *matCellDef="let element" class="ville" mat-cell>
      {{ element?.ville }}
    </td>
  </ng-container>

  <ng-container matColumnDef="leboncoin">
    <th *matHeaderCellDef class="leboncoin" mat-header-cell>
      Nbr annonce Leboncoin
    </th>
    <td *matCellDef="let element" class="leboncoin" mat-cell>
      {{ element?.leboncoin_nombre_annonces }}
    </td>
  </ng-container>

  <ng-container matColumnDef="active">
    <th *matHeaderCellDef class="active" mat-header-cell>Active</th>
    <td *matCellDef="let element" class="active" mat-cell>
      <span [appCabinetActive]="element.active">{{
        element?.active | cabinetActive
      }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="ca">
    <th *matHeaderCellDef class="ca" mat-header-cell>
      Chiffre d&apos;affaires
    </th>
    <td *matCellDef="let element" class="ca" mat-cell>
      {{ calculateTurnover(element.id) | formatEuro }}
    </td>
  </ng-container>

  <tr
    *matHeaderRowDef="columnsToDisplay; sticky: true"
    class="sticky-header-cell"
    mat-header-row></tr>
  <tr *matRowDef="let row; columns: columnsToDisplay" mat-row></tr>
</table>
