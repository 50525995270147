<app-navbar></app-navbar>

<ng-container>
  <app-bottom-nav></app-bottom-nav>
</ng-container>
<div *ngIf="showCountdown" class="update-countdown">
  <div>
    <div>
      <p>
        Une mise à jour est disponible. L'application sera mise à jour dans
        <span class="countdown"> {{ countdownTime }} </span>secondes.
      </p>
      <p>Veuillez enregistrer vos modifications</p>
    </div>
    <app-btn
      (btnClick)="refresh()"
      size="large"
      text="Mettre à jour maintenant" />
  </div>
</div>
<div *ngIf="!showUpdate" class="main-content">
  <router-outlet></router-outlet>
</div>
<div *ngIf="showUpdate" class="update-message">
  <p>
    Veuillez raffraichir la page pour mettre à jour l'application ou appuyer sur
    le bouton ci-dessous.
  </p>
  <img alt="Done" src="assets/icons/Validate.png" />
  <app-btn
    (btnClick)="refresh()"
    class="update-btn"
    size="large"
    text="Mettre à jour maintenant" />
</div>
