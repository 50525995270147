import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-prospection-bottom-menu',
  templateUrl: './prospection-bottom-menu.component.html',
  styleUrl: './prospection-bottom-menu.component.scss',
})
export class ProspectionBottomMenuComponent {
  @Input() navigationGoback!: () => void;

  constructor(private readonly router: Router) {}
}
