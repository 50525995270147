@if (this.shouldDisplayForm) {
  <form [formGroup]="bailleurForm" class="create-form">
    <div class="civilite">
      <label class="required" for="civilite">Civilité </label>

      <app-select
        [items]="civiliteItems"
        class="form-control"
        formControlName="civilite"
        id="civilite"
        size="small" />
    </div>
    <div class="form-group">
      <app-select-contact
        (optionSelectedEvent)="onContactSelected($event)"
        [required]="true"
        class="form-control"
        formControlName="nom"
        placeholder="Nom"
        width="fit"></app-select-contact>
    </div>

    <div class="form-group">
      <app-input-slide
        class="form-control"
        formControlName="prenom"
        id="prenom"
        inputType="text"
        placeholder="Prénom"
        width="fit" />
    </div>
    <div class="form-group">
      <app-input-slide
        class="form-control"
        formControlName="mobile"
        id="mobile"
        inputType="text"
        placeholder="Téléphone mobile"
        width="fit" />
    </div>
    <div class="form-group">
      <app-input-slide
        class="form-control"
        formControlName="telephone"
        id="fixe"
        inputType="text"
        placeholder="Téléphone fixe"
        width="fit" />
    </div>
    <div class="form-group">
      <app-input-slide
        class="form-control"
        formControlName="email"
        id="email"
        inputType="text"
        placeholder="Email"
        width="fit" />
    </div>

    <div class="form-group">
      <app-google-places-autocomplete
        (formattedAddress)="onChange($event)"
        [initialValue]="getControl('formatted_address').value"
        placeholder="Adresse" />
    </div>
  </form>

  <app-btn size="large" text="Annuler" color="alerte"></app-btn>
}

@if (!this.shouldDisplayForm) {
  <app-contact-card-edit-mandat
    [civilite]="bailleurForm.get('civilite')?.value"
    [email]="bailleurForm.get('email')?.value"
    [mobile]="bailleurForm.get('mobile')?.value"
    [nom]="bailleurForm.get('nom')?.value"
    [prenom]="bailleurForm.get('prenom')?.value"
    (editContact)="editContact()"
    (switchContact)="changeContact()"></app-contact-card-edit-mandat>
}
