import { Pipe, PipeTransform } from '@angular/core';
import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';
import { MandatStateEnum } from '@features/mandats/domain/entities/mandat.interface';

@Pipe({
  name: 'mandatStateShortenedDisplayer',
  standalone: true,
})
export class MandatStateShortenedDisplayerPipe implements PipeTransform {
  transform(mandat: MandatDetailInterface): string {
    switch (mandat.mandat.etat) {
      case MandatStateEnum.judiciaryProcess:
        return 'PC';
      case MandatStateEnum.preMandat:
        return 'PM';
      case MandatStateEnum.prospection:
        return 'Prospection';
      default:
        return this.getRegistryNumber(mandat);
    }
  }

  private getRegistryNumber(mandat: MandatDetailInterface): string {
    if (mandat.mandat.numero_registre_mandats) {
      return mandat.mandat.numero_registre_mandats;
    } else {
      throw new Error('Aucune donnée valide pour transformation');
    }
  }
}
