import { ArchivateMandatDto } from '@features/mandats/domain/entities/archivate-mandat.interface';

export class LoadSilenceArchivedMandats {
  static readonly type = '[MandatsArchived] Load Silence MandatsArchived';
}

export class RemoveOtherAgenciesMandatsArchived {
  static readonly type =
    '[MandatsArchived] Remove Other Agencies MandatsArchived';
}

export class ArchivateMandat {
  static readonly type = '[MandatsArchived] Archivate Mandat';

  constructor(public payload: ArchivateMandatDto) {}
}
