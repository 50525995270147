<div class="archived-header">
  <img
    [height]="25"
    [routerLink]="['/mandats/archived/']"
    [width]="25"
    alt="Retour"
    ngSrc="../../../../../../../../assets/mandat-detail/arrow-left-white.svg" />

  <p>Mandat archivé n° {{ archivedMandat.numero_registre_mandats }}</p>
  <p class="date-archive">
    Archivé le {{ archivedMandat.date_archive?.date | date: 'dd/MM/yyyy' }}
  </p>
</div>
<div class="archived-header-mobile">
  <img
    [height]="25"
    [routerLink]="['/mandats/archived/']"
    [width]="25"
    alt="Retour"
    ngSrc="../../../../../../../../assets/mandat-detail/arrow-left-white.svg" />
  <p>Mandat archivé n° {{ archivedMandat.numero_registre_mandats }}</p>
  <p class="date-archive">
    {{ archivedMandat.date_archive?.date | date: 'dd/MM/yyyy' }}
  </p>
</div>
<div class="container">
  <div class="info">
    <h2>Informations du point de vente</h2>
    <p>{{ archivedMandat.enseigne | uppercase }}</p>
    <p>{{ archivedMandat.adresse_pdv }}</p>
    <p>{{ archivedMandat.cp_pdv }} {{ archivedMandat.ville_pdv }}</p>
    <div class="data mt-20">
      <p>
        surface totale
        <span>{{ archivedMandat.surface_totale || '-' }} m²</span>
      </p>
      <p>
        surface commerciale
        <span>{{ archivedMandat.surface_commerciale || '-' }} m²</span>
      </p>
      <p>
        surface annexe
        <span>{{ archivedMandat.surface_annexe || '-' }} m²</span>
      </p>
      <p>
        surface du terrain
        <span>{{ archivedMandat.surface_terrain || '-' }} m²</span>
      </p>
      <p>
        Loyer annuel
        <span>{{ (archivedMandat.loyer | formatEuro) || '-' }}</span>
      </p>
      <p>
        Echéance du bail
        <span>{{
          (archivedMandat.echeance_bail | date: 'dd/MM/yyyy') || '-'
        }}</span>
      </p>
      <p>
        Dépot de garantie
        <span>{{ (archivedMandat.depot_garantie | formatEuro) || '-' }}</span>
      </p>
      <p>
        Chiffre d&apos;affaires (N)
        <span>{{
          (archivedMandat.chiffre_affaires_N | formatEuro) || '-'
        }}</span>
      </p>
      <p>
        EBE <span>{{ archivedMandat.ebe || '-' }}</span>
      </p>
    </div>
  </div>

  <div class="mandant">
    <div class="financial">
      <h2>
        Prix de Présentation :
        {{ archivedMandat.prix_de_presentation | formatEuro }}
      </h2>
      <p>Forme juridique : {{ archivedMandat.forme_juridique }}</p>
      <p>Consultant : {{ archivedMandat.consultant }}</p>
      <p>Activité : {{ archivedMandat.activite }}</p>
    </div>
    <h2 class="mt-20">Mandant</h2>
    <div class="mandant-data">
      <p class="mandant-title">Société</p>
      <p>{{ archivedMandat.societe_mandant || '-' }}</p>
    </div>
    <div class="mandant-data">
      <p class="mandant-title">Siren</p>
      <p>{{ archivedMandat.siren_mandant || '-' }}</p>
    </div>
    <div class="mandant-data">
      <p class="mandant-title">Siège</p>
      <p>
        {{ archivedMandat.adresse_mandant }} {{ archivedMandat.cp_mandant }}
        {{ archivedMandat.ville_mandant }}
      </p>
    </div>
    <div class="mandant-data">
      <p class="mandant-title">Représentant</p>
      <p>
        {{ archivedMandat.prenom_mandant }}
        {{ archivedMandat.nom_mandant | uppercase }}
      </p>
    </div>
    <div class="mandant-data">
      <p class="mandant-title">Email</p>
      <p>{{ archivedMandat.email_mandant || '-' }}</p>
    </div>
    <div class="mandant-data">
      <p class="mandant-title">Mobile</p>
      <p>{{ archivedMandat.mobile_mandant || '-' }}</p>
    </div>
    <div class="mandant-data">
      <p class="mandant-title">Fixe</p>
      <p>{{ archivedMandat.fixe_mandant || '-' }}</p>
    </div>
  </div>
</div>
