import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-default-bottom-menu',
  templateUrl: './default-bottom-menu.component.html',
  styleUrl: './default-bottom-menu.component.scss',
})
export class DefaultBottomMenuComponent {
  constructor(private readonly router: Router) {}
}
