import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { AuthenticationSelectors } from '@features/authentification/domain/redux/selectors/authentication.selectors';
import { ConsultantRole } from '@features/consultant/domain/entities/consultant.interface';
import { LoadSilenceConsultants } from '@features/consultant/domain/redux/actions/consultants.action';
import { ConsultantSelectors } from '@features/consultant/domain/redux/selectors/consultant.selectors';
import { TransfertService } from '@features/dashboard/adapters/secondary/real/transfert.service';
import {
  DefineLayout,
  DeleteUser,
  InitializeConsultantId,
  SelectConsultantId,
  SelectEndDate,
  SelectStartDate,
  TransferBuyersAndMandatBetweenConsultant,
} from '@features/dashboard/domain/redux/actions/dashboard.actions';
import { SweetAlertService } from '@libs/sweet-alert/sweet-alert.service';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { DateTimeInterface } from '@src/shared/interaces/date-time.interface.ts/date-time.interface';
import { tap, throwError } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';

export enum DashBoardUiState {
  CONSULTANT_OR_MOBILE = 'mobile',
  ADMIN_OR_MANAGER = 'computer',
}

export class DashboardStateModel {
  activeMandatsCount!: number;
  idConsultant: number = 0;
  consultantName: string = '';
  consultantRole: string = '';
  startDate!: DateTimeInterface | string;
  endDate!: DateTimeInterface | string;
  layout?: DashBoardUiState;
}

export const defaultDashboardState: DashboardStateModel = {
  activeMandatsCount: 0,
  idConsultant: 0,
  consultantName: '',
  consultantRole: '',
  startDate: new Date(new Date().getFullYear(), 0, 1).toISOString(),
  endDate: new Date().toISOString(),
  layout: undefined,
};

@State<DashboardStateModel>({
  name: 'dashboard',
  defaults: defaultDashboardState,
})
@Injectable()
export class DashboardState {
  constructor(
    private store: Store,
    private breakPointObserver: BreakpointObserver,
    private transfertsService: TransfertService,
    private sweetAlertService: SweetAlertService
  ) {}

  @Action(SelectStartDate)
  selectStartDate(
    ctx: StateContext<DashboardStateModel>,
    action: SelectStartDate
  ): void {
    ctx.patchState({
      startDate: action.startDate,
    });
  }

  @Action(InitializeConsultantId)
  initializeConsultantId(ctx: StateContext<DashboardStateModel>): void {
    this.store
      .select(AuthenticationSelectors.userId)
      .pipe(filter((userId): userId is number => userId !== undefined))
      .subscribe((userId: number) => {
        this.getUserConsultantData(userId, ctx);
      });
  }

  @Action(SelectEndDate)
  selectEndDate(
    ctx: StateContext<DashboardStateModel>,
    action: SelectEndDate
  ): void {
    ctx.patchState({
      endDate: action.endDate,
    });
  }

  @Action(SelectConsultantId)
  selectConsultantId(
    ctx: StateContext<DashboardStateModel>,
    action: SelectConsultantId
  ): void {
    this.store
      .selectOnce(ConsultantSelectors.getConsultantById(action.consultantId))
      .subscribe(consultant => {
        if (consultant) {
          ctx.patchState({
            idConsultant: consultant.id,
            consultantName: `${consultant.nom} ${consultant.prenom}`,
            consultantRole: consultant.role,
          });
        }
      });
  }

  @Action(DefineLayout)
  defineLayout(
    ctx: StateContext<DashboardStateModel>,
    action: DefineLayout
  ): void {
    let layout: DashBoardUiState;
    const isMobileOrTablet = this.breakPointObserver.isMatched([
      Breakpoints.Handset,
      Breakpoints.Tablet,
    ]);

    if (isMobileOrTablet) {
      layout = DashBoardUiState.CONSULTANT_OR_MOBILE;
    } else {
      layout = this.determineUserDashboardUiState(action);
    }

    ctx.patchState({ layout });
  }

  @Action(TransferBuyersAndMandatBetweenConsultant)
  transfertDataBetwennConsultants(
    ctx: StateContext<DashboardStateModel>,
    action: TransferBuyersAndMandatBetweenConsultant
  ) {
    const bodyMandats = {
      id_consultant_expediteur: action.id_consultant_expediteur,
      id_consultant_destinataire_mandat:
        action.id_consultant_destinataire_mandat,
    };

    const bodyAcquereur = {
      id_consultant_expediteur: action.id_consultant_expediteur,
      id_consultant_destinataire_buyer: action.id_consultant_destinataire_buyer,
    };

    return this.transfertsService.updateMandats(bodyMandats).pipe(
      tap(() => {
        this.sweetAlertService.showToastSuccess(
          'Transfert des mandats réussi, nous procédons maintenant au transfert des acquéreurs associés.'
        );
      }),
      switchMap(() => {
        return this.transfertsService.updateBuyers(bodyAcquereur).pipe(
          tap(() => {
            this.sweetAlertService.showToastSuccess(
              'Transfert des acquéreurs réussi'
            );
          })
        );
      }),
      catchError(error => {
        this.sweetAlertService.showToastError(
          'Erreur lors du transfert des données'
        );
        return throwError(() => error);
      })
    );
  }

  @Action(DeleteUser)
  deleteUser(ctx: StateContext<DashboardStateModel>, action: DeleteUser): void {
    this.transfertsService
      .deleteConsultant(action.id)
      .pipe(
        tap(() => {
          this.store.dispatch(new LoadSilenceConsultants());
          this.sweetAlertService.showToastSuccess(
            'Consultant supprimé avec succès !'
          );
        }),
        catchError(error => {
          this.sweetAlertService.showToastError(
            'Une erreur est survenue pendant la suppression du consultant.'
          );
          return throwError(() => error);
        })
      )
      .subscribe();
  }

  private getUserConsultantData(
    userId: number,
    ctx: StateContext<DashboardStateModel>
  ) {
    this.store
      .select(ConsultantSelectors.getConsultantById(userId))
      .pipe(filter(consultant => !!consultant)) // Attendre que le consultant soit disponible
      .subscribe(consultant => {
        if (consultant) {
          ctx.patchState({
            idConsultant: consultant.id,
            consultantName: `${consultant.nom} ${consultant.prenom}`,
            consultantRole: consultant.role,
          });
          ctx.dispatch(new DefineLayout(consultant.role));
        }
      });
  }

  private determineUserDashboardUiState(action: DefineLayout) {
    if (
      action.role === ConsultantRole.CONSTRUCTOR ||
      action.role === ConsultantRole.SUPER_ADMIN ||
      action.role === ConsultantRole.ADMIN
    ) {
      return DashBoardUiState.ADMIN_OR_MANAGER;
    } else {
      return DashBoardUiState.CONSULTANT_OR_MOBILE;
    }
  }
}
