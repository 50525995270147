import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mandat-update-bottom-menu',
  templateUrl: './mandat-update-bottom-menu.component.html',
  styleUrl: './mandat-update-bottom-menu.component.scss',
})
export class MandatUpdateBottomMenuComponent {
  @Input() navigationGoback!: () => void;

  constructor(private readonly router: Router) {}
}
