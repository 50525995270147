<form [formGroup]="priceForm" class="create-form">
  <app-input-slide
    [required]="true"
    formControlName="prix_de_presentation"
    inputType="price"
    placeholder="Prix de présentation"
    type="number"
    width="fit"></app-input-slide>

  <app-input-slide
    formControlName="honoraires_vendeur"
    inputType="price"
    placeholder="Honoraires charge vendeur"
    type="number"
    width="fit"></app-input-slide>

  <app-input-slide
    formControlName="honoraires_acquereur"
    inputType="price"
    placeholder="Honoraires charge acquéreur"
    type="number"
    width="fit"></app-input-slide>

  <div class="form-group">
    <label for="honoraire-tva">T.V.A sur honoraires</label>
    <app-select
      [items]="tvaItems"
      formControlName="option_tva"
      id="honoraire-tva"
      size="fit"></app-select>
  </div>

  <!-- Nouveau bloc pour le "Prix net vendeur" -->
  <div class="form-group">
    <p>Prix net vendeur</p>
    <div class="grey-block">
      {{
        (priceForm.get('prix_de_presentation')?.value ?? 0) -
          (priceForm.get('honoraires_vendeur')?.value ?? 0) | number: '1.2-2'
      }}
      €
    </div>
  </div>

  <!-- Bloc statique pour les Droits de mutation -->
  <div class="form-group">
    <p>Droits de mutation</p>
    <div class="grey-block">
      {{ priceForm.get('droits_mutation')?.value ?? 0 | number: '1.2-2' }} €
    </div>
  </div>
</form>
