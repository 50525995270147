<app-mandat-create-stepper
  (create)="create()"
  *ngIf="!loading"
  [icons]="icons"
  [linear]="true"
  [stepNames]="steps">
  <cdk-step [stepControl]="generalInformationsForm">
    <app-general-information
      [generalInformationForm]="
        this.generalInformationsForm
      "></app-general-information>
  </cdk-step>
  <cdk-step [stepControl]="mandantContactForm">
    <app-mandant-companies
      [companiesMandantForm]="this.mandantCompanyForm"
      [mandatPredefinedForm]="
        this.mandatPredefinedForm
      "></app-mandant-companies>
    <app-mandant-contact
      *ngIf="
        this.mandantCompanyForm.get('siren')?.value ||
        this.mandatPredefinedForm.get('morale_or_physique_mandant')?.value ===
          'physique'
      "
      [contactMandantForm]="this.mandantContactForm"></app-mandant-contact>
  </cdk-step>
  <cdk-step
    *ngIf="
      !(
        this.generalInformationsForm.get('id_forme_juridique')?.value === 3 ||
        (this.generalInformationsForm.get('id_forme_juridique')?.value === 4 &&
          this.generalInformationsForm.get('etat_murs')?.value ===
            MandatWallEnum.free)
      )
    ">
    <app-bailleur-companies
      [companiesBailleurForm]="this.bailleurCompanyForm"
      [mandatPredefinedForm]="
        this.mandatPredefinedForm
      "></app-bailleur-companies>
    <app-bailleur-contact
      *ngIf="
        this.bailleurCompanyForm.get('siren')?.value ||
        this.mandatPredefinedForm.get('morale_or_physique_bailleur')?.value ===
          'physique'
      "
      [bailleurForm]="this.bailleurContactForm"></app-bailleur-contact>
  </cdk-step>
  <cdk-step [stepControl]="salesPointForm">
    <app-sale-point [salePointForm]="this.salesPointForm"></app-sale-point>
  </cdk-step>
  <cdk-step [stepControl]="financialForm">
    <app-financial [financialForm]="this.financialForm"></app-financial>
  </cdk-step>
  <cdk-step [stepControl]="priceForm">
    <app-price [priceForm]="this.priceForm"></app-price>
  </cdk-step>
  <cdk-step>
    <app-annonce [annonceForm]="this.annonceForm"></app-annonce>
    <app-diffusion
      [commentaireForm]="this.commentaireForm"
      [diffusionForm]="this.diffusionForm"></app-diffusion>
  </cdk-step>
</app-mandat-create-stepper>
