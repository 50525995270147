import { Component, EventEmitter, Output } from '@angular/core';
import { CabinetFilterParams } from '@src/features/cabinets/domain/entities/cabinet-filter-params.interface';

@Component({
  selector: 'app-agencies-filter',
  templateUrl: './agencies-filter.component.html',
  styleUrl: './agencies-filter.component.scss',
})
export class AgenciesFilterComponent {
  @Output() filterTrigger: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() filterChange: EventEmitter<CabinetFilterParams> =
    new EventEmitter<CabinetFilterParams>();
  filterTriggered = true;
  filters: CabinetFilterParams = {
    agencies: [],
    debut: new Date(new Date().getFullYear(), 0, 1).toISOString(),
    fin: new Date().toISOString(),
  };

  trigerFilter() {
    this.filterTriggered = !this.filterTriggered;
    this.filterTrigger.emit(this.filterTriggered);
  }

  onDateDebutChange($event: string) {
    this.filters.debut = $event;
    this.emitChanges();
  }

  onDateFinChange($event: string) {
    this.filters.fin = $event;
    this.emitChanges();
  }

  resetFilters() {
    this.filters = {
      ...this.filters,
      debut: new Date(new Date().getFullYear(), 0, 1).toISOString(),
      fin: new Date().toISOString(),
    };
    this.emitChanges();
  }

  private emitChanges() {
    this.filterChange.emit(this.filters);
  }
}
