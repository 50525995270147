<div class="popup-container">
  <div class="popup-content">
    <h2>Activer le Mandat</h2>
    <form (ngSubmit)="onSave()" [formGroup]="mandatForm">
      <div class="form-group">
        <label for="date_debut">Date de Début:</label>
        <input
          formControlName="date_debut"
          name="date_debut"
          required
          type="date" />
        <div
          *ngIf="
            mandatForm.get('date_debut')?.invalid &&
            mandatForm.get('date_debut')?.touched
          "
          class="error">
          La date de début est requise.
        </div>
      </div>
      <div class="form-group">
        <label for="numero_registre">Numéro de Registre:</label>
        <input
          formControlName="numero_registre"
          name="numero_registre"
          required />
        <div
          *ngIf="
            mandatForm.get('numero_registre')?.invalid &&
            mandatForm.get('numero_registre')?.touched
          "
          class="error">
          Le numéro de registre est requis.
        </div>
      </div>
      <div class="form-group">
        <label for="duree_en_mois">Durée en Mois:</label>
        <input
          formControlName="duree_en_mois"
          min="1"
          name="duree_en_mois"
          required
          type="number" />
        <div
          *ngIf="
            mandatForm.get('duree_en_mois')?.invalid &&
            mandatForm.get('duree_en_mois')?.touched
          "
          class="error">
          La durée en mois est requise et doit être au moins 1.
        </div>
      </div>
      <div class="button-group">
        <button (click)="onCancel()" type="button">Annuler</button>
        <button [disabled]="mandatForm.invalid" type="submit">
          Enregistrer
        </button>
      </div>
    </form>
  </div>
</div>
