<div
  [ngClass]="{
    'input-focused': checkIfValueExist() || isInputFocused,
    desktop: forDesktop,
    'input-invalid': isTouched && required && !checkIfValueExist(),
  }"
  class="input_slide-container">
  <input
    (blur)="onFocus(false)"
    (focus)="onFocus(true)"
    (input)="onSearchInputChange($any($event.target).value)"
    [formControl]="searchInput"
    [id]="inputId"
    [matAutocomplete]="auto"
    class="form-control form-control-sm"
    data-cy="recherche-input"
    id="company" />
  <label
    [class.required]="required"
    [for]="inputId"
    [ngClass]="{ 'error-label': isTouched && required }"
    class="placeholder"
    >Rechercher une entreprise</label
  >
</div>
<mat-autocomplete
  #auto="matAutocomplete"
  (opened)="onAutocompleteOpened()"
  (optionSelected)="onOptionSelected($event)"
  style="margin-top: -20px">
  <mat-option
    *ngFor="let result of searchResults; let i = index"
    [attr.data-cy]="'resultat-recherche-' + i"
    [value]="result"
    style="min-height: 30px">
    <div class="search-result">
      <p *ngIf="isNumericSearchTerm()">
        <span style="color: #0d5383"
          >{{ result.denomination }} {{ result.nom }} {{ result.prenom }}</span
        >({{ result.siren }})
      </p>
      <p *ngIf="!isNumericSearchTerm()">
        <span style="color: #0d5383"
          >{{ result.denomination }} {{ result.nom }} {{ result.prenom }} </span
        >({{ result.code_postal }})
      </p>
      <p>{{ result.libelle_code_naf }}</p>
    </div>
  </mat-option>
</mat-autocomplete>
