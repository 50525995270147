import { Component, Input, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-lessor-table',
  templateUrl: './lessor-table.component.html',
  styleUrl: './lessor-table.component.scss',
})
export class LessorTableComponent {
  columnsToDisplay: string[] = [
    'bailleur',
    'mobile',
    'activite',
    'enseigne',
    'loyer',
    'adresse',
    'ville',
  ];
  tableDataSource = new MatTableDataSource<MandatDetailInterface>();
  @ViewChild(CdkVirtualScrollViewport) viewport!: CdkVirtualScrollViewport;
  private allData: MandatDetailInterface[] = [];

  @Input() set dataSource(data: MandatDetailInterface[]) {
    this.allData = data;
    this.tableDataSource.data = data.slice(0, 40); // Slice the data initially
  }

  onScroll() {
    const end = this.viewport.getRenderedRange().end;
    const total = this.viewport.getDataLength();
    if (end === total) {
      const newLength = this.tableDataSource.data.length + 40;
      const nextBatch = this.allData.slice(
        this.tableDataSource.data.length,
        newLength
      );
      this.tableDataSource.data = [...this.tableDataSource.data, ...nextBatch];
    }
  }
}
