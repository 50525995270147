import { Component, inject, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthenticationSelectors } from '@features/authentification/domain/redux/selectors/authentication.selectors';
import { CabinetsInterface } from '@features/cabinets/domain/entities/cabinets.interface';
import { CabinetsSelectors } from '@features/cabinets/domain/redux/selectors/cabinets.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CabinetForm } from '@src/shared/forms/cabinet/cabinet.form';
import { FormGroup } from '@angular/forms';
import { ContactCivilityEnum } from '@src/features/contacts/domain/entities/contacts.interface';
import { Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-agency-edit-container',
  templateUrl: './agency-edit-container.component.html',
  styleUrl: './agency-edit-container.component.scss',
})
export class AgencyEditContainerComponent implements OnInit {
  userCabinetId$: Observable<number | undefined> = inject(Store).select(
    AuthenticationSelectors.userCabinetId
  );
  userCabinetId!: string;
  cabinet$!: Observable<CabinetsInterface | undefined>;
  cabinet!: CabinetsInterface;
  cabinetFormBuilder!: CabinetForm;
  cabinetForm!: FormGroup;
  civility = {
    monsieur: ContactCivilityEnum.mr,
    madame: ContactCivilityEnum.mrs,
  };

  constructor(
    private store: Store,
    private router: Router
  ) {}

  ngOnInit() {
    this.fetchCabinet();
    this.initializeCabinetForm();
  }

  private fetchCabinet() {
    this.userCabinetId$.pipe(untilDestroyed(this)).subscribe(id => {
      if (id) {
        this.cabinet$ = this.store.select(CabinetsSelectors.getCabinetById(id));
        this.userCabinetId = id.toString();
      }
    });
    this.cabinet$.pipe(untilDestroyed(this)).subscribe(cabinet => {
      if (cabinet) {
        this.cabinet = cabinet;
      }
    });
    console.log(this.cabinet);
  }

  private initializeCabinetForm() {
    this.cabinetFormBuilder = new CabinetForm(this.userCabinetId);
    this.cabinetForm = this.cabinetFormBuilder.createFormGroup();
    this.cabinetForm.patchValue(this.cabinet);
  }

  udpateAgency() {
    console.log(this.cabinetForm.value);
  }
  cancelUpdate() {
    this.cabinetForm.reset();
    this.router.navigate(['/agency/informations']);
  }
}
