import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormSectionBase } from '../form.class';

export class CabinetCreateForm extends FormSectionBase {
  createFormGroup() {
    return new FormGroup({
      civilite: new FormControl('mr', [Validators.required]),
      nom: new FormControl('', [Validators.required]),
      responsable_nom: new FormControl('', [Validators.required]),
      responsable_prenom: new FormControl('', [Validators.required]),
      email_signature_electronique: new FormControl(''),
      raison_sociale: new FormControl(''),
      forme_sociale: new FormControl('', [Validators.required]),
      adresse: new FormControl('', [Validators.required]),
      codepostal: new FormControl('', [Validators.required]),
      ville_referante: new FormControl(''),
      ville: new FormControl(''),
      lat: new FormControl(''),
      lng: new FormControl(''),
      telephone: new FormControl('', [Validators.required]),
      mobile: new FormControl(''),
      fax: new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.email]),
      numero_carte_pro: new FormControl(''),
      carte_pro_delivree_par: new FormControl(''),
      carte_pro_delivree_le: new FormControl(''),
      caisse_de_garantie: new FormControl(''),
      caisse_de_garantie_adresse: new FormControl(''),
      caisse_de_garantie_montant: new FormControl(''),
      cheque_versement: new FormControl(true),
      banque_sequestre: new FormControl('', [Validators.required]),
      nom_rcp: new FormControl('', [Validators.required]),
      adresse_rcp: new FormControl('', [Validators.required]),
      mediateur: new FormControl(''),
      siret: new FormControl(''),
      capital: new FormControl('', [Validators.required]),
      num_tva: new FormControl('', [Validators.required]),
      iban: new FormControl('', [Validators.required]),
      bic: new FormControl('', [Validators.required]),
      siteweb: new FormControl('', [Validators.required]),
      url_honoraires: new FormControl('', [Validators.required]),
      presentation: new FormControl(''),
    });
  }
}
