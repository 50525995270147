<div class="title">
  <h1 class="under-lined-h1">Créer une nouvelle agence</h1>
</div>
<form [formGroup]="cabinetCreateForm">
  <div class="representant">
    <h2>Représentant de l'agence</h2>
    <div class="form-group">
      <label class="required" for="agency-create-name"
        >Nom du futur cabinet</label
      >
      <app-input-slide
        [forDesktop]="true"
        formControlName="nom"
        id="agency-create-name"
        inputType="text"
        width="fit" />
    </div>
    <div class="form-group">
      <label for="agency-create-civilite">Civilité</label>
      <div class="radio">
        <input
          [value]="civility.monsieur"
          formControlName="civilite"
          id="agency-create-civilite-mr"
          type="radio" />
        <label for="agency-create-civilite-mr">Monsieur</label>
        <input
          [value]="civility.madame"
          formControlName="civilite"
          id="agency-create-civilite-mme"
          type="radio" />
        <label for="agency-create-civilite-mme">Madame</label>
      </div>
    </div>
    <div class="form-group">
      <label class="required" for="agency-create-responsable-nom"
        >Nom du responsable</label
      >
      <app-input-slide
        [forDesktop]="true"
        formControlName="responsable_nom"
        id="agency-create-responsable-non"
        inputType="text"
        width="fit" />
    </div>
    <div class="form-group">
      <label class="required" for="agency-create-responsable-prenom"
        >Prénom du responsable</label
      >
      <app-input-slide
        [forDesktop]="true"
        formControlName="responsable_prenom"
        id="agency-create-responsable-prenom"
        inputType="text"
        width="fit" />
    </div>
    <div class="form-group">
      <label for="agency-create-mobile">Téléphone mobile</label>
      <app-input-slide
        [forDesktop]="true"
        formControlName="mobile"
        id="agency-create-mobile"
        inputType="text"
        width="fit" />
    </div>
    <div class="form-group">
      <label for="agency-create-email-signature">Email responsable</label>
      <app-input-slide
        [forDesktop]="true"
        formControlName="email_signature_electronique"
        id="agency-create-email-signature"
        inputType="text"
        width="fit" />
    </div>
  </div>
  <div class="info">
    <h2>Information de l'agence</h2>
    <div class="form-group">
      <app-search-pappers
        (optionSelectedEvent)="onSiretChanged($event)"
        [forDesktop]="true" />
    </div>
    <div class="form-group">
      <label for="agency-create-raison">Raison sociale</label>
      <app-input-slide
        [forDesktop]="true"
        formControlName="raison_sociale"
        id="agency-create-raison"
        inputType="text"
        width="fit" />
    </div>
    <div class="form-group">
      <label class="required" for="agency-create-forme">Forme sociale</label>
      <app-input-slide
        [forDesktop]="true"
        formControlName="forme_sociale"
        id="agency-create-forme"
        inputType="text"
        width="fit" />
    </div>
    <div class="form-group">
      <label class="required" for="agency-create-capital">Capital</label>
      <app-input-slide
        [forDesktop]="true"
        formControlName="capital"
        id="agency-create-capital"
        inputType="text"
        width="fit" />
    </div>
    <div class="form-group">
      <app-google-places-autocomplete
        (formattedAddress)="onCabinetAdressChanged($event)"
        [forDesktop]="true"
        [initialValue]="pappersAdresse"
        [placeholder]="'Rechercher une adresse'" />
    </div>
    <div class="form-group">
      <label class="required" for="agency-create-adresse">Adresse</label>
      <app-input-slide
        [forDesktop]="true"
        formControlName="adresse"
        id="agency-create-adresse"
        inputType="text"
        width="fit" />
    </div>
    <div class="form-group">
      <label class="required" for="agency-create-postal">Code postal</label>
      <app-input-slide
        [forDesktop]="true"
        formControlName="codepostal"
        id="agency-create-postal"
        inputType="text"
        width="fit" />
    </div>
    <div class="form-group">
      <label for="agency-create-ville">Ville</label>
      <app-input-slide
        [forDesktop]="true"
        formControlName="ville"
        id="agency-create-ville"
        inputType="text"
        width="fit" />
    </div>
    <div class="form-group">
      <label for="agency-create-ville-ref">Ville référente</label>
      <app-input-slide
        [forDesktop]="true"
        formControlName="ville_referante"
        id="agency-create-ville-ref"
        inputType="text"
        width="fit" />
    </div>
    <div class="form-group">
      <label class="required" for="agency-create-telephone"
        >Téléphone de l'agence</label
      >
      <app-input-slide
        [forDesktop]="true"
        formControlName="telephone"
        id="agency-create-telephone"
        inputType="text"
        width="fit" />
    </div>
    <div class="form-group">
      <label class="required" for="agency-create-email"
        >Email de l'agence</label
      >
      <app-input-slide
        [forDesktop]="true"
        formControlName="email"
        id="agency-create-email"
        inputType="text"
        width="fit" />
    </div>
    <div class="form-group">
      <label for="agency-create-siret">SIRET</label>
      <app-input-slide
        [forDesktop]="true"
        formControlName="siret"
        id="agency-create-siret"
        inputType="text"
        width="fit" />
    </div>
    <div class="form-group">
      <label class="required" for="agency-create-tva">N° de TVA</label>
      <app-input-slide
        [forDesktop]="true"
        formControlName="num_tva"
        id="agency-create-tva"
        inputType="text"
        width="fit" />
    </div>
    <div class="form-group">
      <label class="required" for="agency-create-iban">IBAN</label>
      <app-input-slide
        [forDesktop]="true"
        formControlName="iban"
        id="agency-create-iban"
        inputType="text"
        width="fit" />
    </div>
    <div class="form-group">
      <label class="required" for="agency-create-bic">BIC</label>
      <app-input-slide
        [forDesktop]="true"
        formControlName="bic"
        id="agency-create-bic"
        inputType="text"
        width="fit" />
    </div>
    <div class="form-group">
      <label class="required" for="agency-create-siteweb">Site web</label>
      <app-input-slide
        [forDesktop]="true"
        formControlName="siteweb"
        id="agency-create-siteweb"
        inputType="text"
        width="fit" />
    </div>
    <div class="form-group">
      <label class="required" for="agency-create-honoraires"
        >Lien vers le barème des honoraires</label
      >
      <app-input-slide
        [forDesktop]="true"
        formControlName="url_honoraires"
        id="agency-create-honoraires"
        inputType="text"
        width="fit" />
    </div>
  </div>
  <div class="garantie">
    <h2>Garantie financière</h2>
    <div class="form-group">
      <label class="required" for="agency-create-banque"
        >Banque sequestre</label
      >
      <app-input-slide
        [forDesktop]="true"
        formControlName="banque_sequestre"
        id="agency-create-banque"
        inputType="text"
        width="fit" />
    </div>
    <div class="form-group">
      <label for="agency-create-caisse ">Caisse de garantie</label>
      <app-input-slide
        [forDesktop]="true"
        formControlName="caisse_de_garantie"
        id="agency-create-caisse"
        inputType="text"
        width="fit" />
    </div>
    <div class="form-group">
      <label for="agency-create-caisse-adresse"
        >Adresse caisse de garantie</label
      >
      <app-input-slide
        [forDesktop]="true"
        formControlName="caisse_de_garantie_adresse"
        id="agency-create-caisse-adresse"
        inputType="text"
        width="fit" />
    </div>
    <div class="form-group">
      <label for="agency-create-caisse-montant">Montant garantie</label>
      <app-input-slide
        [forDesktop]="true"
        formControlName="caisse_de_garantie_montant"
        id="agency-create-caisse-montant"
        inputType="text"
        width="fit" />
    </div>
  </div>
  <div class="carte">
    <h2>Carte professionnelle</h2>
    <div class="form-group">
      <label for="agency-create-carte-numero">Numéro</label>
      <app-input-slide
        [forDesktop]="true"
        formControlName="numero_carte_pro"
        id="agency-create-carte-numero"
        inputType="text"
        width="fit" />
    </div>
    <div class="form-group">
      <label for="agency-create-carte-delivree-par">Délivrée par</label>
      <app-input-slide
        [forDesktop]="true"
        formControlName="carte_pro_delivree_par"
        id="agency-create-carte-delivree-par"
        inputType="text"
        width="fit" />
    </div>
    <div class="form-group">
      <label for="agency-create-carte-delivree-le">Délivrée le</label>
      <app-input-slide
        [forDesktop]="true"
        formControlName="carte_pro_delivree_le"
        id="agency-create-carte-delivree-le"
        inputType="text"
        width="fit" />
    </div>
    <div class="form-group">
      <label for="agency-create-carte-mediateur">Médiateur</label>
      <app-input-slide
        [forDesktop]="true"
        formControlName="mediateur"
        id="agency-create-carte-mediateur"
        inputType="text"
        width="fit" />
    </div>
    <div class="form-group">
      <label class="required" for="agency-create-carte-rcp"
        >Organisme RCP</label
      >
      <app-input-slide
        [forDesktop]="true"
        formControlName="nom_rcp"
        id="agency-create-carte-rcp"
        inputType="text"
        width="fit" />
    </div>
    <div class="form-group">
      <label class="required" for="agency-create-carte-rcp-adresse"
        >Adresse RCP</label
      >
      <app-input-slide
        [forDesktop]="true"
        formControlName="adresse_rcp"
        id="agency-create-carte-rcp-adresse"
        inputType="text"
        width="fit" />
    </div>
  </div>
  <div class="btn">
    <app-btn (btnClick)="cancelCreateCabinet()" color="cancel" text="Annuler" />
    <app-btn
      (btnClick)="createCabinet()"
      [disabled]="cabinetCreateForm.invalid"
      text="Enregistrer" />
  </div>
</form>
