<div
  #scrollableDiv
  (scroll)="onTableScroll($event)"
  [style.overflow-y]="'scroll'"
  class="table-container">
  <table aria-label="Table des mandats">
    <thead>
      <tr>
        <th class="select-mandat"></th>
        <th (click)="sortData('numero')" class="column-num-mandat">
          N°
          <span *ngIf="currentSortColumn === 'numero'">
            {{ isAsc ? '▲' : '▼' }}
          </span>
        </th>
        <th class="activite">Activité</th>
        <th (click)="sortData('enseigne')" class="enseigne">
          Enseigne
          <span *ngIf="currentSortColumn === 'enseigne'">
            {{ isAsc ? '▲' : '▼' }}
          </span>
        </th>
        <th class="calculator">
          <img
            alt="Calculatrice"
            height="20"
            ngSrc="../../../../../../../assets/icons/calculator-white.svg"
            width="20" />
        </th>
        <th (click)="sortData('cotation')" class="cotation">
          Cotation
          <span *ngIf="currentSortColumn === 'cotation'">
            {{ isAsc ? '▲' : '▼' }}
          </span>
        </th>
        <th (click)="sortData('prix')" class="prix-presentation">
          Prix
          <span *ngIf="currentSortColumn === 'prix'">
            {{ isAsc ? '▲' : '▼' }}
          </span>
        </th>
        <th (click)="sortData('chiffreAffaire')" class="chiffre-affaire">
          C.A
          <span *ngIf="currentSortColumn === 'chiffreAffaire'">
            {{ isAsc ? '▲' : '▼' }}
          </span>
        </th>
        <th
          (click)="sortData('surfaceCommerciale')"
          class="surface-commerciale">
          Surf.Co
          <span *ngIf="currentSortColumn === 'surfaceCommerciale'">
            {{ isAsc ? '▲' : '▼' }}
          </span>
        </th>
        <th (click)="sortData('loyer')" class="loyer-annuel">
          Loyer
          <span *ngIf="currentSortColumn === 'loyer'">
            {{ isAsc ? '▲' : '▼' }}
          </span>
        </th>
        <th class="adresse">Adresse</th>
        <th class="ville">Ville</th>
        <th class="mandants">Mandants</th>
        <th class="consultants">
          <img
            alt="Consultants"
            height="16"
            ngSrc="../../../../../../../assets/icons/user.svg"
            width="16" />
        </th>
      </tr>
    </thead>

    <tbody>
      <tr
        (touchmove)="onTouchMove($event, i)"
        (touchstart)="onTouchStart($event, i)"
        *ngFor="
          let mandat of sortedMandats.slice(0, this.limite);
          let i = index
        "
        [ngClass]="{
          compromis: mandat.mandat.etat === MandatStateEnum.underCompromise,
          offre: mandat.mandat.etat === MandatStateEnum.underOffer,
        }">
        <td class="select-mandat">
          <span
            [matBadgeHidden]="!mandatIds.includes(mandat.mandat.id)"
            class="custom-badge"
            matBadge="V"
            matBadgeColor="primary"
            matBadgePosition="before"
            matBadgeSize="small">
            <img
              (click)="addMandatToListVisitForm(mandat)"
              (keyup.enter)="addMandatToListVisitForm(mandat)"
              *ngIf="
                mandat.mandat.etat !== MandatStateEnum.preMandat &&
                mandat.mandat.etat !== MandatStateEnum.judiciaryProcess &&
                mandat.mandat.etat !== MandatStateEnum.prospection
              "
              alt="cart icon"
              class="custom-badge"
              height="22"
              src="./assets/icons/cart.svg"
              style="margin-left: 6px"
              tabindex="0"
              width="22" />
            <img
              *ngIf="mandat.mandat.etat === MandatStateEnum.prospection"
              alt="cart icon"
              height="22"
              src="./assets/navbar/extract.png"
              style="margin-left: 6px"
              width="22" />
          </span>
        </td>
        <td class="column-num-mandat">
          <span
            *ngIf="!isProspection"
            [appMandatStateColor]="mandat | mandatStateDisplayer"
            class="fz-12"
            >{{ mandat | mandatStateShortenedDisplayer }}</span
          >
        </td>

        <td class="activite fz-14">
          <app-activite-icons [activiteId]="mandat.mandat.id_activite" />
        </td>

        <td appResize class="enseigne fz-14">
          <span
            [routerLink]="['/mandats/details', mandat.mandat.id]"
            class="fz-14 link enseigne-span"
            >{{ mandat.salePoints.enseigne | uppercase }}
          </span>
          <span
            *ngIf="!isProspection"
            [appMandatStateColor]="mandat | mandatStateDisplayer"
            class="numero-mandat fz-12"
            >{{ mandat | mandatStateDisplayer }}</span
          >
        </td>

        <td class="calculator link">
          <img
            alt="Calculatrice"
            height="20"
            ngSrc="../../../../../../../assets/icons/calculator.svg"
            width="20" />
        </td>
        <td class="cotation fz-14">
          <span
            *ngIf="mandat.cotation"
            [appCotationColor]="mandat.cotation"
            class="fz-14"
            >{{ mandat.cotation }}</span
          >
        </td>

        <td
          *ngIf="!visibilityFlags[i]?.hidePrice"
          class="prix-presentation fz-14">
          {{ mandat.mandat.prix_de_presentation | formatEuro }}
        </td>
        <td class="chiffre-affaire fz-14">
          {{ mandat.salePoints.ca | formatEuro }}
        </td>
        <td class="surface-commerciale fz-14">
          @if (
            mandat.salePoints.surface_commerciale !== null &&
            mandat.salePoints.surface_commerciale !== undefined
          ) {
            {{ mandat.salePoints.surface_commerciale | thousandsSeparator }} m²
          } @else {
            -
          }
        </td>
        <td *ngIf="!visibilityFlags[i]?.hideRent" class="loyer-annuel fz-14">
          {{ mandat.salePoints.loyer_annuel | formatEuro }}
        </td>
        <td
          (click)="addAddresToSearchTab(mandat.salePoints.route)"
          class="adresse fz-14">
          <p>
            {{ mandat.salePoints.street_number }}
            {{ mandat.salePoints.route }} {{ mandat.salePoints.comp_adresse }}
          </p>
          <img
            *ngIf="mandat.salePoints.place_id"
            alt="geolocalisation"
            height="20"
            ngSrc="../../../../../../../../assets/icons/geo-green.png"
            width="20" />
          <img
            *ngIf="!mandat.salePoints.place_id"
            alt="geolocalisation"
            height="20"
            ngSrc="../../../../../../../../assets/icons/geo-red.png"
            width="20" />
        </td>
        <td
          (click)="addCityToSearchTab(mandat.salePoints.locality)"
          *ngIf="!visibilityFlags[i]?.hideVille"
          class="ville fz-14">
          {{ mandat.salePoints.locality }}
        </td>

        <td
          *ngIf="!visibilityFlags[i]?.hideMandant"
          [routerLink]="''"
          class="mandants link">
          {{ mandat.mandantContact.nom }}
        </td>

        <td *ngIf="!visibilityFlags[i]?.hideConsultant" class="consultants">
          <app-consultant-avatar [consultantId]="mandat.mandat.id_consultant" />
        </td>
        <div
          *ngIf="swipedRightIndexes[i]"
          [@swipeRight]="swipedRightIndexes[i] ? 'swipedRight' : 'default'"
          class="icons">
          <div class="swipe-icons-position">
            <span
              [matBadgeHidden]="!mandatIds.includes(mandat.mandat.id)"
              class="custom-badge"
              matBadge="V"
              matBadgeColor="primary"
              matBadgePosition="before"
              matBadgeSize="small">
              <img
                (click)="addMandatToListVisitForm(mandat)"
                (keyup.enter)="addMandatToListVisitForm(mandat)"
                *ngIf="
                  mandat.mandat.etat !== MandatStateEnum.preMandat &&
                  mandat.mandat.etat !== MandatStateEnum.judiciaryProcess &&
                  mandat.mandat.etat !== MandatStateEnum.prospection
                "
                alt="cart icon"
                height="22"
                src="./assets/icons/cart.svg"
                style="margin-right: 6px"
                tabindex="0"
                width="22" />
              <img
                *ngIf="mandat.mandat.etat === MandatStateEnum.prospection"
                alt="cart icon"
                height="22"
                src="./assets/navbar/extract.png"
                style="margin-left: 6px"
                width="22" />
            </span>
            <span>
              <img
                [routerLink]="['/mandats/update', mandat.mandat.id]"
                alt="edit icon"
                height="22"
                ngSrc="./assets/icons/edit.svg"
                width="22" />
            </span>
            <span>
              <img
                alt="calculator icon"
                height="22"
                ngSrc="./assets/icons/calculator.svg"
                style="margin-left: 6px"
                width="22" />
            </span>
          </div>
        </div>
      </tr>
    </tbody>
  </table>
</div>
