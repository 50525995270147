import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TvaExprimeEnum } from '@features/mandats/domain/entities/mandat.interface';
import { SelectItem } from '@libs/select/select-options';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss'],
})
export class PriceComponent implements OnInit {
  @Input({ required: true }) priceForm!: FormGroup;
  public tvaItems!: SelectItem[];

  ngOnInit() {
    this.tvaItems = [
      {
        id: TvaExprimeEnum.exprimes_ht,
        name: 'Honoraires HT',
      },
      {
        id: TvaExprimeEnum.exprimes_ttc,
        name: 'Honoraires TTC',
      },
    ];

    // On s'abonne aux changements du prix de présentation
    // (ou à l’autre champ qui sert de base pour calculer le droit de mutation).
    this.priceForm
      .get('prix_de_presentation')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe((value: number) => {
        const droitsMutation = this.calculateDroitDeMutation(value);
        this.priceForm.get('droits_mutation')?.setValue(droitsMutation);
      });
  }

  private calculateDroitDeMutation(total: number): number {
    if (!total || total === 0) {
      return 0;
    }

    const step1 = 23000;
    const step2 = 200000;

    if (total > step1) {
      if (total <= step2) {
        return Number((0.03 * (total - step1)).toFixed(2));
      } else {
        return Number((5310 + 0.05 * (total - step2)).toFixed(2));
      }
    }

    return 25;
  }
}
