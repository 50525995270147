<div class="sub-header-container">
  <div class="first-container">
    <div>
      <p>
        {{ this.mandat.mandat.id_forme_juridique | idToLegalForm }}
        <span
          *ngIf="mandat.cotation && mandat.cotation !== '-'"
          [appCotationColor]="mandat.cotation"
          class="fz-14"
          >{{ mandat.cotation }}</span
        >
      </p>
      <p [appMandatStateColor]="mandat | mandatStateDisplayer">
        {{ this.mandat | mandatStateDisplayer }}
      </p>
      <p>{{ this.mandat.mandat.exclusivite | exclusive }}</p>
    </div>

    <div class="right-block">
      <app-consultant-avatar [consultantId]="mandat.mandat.id_consultant" />
      <p>{{ this.mandat.mandat.prix_de_presentation | formatEuro }}</p>
    </div>
  </div>
  <div class="second-container">
    <p>
      {{ mandat.salePoints.street_number }} {{ mandat.salePoints.route }}
      {{ mandat.salePoints.comp_adresse }} {{ mandat.salePoints.postal_code }}
      {{ mandat.salePoints.locality }}
    </p>
    <img
      *ngIf="mandat.salePoints.place_id"
      alt="point gps"
      height="12"
      ngSrc="./assets/icons/geo-green.png"
      width="12" />
    <img
      *ngIf="!mandat.salePoints.place_id"
      alt="point gps"
      height="12"
      ngSrc="./assets/icons/geo-red.png"
      width="12" />
  </div>
</div>
