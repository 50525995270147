import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mandat-list-bottom-menu',
  templateUrl: './mandat-list-bottom-menu.component.html',
  styleUrl: './mandat-list-bottom-menu.component.scss',
})
export class MandatListBottomMenuComponent {
  @Input() navigationGoback!: () => void;
  @Input({ required: true }) numberOfSelectedMandatsInVisitForm!: number;

  constructor(private readonly router: Router) {}
}
