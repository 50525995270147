import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CabinetsApiService } from '@features/cabinets/adapters/secondary/real/cabinets-api.service';
import { CabinetsRoutingModule } from '@features/cabinets/cabinets-routing.module';
import { CabinetsApiPorts } from '@features/cabinets/domain/ports/cabinets-api.ports';
import { AgencyInfoContainerComponent } from './adapters/primary/ui/containers/agency-info-container/agency-info-container.component';
import { BtnModule } from '../../libs/btn/btn.module';
import { MatIconModule } from '@angular/material/icon';
import { AgencyEditContainerComponent } from './adapters/primary/ui/containers/agency-edit-container/agency-edit-container.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputSlideModule } from '../../libs/input-slide/input-slide.module';
import { AgenciesListContainerComponent } from './adapters/primary/ui/containers/agencies-list-container/agencies-list-container.component';
import { MatTableModule } from '@angular/material/table';
import {
  CdkFixedSizeVirtualScroll,
  CdkVirtualScrollViewport,
} from '@angular/cdk/scrolling';
import { AgenciesFilterComponent } from './adapters/primary/ui/components/agencies-filter/agencies-filter.component';
import { AgencyCreateContainerComponent } from './adapters/primary/ui/containers/agency-create-container/agency-create-container.component';
import { SearchPappersModule } from '../../libs/search-pappers/search-pappers.module';
import { GooglePlacesAutocompleteComponent } from '../../libs/google-places-autocomplete/google-places-autocomplete.component';
import { FormatEuroPipe } from '../../shared/pipes/format-euro.pipe';
import { CabinetActivePipe } from './adapters/primary/pipes/cabinet-active.pipe';
import { CabinetActiveDirective } from './adapters/primary/directives/cabinet-active.directive';
import { AgencyFeesContainerComponent } from './adapters/primary/ui/containers/agency-fees-container/agency-fees-container.component';
import { AppInputModule } from '../../libs/input/input.module';

@NgModule({
  providers: [{ provide: CabinetsApiPorts, useClass: CabinetsApiService }],
  imports: [
    CommonModule,
    CabinetsRoutingModule,
    BtnModule,
    MatIconModule,
    MatTableModule,
    ReactiveFormsModule,
    InputSlideModule,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    FormsModule,
    SearchPappersModule,
    GooglePlacesAutocompleteComponent,
    FormatEuroPipe,
    CabinetActivePipe,
    CabinetActiveDirective,
    AppInputModule,
  ],
  declarations: [
    AgencyInfoContainerComponent,
    AgencyEditContainerComponent,
    AgenciesListContainerComponent,
    AgenciesFilterComponent,
    AgencyCreateContainerComponent,
    AgencyFeesContainerComponent,
  ],
})
export class CabinetsModule {}
