import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[appCabinetActive]',
  standalone: true,
})
export class CabinetActiveDirective implements OnChanges {
  @Input() appCabinetActive!: number;

  constructor(private el: ElementRef) {}

  ngOnChanges() {
    this.updateStatus();
  }

  private updateStatus() {
    if (this.appCabinetActive == 1) {
      this.el.nativeElement.style.color = 'green';
      this.el.nativeElement.innerText = 'ACTIF';
    } else {
      this.el.nativeElement.style.color = 'red';
      this.el.nativeElement.innerText = 'INACTIF';
    }
  }
}
