<div class="information">
  <ng-container *ngIf="cabinet$ | async as cabinet">
    <section>
      <div class="banniere">
        <div class="logo">
          <div class="border">
            <img alt="logo" src="assets/logos/logo-emplacement-n1.png" />
          </div>
        </div>
      </div>

      <div class="information-general">
        <div>
          <h2 class="title">{{ cabinet.nom }}</h2>
          <p>{{ cabinet.forme_sociale }} {{ cabinet.raison_sociale }}</p>
          <p>{{ cabinet.telephone }}</p>
          <p>{{ cabinet.email }}</p>
          <p>{{ cabinet.adresse }}</p>
          <p>{{ cabinet.codepostal }} {{ cabinet.ville }}</p>
          <h2>Image utilisé pour les diverses passerelles</h2>
          <div class="passerelle">
            <div class="passerelle-photo">
              <img
                *ngIf="!filePreviewUrl"
                [src]="
                  apiUrl +
                  '/uploads/images-agences/' +
                  cabinet.id +
                  '/' +
                  cabinet.photo
                "
                alt="Photo du cabinet" />
              <img
                *ngIf="filePreviewUrl"
                [src]="filePreviewUrl"
                alt="précédent" />
            </div>
            <div class="btn">
              <app-btn
                (btnClick)="editPasserelleImage()"
                text="Select. fichier"></app-btn>
              <div class="btn-validate">
                <app-btn
                  (btnClick)="uploadPasserelleImage()"
                  *ngIf="isFileSelected"
                  color="secondary"
                  text="Uploader"></app-btn>
                <app-btn
                  (btnClick)="cancelUpload()"
                  *ngIf="isFileSelected"
                  color="cancel"
                  text="Annuler"></app-btn>
              </div>
            </div>
            <input
              #fileInput
              (change)="onFileChange($event)"
              accept="image/*"
              style="display: none"
              type="file" />
          </div>
        </div>
        <div [routerLink]="'/agency/edit'" class="edit">
          <mat-icon>edit</mat-icon>
        </div>
      </div>
    </section>

    <section class="detail">
      <div>
        <h3>Directeur d'agence</h3>
        <p>
          {{ cabinet.prenom_responsable_cabinet }}
          {{ cabinet.nom_responsable_cabinet | uppercase }}
        </p>
      </div>
      <div>
        <h3>Signature électronique</h3>
        <p>
          {{ cabinet.email_signature_electronique }}
        </p>
        <p>
          {{ cabinet.mobile }}
        </p>
      </div>

      <div>
        <h3>Information juridiques</h3>
        <table>
          <tbody>
            <tr>
              <td><b>Capital</b></td>
              <td>{{ cabinet.capital }}</td>
            </tr>
            <tr>
              <td><b>SIREN</b></td>
              <td>{{ cabinet.siret }}</td>
            </tr>
            <tr>
              <td><b>N° TVA</b></td>
              <td>{{ cabinet.num_tva }}</td>
            </tr>
            <tr>
              <td><b>IBAN</b></td>
              <td>{{ cabinet.iban }}</td>
            </tr>
            <tr>
              <td><b>BIC</b></td>
              <td>{{ cabinet.bic }}</td>
            </tr>
            <tr>
              <td><b>Barêmes d'honoraires</b></td>
              <td>{{ cabinet.url_honoraires }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h3>Carte Pro</h3>
        <table>
          <tbody>
            <tr>
              <td><b>Numéro</b></td>
              <td>{{ cabinet.numero_carte_pro }}</td>
            </tr>
            <tr>
              <td><b>Délivré par</b></td>
              <td>
                {{ cabinet.carte_pro_delivree_par }}, <b>le</b>
                {{ cabinet.carte_pro_delivree_le }}
              </td>
            </tr>
            <tr>
              <td><b>Médiateur</b></td>
              <td>{{ cabinet.mediateur }}</td>
            </tr>
            <tr>
              <td><b>Organisme RCP</b></td>
              <td>{{ cabinet.nom_rcp }}</td>
            </tr>
            <tr>
              <td><b>Adresse RCP</b></td>
              <td>{{ cabinet.adresse_rcp }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h3>Générale</h3>
        <div class="website">
          <p>Site web :</p>
          <a
            [href]="'https://' + cabinet.siteweb"
            class="link"
            target="_blank"
            >{{ cabinet.siteweb }}</a
          >
        </div>
        <p>Présentation : {{ cabinet.presentation }}</p>
      </div>
    </section>
  </ng-container>
</div>
