<div class="archived-header">
  <p>{{ filteredMandats.length }} Mandats Archivés</p>
</div>
<div class="archived-header-mobile">
  <p>{{ filteredMandats.length }} Mandats Archivés</p>
</div>

<app-archived-mandats-filter
  (filterChange)="filterMandats($event)"
  (filterTrigger)="filterTrigger($event)"></app-archived-mandats-filter>

<div *ngIf="this.filterTriggered">
  <app-archived-mandats-table
    *ngIf="filteredMandats.length > 0"
    [dataSource]="filteredMandats" />
</div>
