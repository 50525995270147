import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { CabinetsInterface } from '@src/features/cabinets/domain/entities/cabinets.interface';
import { CabinetsSelectors } from '@src/features/cabinets/domain/redux/selectors/cabinets.selectors';
import { InvoicesInterface } from '@src/features/invoices/domain/entities/invoices.interface';
import { InvoicesSelector } from '@src/features/invoices/domain/redux/selectors/invoices.selector';

@UntilDestroy()
@Component({
  selector: 'app-agencies-list-container',
  templateUrl: './agencies-list-container.component.html',
  styleUrl: './agencies-list-container.component.scss',
})
export class AgenciesListContainerComponent implements OnInit {
  cabinets$ = this.store.select(CabinetsSelectors.cabinets);
  cabinet!: CabinetsInterface[];
  filteredCabinets!: CabinetsInterface[] | undefined;
  filterTriggered = true;
  allInvoices: InvoicesInterface[] = [];
  startDate = new Date(new Date().getFullYear(), 0, 1);
  endDate = new Date();

  columnsToDisplay: string[] = [
    'id',
    'nom',
    'responsable',
    'raison',
    'cheque',
    'ville',
    'leboncoin',
    'active',
    'ca',
  ];
  tableDataSource = new MatTableDataSource<CabinetsInterface>();
  @ViewChild(CdkVirtualScrollViewport) viewport!: CdkVirtualScrollViewport;

  private allData: CabinetsInterface[] = [];

  constructor(private store: Store) {}

  set dataSource(data: CabinetsInterface[]) {
    this.allData = data;
    this.tableDataSource.data = data.slice(0, 40); // Slice the data initially
  }

  ngOnInit() {
    this.fetchCabinets();
    this.fetchInvoices();
    this.dataSource = this.cabinet;
  }

  fetchCabinets() {
    this.cabinets$.pipe(untilDestroyed(this)).subscribe(cabinets => {
      if (cabinets) {
        this.cabinet = cabinets;
        this.filteredCabinets = cabinets;
      }
    });
  }

  fetchInvoices() {
    this.store
      .select(InvoicesSelector.getAllInvoices())
      .pipe(untilDestroyed(this))
      .subscribe(invoices => {
        this.allInvoices = invoices;
        console.log('invoices', invoices);
      });
  }

  onScroll() {
    const end = this.viewport.getRenderedRange().end;
    const total = this.viewport.getDataLength();
    if (end === total) {
      const newLength = this.tableDataSource.data.length + 40;
      const nextBatch = this.allData.slice(
        this.tableDataSource.data.length,
        newLength
      );
      this.tableDataSource.data = [...this.tableDataSource.data, ...nextBatch];
    }
  }

  filterTrigger($event: boolean) {
    this.filterTriggered = $event;
  }

  setDateRange($event: { debut: string | Date; fin: string | Date }) {
    this.startDate = new Date($event.debut);
    this.endDate = new Date($event.fin);
    console.log(this.calculateTurnover(501));
  }

  calculateTurnover(cabinetId: number) {
    const filteredInvoices = this.getFilteredInvoices(cabinetId);
    return filteredInvoices.reduce((acc, invoice) => {
      return acc + invoice.montant_honoraires_ht;
    }, 0);
  }

  private getFilteredInvoices(cabinetId: number) {
    const cabinetInvoices = this.allInvoices.filter(
      invoice => invoice.id_cabinet === cabinetId
    );
    if (!cabinetInvoices || cabinetInvoices.length === 0) {
      return [];
    }
    const filteredInvoices = cabinetInvoices.filter(invoice => {
      if (invoice.created_at?.date) {
        const invoiceDate = new Date(invoice.created_at.date).getTime();
        return (
          invoiceDate >= this.startDate.getTime() &&
          invoiceDate <= this.endDate.getTime()
        );
      }
      return false;
    });
    return filteredInvoices;
  }
}
