<div class="bottom-nav-container">
  <div class="nav-item">
    <a
      (click)="navigationGoback()"
      (keydown.enter)="navigationGoback()"
      role="button"
      tabindex="0">
      <img
        alt="Ouvrir/Fermer le menu"
        height="24"
        ngSrc="./assets/icons/arrow.svg"
        style="rotate: 90deg"
        width="24" />
    </a>

    <p class="icon-label">Retour</p>
  </div>
  <div class="nav-item">
    <img
      [queryParams]="{ prospection: 'false' }"
      [routerLink]="'mandats/checking'"
      alt="Mandat"
      height="24"
      ngSrc="./assets/navbar/new.png"
      width="24" />
    <p class="icon-label">Créer</p>
  </div>
  <div class="nav-item">
    <span
      [hidden]="this.numberOfSelectedMandatsInVisitForm === 0"
      [matBadge]="this.numberOfSelectedMandatsInVisitForm"
      [routerLink]="'bon-de-visite'"
      matBadgeColor="primary"
      matBadgeSize="small">
      <img
        alt="Mandat Icon"
        height="24"
        src="./assets/icons/cart.svg"
        width="24" />
    </span>
    <img
      *ngIf="this.numberOfSelectedMandatsInVisitForm === 0"
      [routerLink]="'bon-de-visite'"
      alt="Mandat Icon"
      height="24"
      src="./assets/icons/cart.svg"
      width="24" />
    <p class="icon-label">Bon de visite</p>
  </div>
</div>
