import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationSelectors } from '@features/authentification/domain/redux/selectors/authentication.selectors';
import { ContactInterface } from '@features/contacts/domain/entities/contacts.interface';
import {
  CreateGlobalMandatDto,
  CreateMandatDto,
} from '@features/mandats/domain/dto/create-global-mandat.dto';
import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';
import { MoraleOrPhysiqueEnum } from '@features/mandats/domain/entities/mandat.interface';
import { MandatValidators } from '@features/mandats/domain/forms-validators/mandat-validators';
import { SalePointValidators } from '@features/mandats/domain/forms-validators/sale-point-validators';
import { UpdateMandat } from '@features/mandats/domain/redux/actions/mandats.actions';
import { MandatsSelectors } from '@features/mandats/domain/redux/selectors/mandats.selectors';
import { MandatsProspectionSelectors } from '@features/mandats/domain/redux/selectors/prospection.selectors';
import { NavigateToPreviousUrl } from '@features/nav/domain/redux/actions/nav.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { CompanieForm } from '@src/shared/forms/companies/companies.form';
import { ContactForm } from '@src/shared/forms/contact/contact.form';
import { MandatAnnonceForm } from '@src/shared/forms/mandat/mandat-annonce.form';
import { MandatCommentaireForm } from '@src/shared/forms/mandat/mandat-commentaire.form';
import { MandatDiffusionForm } from '@src/shared/forms/mandat/mandat-diffusion.form';
import { GeneralInformationsForm } from '@src/shared/forms/mandat/mandat-general-informations.form';
import { MandatPredefinedForm } from '@src/shared/forms/mandat/mandat-predefined.form';
import { FinancialForm } from '@src/shared/forms/sale-point/financial.form';
import { PriceForm } from '@src/shared/forms/sale-point/price.form';
import { SalePointForm } from '@src/shared/forms/sale-point/sale-point.form';
import { PappersCompaniesInterface } from '@src/shared/interaces/comapnies/entities/pappers-companies.interface';
import { CreateSalePointDto } from '@src/shared/interaces/salePoints/sale-point.interface';
import { Observable, of, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';

type Dto = CreateMandatDto | CreateSalePointDto;

@UntilDestroy()
@Component({
  selector: 'app-mandat-update-container',
  templateUrl: './mandat-update.container.component.html',
  styleUrl: './mandat-update.container.component.scss',
})
export class MandatUpdateContainerComponent implements OnInit {
  @Select(AuthenticationSelectors.userCabinetId)
  cabinetId$!: Observable<string>;
  cabinetId!: string;
  annonceFormBuilder = new MandatAnnonceForm();
  commentaireFormBuilder = new MandatCommentaireForm();
  diffusionFormBuilder = new MandatDiffusionForm();
  generalInformationsFormBuilder = new GeneralInformationsForm(false);
  mandatPredefinedFormBuilder = new MandatPredefinedForm(false, this.cabinetId);
  companyFormBuilder = new CompanieForm();
  contactFormBuilder = new ContactForm(this.cabinetId);
  bailleurContactFormBuilder = new ContactForm(this.cabinetId, true);
  salesPointFormBuilder = new SalePointForm(false);
  priceFormBuilder = new PriceForm(false);
  financialFormBuilder = new FinancialForm(false);
  annonceForm!: FormGroup;
  commentaireForm!: FormGroup;
  diffusionForm!: FormGroup;
  generalInformationsForm!: FormGroup;
  mandatPredefinedForm!: FormGroup;
  mandantCompanyForm!: FormGroup;
  bailleurCompanyForm!: FormGroup;
  mandantContactForm!: FormGroup;
  bailleurContactForm!: FormGroup;
  salesPointForm!: FormGroup;
  priceForm!: FormGroup;
  financialForm!: FormGroup;
  isProspection = false;
  mandatValidators!: MandatValidators;
  salePointValidators!: SalePointValidators;
  mandat$!: Observable<MandatDetailInterface | null | undefined>;
  mandat!: MandatDetailInterface;
  private readonly id: number;

  constructor(
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private store: Store
  ) {
    this.cabinetId$.pipe(untilDestroyed(this)).subscribe(cabinetId => {
      this.cabinetId = cabinetId;
    });
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
      this.isProspection = params['prospection'] === 'true';
    });
    this.id = Number(this.route.snapshot.paramMap.get('id'));
  }

  ngOnInit() {
    /**
     * Get the mandat by id from the store
     * If the mandat is not found in the mandats store, we try to find it in the prospection store
     * If the mandat is not found in the prospection store, we return null
     * do not delete this comment
     */
    this.mandat$ = this.store
      .select(MandatsSelectors.getMandatById(this.id))
      .pipe(
        switchMap(mandat => {
          if (mandat) {
            return of(mandat);
          } else {
            return this.store
              .select(MandatsProspectionSelectors.getMandatById(this.id))
              .pipe(catchError(() => of(null)));
          }
        })
      );

    this.mandat$.pipe(untilDestroyed(this)).subscribe(mandat => {
      if (mandat) {
        this.mandat = mandat;
        console.log(this.mandat);
      }
    });
    this.buildForms();
  }

  markAllFormsAsTouched(): void {
    this.markFormControlsAsTouched(this.annonceForm);
    this.markFormControlsAsTouched(this.financialForm);
    this.markFormControlsAsTouched(this.commentaireForm);
    this.markFormControlsAsTouched(this.priceForm);
    this.markFormControlsAsTouched(this.salesPointForm);
    this.markFormControlsAsTouched(this.diffusionForm);
    this.markFormControlsAsTouched(this.generalInformationsForm);
    this.markFormControlsAsTouched(this.bailleurCompanyForm);
    this.markFormControlsAsTouched(this.bailleurContactForm);
    this.markFormControlsAsTouched(this.mandantCompanyForm);
    this.markFormControlsAsTouched(this.mandantContactForm);
  }

  updateMandat() {
    console.log('updateMandat');
    const mandat: CreateMandatDto | null = this.getMandatDto();
    const salePoint: CreateSalePointDto | null = this.getSalePointDto();
    if (!mandat || !salePoint) return;

    const { morale_or_physique_mandant, morale_or_physique_bailleur } = mandat;

    const bailleurContact: ContactInterface | null =
      this.getContactIfValueExists(this.bailleurContactForm);
    const bailleurCompanies: PappersCompaniesInterface | null =
      this.getCompaniesIfMorale(
        morale_or_physique_bailleur,
        this.bailleurCompanyForm
      );
    const mandantCompanies: PappersCompaniesInterface | null =
      this.getCompaniesIfMorale(
        morale_or_physique_mandant,
        this.mandantCompanyForm
      );
    const mandantContact = this.mandantContactForm.value;
    mandat.id = this.mandat.mandat.id;
    mandat.etat = this.mandat.mandat.etat;
    const mandatToCreate: CreateGlobalMandatDto = {
      mandat,
      salePoint,
      bailleurCompanies: bailleurCompanies ?? null,
      mandantContact: mandantContact,
      mandantCompanies: mandantCompanies ?? null,
      bailleurContact: bailleurContact ?? null,
    };

    this.store.dispatch(new UpdateMandat(mandatToCreate));
  }

  navigatToPreviousUrl() {
    this.store.dispatch(new NavigateToPreviousUrl());
  }

  private getContactIfValueExists(contactForm: FormGroup) {
    if (contactForm.value) {
      const contact = contactForm.value;
      return contact;
    }

    return null;
  }

  private getCompaniesIfMorale(
    morale_or_physique: MoraleOrPhysiqueEnum,
    form: FormGroup
  ) {
    if (morale_or_physique === MoraleOrPhysiqueEnum.morale) {
      const companies = form.value;
      return companies;
    }

    return null;
  }

  private buildForms(): void {
    this.annonceForm = this.annonceFormBuilder.createFormGroup();
    this.commentaireForm = this.commentaireFormBuilder.createFormGroup();
    this.diffusionForm = this.diffusionFormBuilder.createFormGroup();
    this.generalInformationsForm =
      this.generalInformationsFormBuilder.createFormGroup();

    this.mandatPredefinedForm = new MandatPredefinedForm(
      false,
      this.cabinetId
    ).createFormGroup();

    this.mandantCompanyForm = this.companyFormBuilder.createFormGroup();
    this.bailleurCompanyForm = this.companyFormBuilder.createFormGroup();
    this.mandantContactForm = this.contactFormBuilder.createFormGroup();

    // Utilisation de this.cabinetId dans le FormBuilder
    this.bailleurContactForm = new ContactForm(
      this.cabinetId
    ).createFormGroup();

    this.salesPointForm = this.salesPointFormBuilder.createFormGroup();
    this.priceForm = this.priceFormBuilder.createFormGroup();
    this.financialForm = this.financialFormBuilder.createFormGroup();
    this.mandatValidators = new MandatValidators(
      this.annonceForm,
      this.commentaireForm,
      this.diffusionForm,
      this.generalInformationsForm,
      this.mandatPredefinedForm,
      this.isProspection,
      this.priceForm,
      this.financialForm
    );
    this.salePointValidators = new SalePointValidators(
      this.mandatPredefinedForm,
      this.financialForm,
      this.salesPointForm
    );
    this.cdr.markForCheck();
    this.updateForms();
  }

  private updateForms(): void {
    this.annonceForm.patchValue(this.mandat.mandat);
    this.financialForm.patchValue(this.mandat.salePoints);
    this.commentaireForm.patchValue(this.mandat.mandat);
    this.priceForm.patchValue(this.mandat.mandat);
    this.salesPointForm.patchValue(this.mandat.salePoints);
    this.diffusionForm.patchValue(this.mandat.mandat);
    this.generalInformationsForm.patchValue(this.mandat.mandat);
    if (this.mandat.bailleurCompanies) {
      this.bailleurCompanyForm.patchValue(this.mandat.bailleurCompanies);
    }
    if (this.mandat.bailleurContact) {
      this.bailleurContactForm.patchValue(this.mandat.bailleurContact);
    }
    if (this.mandat.mandantCompanies) {
      this.mandantCompanyForm.patchValue(this.mandat.mandantCompanies);
    }
    if (this.mandat.mandantContact) {
      this.mandantContactForm.patchValue(this.mandat.mandantContact);
    }

    this.markAllFormsAsTouched();
  }

  private markFormControlsAsTouched(form: FormGroup): void {
    Object.values(form.controls).forEach(control => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormControlsAsTouched(control);
      } else if (control instanceof FormArray) {
        control.controls.forEach(c =>
          this.markFormControlsAsTouched(c as FormGroup)
        );
      }
    });
  }

  private getMandatDto(): CreateMandatDto | null {
    const mandat: CreateMandatDto = this.mandatValidators.checkMandatValue();
    return this.validateDto(mandat, 'mandatValidators.checkMandatValue')
      ? mandat
      : null;
  }

  private getSalePointDto(): CreateSalePointDto | null {
    const salePoint: CreateSalePointDto =
      this.salePointValidators.checkSalePointValue();
    return this.validateDto(
      salePoint,
      'salePointValidators.checkSalePointValue'
    )
      ? salePoint
      : null;
  }

  private validateDto(dto: Dto | null, validatorName: string): boolean {
    if (dto == null) {
      console.error(`${validatorName} returned an invalid value`);
      return false;
    }

    return true;
  }
}
