import { Component, OnInit } from '@angular/core';
import { UrlListenerService } from '@features/nav/adapters/secondary/url-listener.service';
import { Select, Store } from '@ngxs/store';
import {
  AuthenticationState,
  AuthenticationStateModel,
} from '@features/authentification/domain/redux/state/authentication.state';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Logout } from '@features/authentification/domain/redux/actions/authentication.action';

@UntilDestroy()
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavbarComponent implements OnInit {
  @Select(AuthenticationState) user$!: Observable<AuthenticationStateModel>;
  isOpen: boolean[] = Array(8).fill(false);
  consultantId: number | undefined;

  constructor(
    private urlListenerService: UrlListenerService,
    private store: Store
  ) {}

  ngOnInit() {
    this.user$.pipe(untilDestroyed(this)).subscribe(user => {
      this.consultantId = user.user?.id;
    });
  }

  logout() {
    this.store.dispatch(new Logout());
  }
}
