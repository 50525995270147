import { FormGroup } from '@angular/forms';
import { CreateMandatDto } from '@features/mandats/domain/dto/create-global-mandat.dto';
import {
  MandatStateEnum,
  MandatTransactionEnum,
} from '@features/mandats/domain/entities/mandat.interface';

export class MandatValidators {
  mandatValue!: CreateMandatDto;

  constructor(
    private annonceForm: FormGroup,
    private commentaireForm: FormGroup,
    private diffusionForm: FormGroup,
    private generalInformationsForm: FormGroup,
    private mandatPredefinedForm: FormGroup,
    private isProspection: boolean,
    private priceForm: FormGroup,
    private financialForm: FormGroup
  ) {}

  checkMandatValue() {
    this.mandatValue = {
      id_cabinet: this.mandatPredefinedForm.value.id_cabinet,
      id_consultant: this.generalInformationsForm.value.id_consultant,
      id_forme_juridique: this.generalInformationsForm.value.id_forme_juridique,
      id_activite: this.generalInformationsForm.value.id_activite,
      id_rubrique_cession_pme:
        this.generalInformationsForm.value.id_rubrique_cession_pme,
      exclusivite: this.generalInformationsForm.value.exclusivite,
      etat: this.isProspection
        ? MandatStateEnum.prospection
        : MandatStateEnum.preMandat,
      etat_murs: this.generalInformationsForm.value.etat_murs,
      titre: this.annonceForm.value.titre?.trim(),
      description: this.annonceForm.value.description.trim(),
      transaction: MandatTransactionEnum.sell,
      prix_de_presentation: this.priceForm?.value?.prix_de_presentation,
      honoraires_vendeur: this.priceForm.value.honoraires_vendeur,
      honoraires_acquereur: this.priceForm.value.honoraires_acquereur,
      droits_mutation: this.priceForm.value.droits_mutation,
      tva: this.financialForm.value.provision_tva,
      option_tva: this.priceForm.value.option_tva,
      commentaires: this.commentaireForm.value.commentaire?.trim(),
      vilcespme: this.diffusionForm.value.vilcespme,
      CPdiffusion: this.diffusionForm.value.CPdiffusion,
      morale_or_physique_mandant:
        this.mandatPredefinedForm.value.morale_or_physique_mandant,
      morale_or_physique_bailleur:
        this.mandatPredefinedForm.value.morale_or_physique_bailleur,
    };
    return this.mandatValue;
  }
}
