import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MoraleOrPhysiqueEnum } from '@features/mandats/domain/entities/mandat.interface';
import { ShortPappersCompanyInterface } from '@src/shared/interaces/comapnies/entities/pappers-companies-short.interface';

@Component({
  selector: 'app-bailleur-companies',
  templateUrl: './bailleur-companies.component.html',
  styleUrls: ['./bailleur-companies.component.scss'],
})
export class BailleurCompaniesComponent implements OnInit {
  @Input({ required: true }) companiesBailleurForm!: FormGroup;
  @Input({ required: true }) mandatPredefinedForm!: FormGroup;
  @Input() isEditMode: boolean = false;
  moralOrPhysicalControl: FormControl<boolean | null> = new FormControl(false);
  shouldDisplaySearchCompaniesBar: boolean = true;
  backupCompaniesBailleurForm: FormGroup = this.companiesBailleurForm;
  canCancelTheChangeCompany: boolean = false;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.shouldDisplaySearchCompaniesBar =
      !this.isEditMode || !this.companiesBailleurForm.value;

    if (this.companiesBailleurForm.value) {
      this.backupCompaniesBailleurForm = this.companiesBailleurForm;
    }
    this.mandatPredefinedForm
      .get('morale_or_physique_bailleur')
      ?.setValue(
        this.moralOrPhysicalControl.value === true
          ? MoraleOrPhysiqueEnum.physique
          : MoraleOrPhysiqueEnum.morale
      );
  }

  getPappersResult(event: ShortPappersCompanyInterface): void {
    this.companiesBailleurForm.patchValue({
      forme_juridique: event.forme_juridique,
      adresse_ligne_1: event.adresse_ligne_1,
      adresse_ligne_2: event.adresse_ligne_2,
      code_postal: event.code_postal,
      ville: event.ville,
      complement_adresse: event.complement_adresse,
      denomination: event.denomination,
      domaine_activite: event.domaine_activite,
      indice_repetition: event.indice_repetition,
      libelle_code_naf: event.libelle_code_naf,
      libelle_voie: event.libelle_voie,
      nic: event.nic,
      nom: event.nom,
      nom_entreprise: event.nom_entreprise,
      numero_voie: event.numero_voie,
      prenom: event.prenom,
      siren: event.siren,
      siren_formate: event.siren_formate,
      type_search: event.type_search,
      type_voie: event.type_voie,
    });
    this.shouldDisplaySearchCompaniesBar = false;
  }

  resetFormFromChildCall() {
    const formValues = this.companiesBailleurForm.getRawValue();
    this.backupCompaniesBailleurForm = this.fb.group(formValues);
    this.companiesBailleurForm.reset();
    this.canCancelTheChangeCompany = true;
    this.mandatPredefinedForm.patchValue({
      morale_or_physique_mandant: MoraleOrPhysiqueEnum.physique,
    });
    this.shouldDisplaySearchCompaniesBar = false;
  }

  toggleBackToSearch() {
    this.shouldDisplaySearchCompaniesBar = true;
    this.mandatPredefinedForm.patchValue({
      morale_or_physique_mandant: MoraleOrPhysiqueEnum.morale,
    });
  }

  setOnlyPhysique() {
    this.shouldDisplaySearchCompaniesBar = false;
    const formValues = this.companiesBailleurForm.getRawValue();
    this.backupCompaniesBailleurForm = this.fb.group(formValues);
    this.companiesBailleurForm.reset();
    this.canCancelTheChangeCompany = true;
    this.mandatPredefinedForm.patchValue({
      morale_or_physique_mandant: MoraleOrPhysiqueEnum.physique,
    });
  }

  changeCompanyFromChildCall() {
    this.shouldDisplaySearchCompaniesBar = true;
    const formValues = this.companiesBailleurForm.getRawValue();
    this.backupCompaniesBailleurForm = this.fb.group(formValues);
    this.companiesBailleurForm.reset();
    this.canCancelTheChangeCompany = true;
    this.mandatPredefinedForm.patchValue({
      morale_or_physique_mandant: MoraleOrPhysiqueEnum.morale,
    });
    this.companiesBailleurForm.reset();
  }

  backupForm() {
    this.companiesBailleurForm = this.backupCompaniesBailleurForm;
    this.shouldDisplaySearchCompaniesBar = false;
    this.canCancelTheChangeCompany = false;
  }
}
