import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';
import { MandatsFilterParams } from '@features/mandats/domain/entities/mandat-filter-params.interface';

export class MandatsFilter {
  static readonly NO_SEARCH_VALUE = 0;
  static readonly NO_SEARCH_VALUE_STRING = '0';

  static filterMandats(params: MandatsFilterParams) {
    return [...params.mandats]
      .filter(this.filterActivityOption(params))
      .filter(this.filterLegalformOption(params))
      .filter(this.filterStatusOption(params))
      .filter(this.filterSelectedConsultant(params))
      .filter(this.filterPriceRange(params))
      .filter(this.filterSurfaceRange(params))
      .filter(this.filterCheckboxValues(params))
      .filter(this.filterCityValue(params))
      .filter(this.filterStreetValue(params))
      .filter(this.filterSearchValue(params));
  }

  static resetParams(mandat: MandatDetailInterface[]) {
    return {
      mandats: mandat,
      activityOption: this.NO_SEARCH_VALUE,
      legalformOption: this.NO_SEARCH_VALUE,
      statusOptionValue: this.NO_SEARCH_VALUE_STRING,
      statusOption: this.NO_SEARCH_VALUE,
      selectedConsultant: this.NO_SEARCH_VALUE,
      minPrice: 0,
      maxPrice: Infinity,
      minSurface: 0,
      maxSurface: Infinity,
      checkboxValues: { with: false },
      cityValue: '',
      streetValue: '',
      searchValue: '',
    };
  }

  private static filterActivityOption(params: MandatsFilterParams) {
    return (mandat: MandatDetailInterface) =>
      params.activityOption === this.NO_SEARCH_VALUE ||
      mandat.mandat.id_activite === params.activityOption;
  }

  private static filterLegalformOption(params: MandatsFilterParams) {
    return (mandat: MandatDetailInterface) =>
      params.legalformOption === this.NO_SEARCH_VALUE ||
      mandat.mandat.id_forme_juridique === params.legalformOption;
  }

  private static filterStatusOption(params: MandatsFilterParams) {
    return (mandat: MandatDetailInterface) =>
      params.statusOptionValue === this.NO_SEARCH_VALUE_STRING ||
      mandat.mandat.etat === params.statusOptionValue;
  }

  private static filterSelectedConsultant(params: MandatsFilterParams) {
    return (mandat: MandatDetailInterface) =>
      params.selectedConsultant === this.NO_SEARCH_VALUE ||
      mandat.mandat.id_consultant === params.selectedConsultant;
  }

  private static filterPriceRange(params: MandatsFilterParams) {
    return (mandat: MandatDetailInterface) =>
      (params.minPrice <= 0 ||
        mandat.mandat.prix_de_presentation >= params.minPrice) &&
      (params.maxPrice === Infinity ||
        mandat.mandat.prix_de_presentation <= params.maxPrice);
  }

  private static filterSurfaceRange(params: MandatsFilterParams) {
    return (mandat: MandatDetailInterface) =>
      (params.minSurface <= 0 ||
        (mandat.salePoints?.surface_commerciale ?? 0) >= params.minSurface) &&
      (params.maxSurface === Infinity ||
        (mandat.salePoints?.surface_commerciale ?? 0) <= params.maxSurface);
  }

  private static filterCheckboxValues(params: MandatsFilterParams) {
    return (mandat: MandatDetailInterface) =>
      !params.checkboxValues.with || !!mandat.salePoints?.presence_extraction;
  }

  private static filterCityValue(params: MandatsFilterParams) {
    return (mandat: MandatDetailInterface) => {
      if (!params.cityValue.length) return true;
      const cityValueLowerCase = params.cityValue.toLowerCase();
      return String(mandat.salePoints?.locality?.toLowerCase()).includes(
        cityValueLowerCase
      );
    };
  }

  private static filterStreetValue(params: MandatsFilterParams) {
    return (mandat: MandatDetailInterface) => {
      if (!params.streetValue.length) return true;
      const streetValueLowerCase = params.streetValue.toLowerCase();
      return (
        String(mandat.salePoints?.route?.toLowerCase()).includes(
          streetValueLowerCase
        ) ||
        String(mandat.salePoints?.street_number).includes(streetValueLowerCase)
      );
    };
  }

  private static filterSearchValue(params: MandatsFilterParams) {
    return (mandat: MandatDetailInterface) => {
      if (!params.searchValue.length) return true;
      const searchValueLowerCase = params.searchValue.toLowerCase();
      return [
        String(mandat.mandantContact.nom?.toLowerCase()),
        String(mandat.salePoints?.type_voie?.toLowerCase()),
        String(mandat.salePoints?.postal_code),
        String(mandat.salePoints?.locality?.toLowerCase()),
        String(mandat.salePoints?.street_number),
        String(mandat.mandat.numero_registre_mandats),
        String(mandat.salePoints?.enseigne?.toLowerCase()),
      ].some(field => field.toLowerCase().includes(searchValueLowerCase));
    };
  }
}
