import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-acquereur-bottom-menu',
  templateUrl: './acquereur-bottom-menu.component.html',
  styleUrl: './acquereur-bottom-menu.component.scss',
})
export class AcquereurBottomMenuComponent {
  @Input() navigationGoback!: () => void;

  constructor(private readonly router: Router) {}
}
