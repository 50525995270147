import { FormControl, FormGroup } from '@angular/forms';
import { FormSectionBase } from '../form.class';

export class CabinetFeesForm extends FormSectionBase {
  private idCabinet: string;
  constructor(idCabinet: string) {
    super();
    this.idCabinet = idCabinet;
  }

  createFormGroup() {
    return new FormGroup({
      id: new FormControl(this.idCabinet),
      taux1fdc: new FormControl(''),
      taux2fdc: new FormControl(''),
      taux3fdc: new FormControl(''),
      taux4fdc: new FormControl(''),
      taux1dab: new FormControl(''),
      taux2dab: new FormControl(''),
      taux3dab: new FormControl(''),
      taux4dab: new FormControl(''),
      taux_loc: new FormControl(''),
      taux1murs: new FormControl(''),
      taux2murs: new FormControl(''),
      taux3murs: new FormControl(''),
      taux4murs: new FormControl(''),
      tranche1fdc: new FormControl(''),
      tranche2fdc: new FormControl(''),
      tranche3fdc: new FormControl(''),
      tranche4fdc: new FormControl(''),
      tranche5fdc: new FormControl(''),
      tranche1dab: new FormControl(''),
      tranche2dab: new FormControl(''),
      tranche3dab: new FormControl(''),
      tranche4dab: new FormControl(''),
      tranche5dab: new FormControl(''),
      tranche1murs: new FormControl(''),
      tranche2murs: new FormControl(''),
      tranche3murs: new FormControl(''),
      tranche4murs: new FormControl(''),
      tranche5murs: new FormControl(''),
      descriptif_loc: new FormControl(''),
      fdcttc1: new FormControl(''),
      fdcttc2: new FormControl(''),
      fdcttc3: new FormControl(''),
      dabttc1: new FormControl(''),
      dabttc2: new FormControl(''),
      dabttc3: new FormControl(''),
      locttc: new FormControl(''),
      mursttc1: new FormControl(''),
      mursttc2: new FormControl(''),
      mursttc3: new FormControl(''),
      facturationmin: new FormControl(''),
      facturationminttc: new FormControl(''),
    });
  }
}
