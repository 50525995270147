import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckingSalePointComponent } from '@features/mandats/adapters/primary/ui/components/create/checking-sale-point/checking-sale-point.component';
import { MandatCreateContainerComponent } from '@features/mandats/adapters/primary/ui/containers/mandat-create-container/mandat-create-container.component';
import { MandatDetailViewContainerComponent } from '@features/mandats/adapters/primary/ui/containers/mandat-detail-view-container/mandat-detail-view-container.component';
import { MandatUpdateContainerComponent } from '@features/mandats/adapters/primary/ui/containers/mandat-update-container/mandat-update.container.component';
import { MandatsContainerComponent } from '@features/mandats/adapters/primary/ui/containers/mandats-container/mandats-container.component';
import { ArchivedMandatsContainerComponent } from '@features/mandats/adapters/primary/ui/containers/archived-mandats.container/archived-mandats.container.component';
import { ArchivedMandatsDetailComponent } from '@features/mandats/adapters/primary/ui/components/archived/archived-mandats-detail/archived-mandats-detail.component';
import { LessorsContainerComponent } from '@features/mandats/adapters/primary/ui/containers/lessors-container/lessors-container.component';

const routes: Routes = [
  {
    path: '',
    component: MandatsContainerComponent,
  },
  {
    path: 'prospections',
    component: MandatsContainerComponent,
  },
  {
    path: 'archives',
    component: MandatsContainerComponent,
  },
  {
    path: 'details/:id',
    component: MandatDetailViewContainerComponent,
    title: 'Détail du mandat',
  },
  {
    path: 'update/:id',
    component: MandatUpdateContainerComponent,
    title: 'Modification du mandat',
  },
  {
    path: 'checking',
    component: CheckingSalePointComponent,
    title: 'Vérification du point de vente',
  },
  {
    path: 'create',
    component: MandatCreateContainerComponent,
    title: "Création d'un mandat",
  },
  {
    path: 'archived',
    component: ArchivedMandatsContainerComponent,
  },
  {
    path: 'archived/details/:id',
    component: ArchivedMandatsDetailComponent,
  },
  {
    path: 'lessors',
    component: LessorsContainerComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MandatsRoutingModule {}
