import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivateMandatDto } from '@features/mandats/domain/dto/activate-mandat.dto';
import { CreateGlobalMandatDto } from '@features/mandats/domain/dto/create-global-mandat.dto';
import {
  ArchivateMandatDto,
  ArchivateMandatInterface,
} from '@features/mandats/domain/entities/archivate-mandat.interface';
import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';
import { MandatInterface } from '@features/mandats/domain/entities/mandat.interface';
import { MandatApiPort } from '@features/mandats/domain/ports/api/mandat-api.port';
import { environment } from '@src/environments/environment';
import { EMPTY, expand, Observable, reduce, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MandatsApiService implements MandatApiPort {
  apiPath = environment.apiUrl;

  constructor(private http: HttpClient) {}

  /*  getMandats(): Observable<MandatDetailInterface[]> {
      return this.http.get<MandatDetailInterface[]>(`${this.apiPath}/mandats`);
    }*/

  getArchivedMandats(): Observable<ArchivateMandatInterface[]> {
    return this.http.get<ArchivateMandatInterface[]>(
      `${this.apiPath}/mandats-archives`
    );
  }

  //TODO VOIR POUR LE TYPE
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  createMandat(mandat: CreateGlobalMandatDto): Observable<any> {
    return this.http.post(`${this.apiPath}/mandats/create`, mandat);
  }

  getMandats(): Observable<MandatDetailInterface[]> {
    let page = 0;
    const offset = 2000;
    return this.loadPage(page, offset, 'mandatDetails').pipe(
      expand(mandats =>
        mandats.length < offset
          ? EMPTY
          : this.loadPage(++page, offset, 'mandatDetails')
      ),
      reduce(
        (acc: MandatDetailInterface[], mandats: MandatDetailInterface[]) =>
          acc.concat(mandats),
        [] as MandatDetailInterface[]
      ),
      map(mandats => {
        const uniqueMandatsSet = new Set();
        const uniqueMandats = [];
        for (const mandat of mandats) {
          const mandatStr = JSON.stringify(mandat);
          if (!uniqueMandatsSet.has(mandatStr)) {
            uniqueMandatsSet.add(mandatStr);
            uniqueMandats.push(mandat);
          }
        }
        return uniqueMandats;
      }),
      catchError(error => {
        console.error('Error in getMandat function: ', error);
        return throwError(() => error);
      })
    );
  }

  loadPage(
    page: number,
    offset: number,
    route: string
  ): Observable<MandatDetailInterface[]> {
    return this.http
      .get<
        MandatDetailInterface[]
      >(`${this.apiPath}${route}?page=${page}&offset=${offset}`)
      .pipe(
        catchError(error => {
          console.error('Error in loadPage function: ', error);
          return throwError(error);
        })
      );
  }

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  activateMandat(body: ActivateMandatDto): Observable<any> {
    return this.http.post(`${this.apiPath}/mandats/activate`, body);
  }

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateMandat(mandat: CreateGlobalMandatDto): Observable<any> {
    return this.http.put(`${this.apiPath}/mandats/update`, mandat);
  }

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  archivateMandat(body: ArchivateMandatDto): Observable<any> {
    return this.http.post(`${this.apiPath}/mandats/archivate`, body);
  }

  swapToPm(id: number): Observable<void> {
    return this.http.post<void>(`${this.apiPath}/mandats/swap-to-pm`, { id });
  }

  swapToProspection(id: number): Observable<void> {
    return this.http.post<void>(`${this.apiPath}/mandats/swap-to-prospection`, {
      id,
    });
  }

  swapToProcedure(id: number): Observable<void> {
    return this.http.post<void>(`${this.apiPath}/mandats/swap-to-procedure`, {
      id,
    });
  }

  checkMandatPdf($filename: string) {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.http.get<any>(
      this.apiPath + 'mandats/pdf-exist/' + encodeURIComponent($filename)
    );
  }

  sendToYousign(mandat: MandatInterface, filename: string) {
    return this.http.get(
      this.apiPath +
        'mandats/yousign/' +
        mandat.id_forme_juridique +
        '/' +
        mandat.id +
        '/' +
        filename
    );
  }

  deleteMandatElecPdf($filename: string) {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.http.delete<any>(
      this.apiPath + 'mandats/pdf-exist/' + encodeURIComponent($filename)
    );
  }
}
