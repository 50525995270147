<div class="container">
  <div class="search-bar mt-10">
    <div style="position: relative; display: inline-block">
      <button
        (click)="trigerFilter()"
        style="border: none; background: none; padding: 0">
        <img
          alt="Filter"
          height="34"
          src="assets/icons/filter.svg"
          width="34" />
      </button>
    </div>
  </div>
  <div class="selection-container">
    <div class="search-item">
      <label for="date-debut">Date de début :</label>
      <app-input-slide
        (ngModelChange)="onDateDebutChange($event)"
        [(ngModel)]="filters.debut"
        id="date-debut"
        inputType="date">
      </app-input-slide>
    </div>
    <div class="search-item">
      <label for="date-fin">Date de fin :</label>
      <app-input-slide
        (ngModelChange)="onDateFinChange($event)"
        [(ngModel)]="filters.fin"
        id="date-fin"
        inputType="date">
      </app-input-slide>
    </div>
  </div>
</div>

<div *ngIf="!filterTriggered" class="mobile-search">
  <h3 class="text-center">Période</h3>

  <app-input-slide
    (ngModelChange)="onDateDebutChange($event)"
    [ngModel]="filters.debut"
    inputType="date"
    placeholder="Date de début" />

  <app-input-slide
    (ngModelChange)="onDateFinChange($event)"
    [ngModel]="filters.fin"
    inputType="date"
    placeholder="Date de fin" />

  <div class="btn-container">
    <app-btn
      (btnClick)="resetFilters(); trigerFilter()"
      color="alerte"
      text="Annuler" />
    <app-btn (btnClick)="trigerFilter()" color="validate" text="Valider" />
  </div>
</div>
