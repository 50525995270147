import { createSelector, Selector } from '@ngxs/store';
import {
  InvoicesState,
  InvoicesStateModel,
} from '@features/invoices/domain/redux/state/invoices.state';
import { InvoicesInterface } from '@features/invoices/domain/entities/invoices.interface';

export class InvoicesSelector {
  @Selector([InvoicesState])
  static invoices(state: InvoicesStateModel): InvoicesInterface[] {
    return state.invoices;
  }

  static getAllInvoices() {
    return createSelector(
      [InvoicesState],
      (state: InvoicesStateModel): InvoicesInterface[] => {
        return state.allInvoices;
      }
    );
  }

  static searchInvoice(searchTerm: string) {
    return createSelector(
      [InvoicesState],
      (state: InvoicesStateModel): InvoicesInterface[] => {
        return state.invoices.filter(invoice =>
          invoice.facture_num.includes(searchTerm)
        );
      }
    );
  }
}
