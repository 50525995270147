<div appDisableScroll>
  <app-mandat-header
    (resetFilters)="handleResetFilters()"
    *ngIf="mandatToDisplay && mandats"
    [filteredMandatsLength]="mandatToDisplay.length"
    [isProspection]="isProspection"
    [mandatsLength]="mandats.length" />
  <app-mandat-search-tab
    (filteredMandatsChange)="updateMandatList($event)"
    (filtersActiveChange)="handleFiltersActiveChange($event)"
    [isProspection]="isProspection"
    [mandats]="mandats">
  </app-mandat-search-tab>

  <app-mandat-active-table
    *ngIf="mandatShouldBeDisplayed && mandats.length !== 0"
    [isProspection]="isProspection"
    [mandatsToDisplay]="mandatToDisplay"
    [mandats]="mandats">
  </app-mandat-active-table>

  <div *ngIf="mandatShouldBeDisplayed && mandats.length === 0" class="item">
    <ngx-skeleton-loader appearance="line" count="15"></ngx-skeleton-loader>
  </div>

  <div
    *ngIf="
      mandatShouldBeDisplayed &&
      mandatToDisplay.length === 0 &&
      mandats.length > 0
    "
    class="no-mandat">
    <h2>Aucun mandat ne correspond à votre recherche</h2>
    <app-btn
      (btnClick)="handleResetFilters()"
      class="delete-filters-btn"
      color="ternary"
      text="Supprimer les filtres" />
  </div>
</div>
