import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationSelectors } from '@features/authentification/domain/redux/selectors/authentication.selectors';
import { ActivateMandatPopUpComponent } from '@features/mandats/adapters/primary/ui/components/activate-mandat-pop-up/activate-mandat-pop-up.component';
import { MandatsApiService } from '@features/mandats/adapters/secondary/real/mandats-api.service';
import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';
import { MandatStateEnum } from '@features/mandats/domain/entities/mandat.interface';
import { MandatDetailsToArchivateMapper } from '@features/mandats/domain/mappers/mandat-details-to-archivate-mapper';

import { ArchivateMandat } from '@features/mandats/domain/redux/actions/archived-mandats.actions';
import {
  ActivateMandat,
  SwapToPm,
  SwapToProcedures,
  SwapToProspection,
} from '@features/mandats/domain/redux/actions/mandats.actions';
import {
  AddMandatToVisitForm,
  RemoveMandatFromVisitForm,
} from '@features/visit-form/domain/redux/actions/visit-form.actions';
import { VisitFormSelectors } from '@features/visit-form/domain/redux/selectors/visit-form.selectors';
import { SweetAlertService } from '@libs/sweet-alert/sweet-alert.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { environment } from '@src/environments/environment';
import { delayWhen, Observable, of, retryWhen, take, tap, timer } from 'rxjs';

@Component({
  selector: 'app-mandat-detail-mobile-header',
  templateUrl: './mandat-detail-mobile-header.component.html',
  styleUrl: './mandat-detail-mobile-header.component.scss',
})
@UntilDestroy()
export class MandatDetailMobileHeaderComponent implements OnInit {
  @Input() salePointName!: string;
  @Input() idActivity!: number;
  @Input() mandat!: MandatDetailInterface;
  @Input() mandatState!: MandatStateEnum;
  @Select(AuthenticationSelectors.userId) userId$!: Observable<number>;
  userId!: number;
  mandatIds: number[] = [];

  loadingForMandatElecs = false;
  shouldShowSignatureButton = false;
  disableBtnSignature = false;
  protected readonly MandatStateEnum = MandatStateEnum;

  constructor(
    private location: Location,
    private store: Store,
    public dialog: MatDialog,
    private router: Router,
    public mandatService: MandatsApiService,
    public sweetAlertService: SweetAlertService
  ) {}

  ngOnInit() {
    this.store
      .select(VisitFormSelectors.mandatsIds)
      .pipe(untilDestroyed(this))
      .subscribe(ids => {
        this.mandatIds = ids;
      });
    this.userId$.pipe(untilDestroyed(this)).subscribe(id => {
      this.userId = id;
    });
  }

  goBack(): void {
    this.router.navigateByUrl('/mandats');
  }

  addMandatToListVisitForm(mandat: MandatDetailInterface) {
    if (!this.mandatIds.includes(mandat.mandat.id)) {
      this.store.dispatch(new AddMandatToVisitForm(mandat));
    } else {
      this.store.dispatch(new RemoveMandatFromVisitForm(mandat.mandat.id));
    }
  }

  activateMandat(): void {
    const dialogRef = this.dialog.open(ActivateMandatPopUpComponent, {
      width: '250px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const mandatToActivate = {
          id: this.mandat.mandat.id,
          date_debut: result.date_debut,
          duree_en_mois: result.duree_en_mois,
          numero_registre_mandats: result.numero_registre,
        };
        this.store.dispatch(new ActivateMandat(mandatToActivate));
      }
    });
  }

  archivateMandat() {
    const mandat = MandatDetailsToArchivateMapper(this.mandat);
    this.store.dispatch(new ArchivateMandat(mandat));
  }

  createPm() {
    const id = this.mandat.mandat.id;
    this.store.dispatch(new SwapToPm(id));
  }

  swapToProspection() {
    const id = this.mandat.mandat.id;
    this.store.dispatch(new SwapToProspection(id));
  }

  swapToProcedure() {
    const id = this.mandat.mandat.id;
    this.store.dispatch(new SwapToProcedures(id));
  }

  onPreviewSignatureElectronique() {
    const url = `${environment.apiUrl}/pdf/signature_electronique_preview?id=${this.mandat.mandat.id}&type=${this.mandat.mandat.id_forme_juridique}&id_cabinet=${this.mandat.mandat.id_cabinet}`;
    window.open(url, '_blank');
    this.loadingForMandatElecs = true;

    this.checkFileExist()
      .pipe(
        tap(response => {
          console.log(response);
          if (response.statut === false) {
            console.log('Retrying...');
          } else {
            this.loadingForMandatElecs = false;
            this.shouldShowSignatureButton = true;
          }
        }),
        retryWhen(errors =>
          errors.pipe(
            delayWhen(() => timer(3000)),
            take(3)
          )
        )
      )
      .subscribe();
  }

  checkFileExist() {
    try {
      let filename = '';
      if (this.mandat.mandat.id_forme_juridique === 1) {
        filename = 'FDC';
      } else if (this.mandat.mandat.id_forme_juridique === 2) {
        filename = 'DAB';
      } else if (this.mandat.mandat.id_forme_juridique === 3) {
        filename = 'LOC';
      } else if (this.mandat.mandat.id_forme_juridique === 4) {
        filename = 'BI';
      }
      return this.mandatService.checkMandatPdf(
        `${this.mandat.mandat.id_cabinet}${filename}${this.mandat.mandat.id}`
      );
    } catch (e) {
      console.error('An error occurred in checkFileExist function:', e);
      return of(null);
    }
  }

  onSendSignatureElectronique() {
    let filename = '';
    if (this.mandat.mandat.id_forme_juridique === 1) {
      filename = 'FDC';
    } else if (this.mandat.mandat.id_forme_juridique === 2) {
      filename = 'DAB';
    } else if (this.mandat.mandat.id_forme_juridique === 3) {
      filename = 'LOC';
    } else if (this.mandat.mandat.id_forme_juridique === 4) {
      filename = 'BI';
    }
    this.disableBtnSignature = true;
    this.mandatService
      .sendToYousign(
        this.mandat.mandat,
        `${this.mandat.mandat.id_cabinet}${filename}${this.mandat.mandat.id}`
      )
      .pipe(take(1))
      .subscribe(
        () => {
          this.sweetAlertService.showToastSuccess(
            'Le mandat a été envoyé à la signature électronique'
          );
          this.mandatService
            .deleteMandatElecPdf(
              `${this.mandat.mandat.id_cabinet}${filename}${this.mandat.mandat.id}`
            )
            .subscribe(() => {});
        },
        error => {
          console.log(error);
          this.sweetAlertService.showToastError(
            "Une erreur est survenue lors de l'envoi du mandat à la signature électronique"
          );
        }
      );
  }
}
