@if (!fullScreenMenuOpen) {
  <div class="bottom-nav-closed" [@moveInAndOut]>
    <div class="nav-item">
      <img
        (click)="toggleFullScreenMenu()"
        (keyup.enter)="toggleFullScreenMenu()"
        tabindex="0"
        alt="Ouvrir/Fermer le menu"
        height="24"
        ngSrc="./assets/icons/arrow.svg"
        style="rotate: 180deg"
        width="24" />
      <p class="icon-label">Menu</p>
    </div>

    <ng-container *ngIf="isMandatUpdateRoute(); else otherRoutes">
      <ng-container *ngTemplateOutlet="updateMandatIcons"></ng-container>
    </ng-container>

    <ng-template #otherRoutes>
      <ng-container [ngSwitch]="actualUrl">
        <ng-container *ngSwitchCase="'/mandats'">
          <ng-container *ngTemplateOutlet="mandatsIcons"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'/mandats/prospections'">
          <ng-container *ngTemplateOutlet="prospectionsIcons"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'/buyers/false'">
          <ng-container *ngTemplateOutlet="acquereursIcons"></ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <ng-container *ngTemplateOutlet="defaultIcons"></ng-container>
        </ng-container>
      </ng-container>
    </ng-template>
  </div>

  <ng-template #defaultIcons>
    <app-default-bottom-menu></app-default-bottom-menu>
  </ng-template>
  <ng-template #mandatsIcons>
    <app-mandat-list-bottom-menu
      [navigationGoback]="navigationGoback"
      [numberOfSelectedMandatsInVisitForm]="
        this.numberOfSelectedMandatsInVisitForm
      "></app-mandat-list-bottom-menu>
  </ng-template>
  <ng-template #prospectionsIcons>
    <app-prospection-bottom-menu
      [navigationGoback]="navigationGoback"></app-prospection-bottom-menu>
  </ng-template>
  <ng-template #acquereursIcons>
    <app-acquereur-bottom-menu
      [navigationGoback]="navigationGoback"></app-acquereur-bottom-menu>
  </ng-template>

  <ng-template #updateMandatIcons>
    <app-mandat-update-bottom-menu
      [navigationGoback]="navigationGoback"></app-mandat-update-bottom-menu>
  </ng-template>
}
<app-opened-menu
  (closeMenu)="toggleFullScreenMenu()"
  *ngIf="fullScreenMenuOpen">
</app-opened-menu>
