import { createSelector } from '@ngxs/store';
import {
  VisitesState,
  VisitesStateModel,
} from '@features/visites/domain/redux/state/visites.state';

export class VisitesSelectors {
  static readonly findVisiteByIdMandat = (idMandat: number) => {
    return createSelector([VisitesState], (state: VisitesStateModel) => {
      if (!state.visites) {
        return null;
      }

      return state.visites.filter(visite => {
        const hasMandat = visite.mandats.includes(idMandat);
        return hasMandat;
      });
    });
  };

  static readonly findVisiteByIdAcquereur = (idAcquereur: number) => {
    return createSelector([VisitesState], (state: VisitesStateModel) => {
      if (!state.visites) {
        return null;
      }

      return state.visites.filter(visite => {
        const hasAcquereur = visite.acquereurs.includes(idAcquereur);
        return hasAcquereur;
      });
    });
  };
}
