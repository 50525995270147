import { Component, Input, ViewChild } from '@angular/core';
import { ArchivateMandatInterface } from '@features/mandats/domain/entities/archivate-mandat.interface';
import { MatTableDataSource } from '@angular/material/table';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-archived-mandats-table',
  templateUrl: './archived-mandats-table.component.html',
  styleUrl: './archived-mandats-table.component.scss',
})
export class ArchivedMandatsTableComponent {
  @ViewChild(CdkVirtualScrollViewport) viewport!: CdkVirtualScrollViewport;
  columnsToDisplay: string[] = [
    'date',
    'numero',
    'mandant',
    'activite',
    'prix',
    'enseigne',
    'adresse',
    'ville',
  ];
  tableDataSource = new MatTableDataSource<ArchivateMandatInterface>();
  private allData: ArchivateMandatInterface[] = [];

  @Input() set dataSource(data: ArchivateMandatInterface[]) {
    this.allData = data;
    this.tableDataSource.data = data.slice(0, 40);
  }

  onScroll() {
    const end = this.viewport.getRenderedRange().end;
    const total = this.viewport.getDataLength();
    if (end === total) {
      const newLength = this.tableDataSource.data.length + 40;
      const nextBatch = this.allData.slice(
        this.tableDataSource.data.length,
        newLength
      );
      this.tableDataSource.data = [...this.tableDataSource.data, ...nextBatch];
    }
  }
}
