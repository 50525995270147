<div class="title">
  <h1 class="under-lined-h1">Informations de l'agence</h1>
  <p>
    Ces informations seront utilisées pour la génération des documents
    administratifs.
  </p>
</div>

<form [formGroup]="cabinetForm">
  <div class="first-container">
    <div class="cabinet">
      <h2>Cabinet</h2>
      <div class="form-group">
        <label for="cabinet-name">Nom commercial</label>
        <app-input-slide
          inputType="text"
          formControlName="nom"
          id="cabinet-name"
          [forDesktop]="true"
          width="fit" />
      </div>
      <div class="form-group">
        <label for="cabinet-raison">Raison sociale</label>
        <app-input-slide
          inputType="text"
          formControlName="raison_sociale"
          id="cabinet-raison"
          [forDesktop]="true"
          width="fit" />
      </div>
      <div class="form-group">
        <label for="cabinet-forme">Forme juridique</label>
        <app-input-slide
          inputType="text"
          formControlName="forme_sociale"
          id="cabinet-forme"
          [forDesktop]="true"
          width="fit" />
      </div>
      <div class="form-group">
        <label for="cabinet-capital">Capital</label>
        <app-input-slide
          inputType="text"
          formControlName="capital"
          id="cabinet-capital"
          [forDesktop]="true"
          width="fit" />
      </div>
      <div class="form-group">
        <label for="cabinet-siret">SIREN</label>
        <app-input-slide
          inputType="text"
          formControlName="siret"
          id="cabinet-siret"
          [forDesktop]="true"
          width="fit" />
      </div>
      <div class="form-group">
        <label for="cabinet-adresse">Adresse</label>
        <app-input-slide
          inputType="text"
          formControlName="adresse"
          id="cabinet-adresse"
          [forDesktop]="true"
          width="fit" />
      </div>
      <div class="form-group">
        <label for="cabinet-codepostal">Code postal</label>
        <app-input-slide
          inputType="text"
          formControlName="codepostal"
          id="cabinet-codepostal"
          [forDesktop]="true"
          width="fit" />
      </div>
      <div class="form-group">
        <label for="cabinet-ville">Ville</label>
        <app-input-slide
          inputType="text"
          formControlName="ville"
          id="cabinet-ville"
          [forDesktop]="true"
          width="fit" />
      </div>
      <div class="form-group">
        <label for="cabinet-telephone">Téléphone</label>
        <app-input-slide
          inputType="text"
          formControlName="telephone"
          id="cabinet-telephone"
          [forDesktop]="true"
          width="fit" />
      </div>
      <div class="form-group">
        <label for="cabinet-fax">Fax</label>
        <app-input-slide
          inputType="text"
          formControlName="fax"
          id="cabinet-fax"
          [forDesktop]="true"
          width="fit" />
      </div>
      <div class="form-group">
        <label for="cabinet-email">Email général</label>
        <app-input-slide
          inputType="text"
          formControlName="email"
          id="cabinet-email"
          [forDesktop]="true"
          width="fit" />
      </div>
      <div class="form-group">
        <label for="cabinet-tva">N° TVA intracommunautaire</label>
        <app-input-slide
          inputType="text"
          formControlName="num_tva"
          id="cabinet-tva"
          [forDesktop]="true"
          width="fit" />
      </div>
      <div class="form-group">
        <label for="cabinet-iban">IBAN</label>
        <app-input-slide
          inputType="text"
          formControlName="iban"
          id="cabinet-iban"
          [forDesktop]="true"
          width="fit" />
      </div>
      <div class="form-group">
        <label for="cabinet-bic">BIC</label>
        <app-input-slide
          inputType="text"
          formControlName="bic"
          id="cabinet-bic"
          [forDesktop]="true"
          width="fit" />
      </div>
      <div class="form-group">
        <label for="cabinet-honoraires">Barèmes d&apos;honoraires</label>
        <app-input-slide
          inputType="text"
          formControlName="honoraires"
          id="cabinet-honoraires"
          [forDesktop]="true"
          width="fit" />
      </div>
    </div>
    <div class="second-container">
      <div class="garantie">
        <h2>Garantie financière</h2>
        <div class="form-group">
          <p>Prise de chèque à l&apos;offre</p>
          <div class="radio">
            <input
              type="radio"
              id="cheque-oui"
              value="1"
              formControlName="cheque_versement" />
            <label for="cheque-oui">Oui</label>
            <input
              type="radio"
              id="cheque-non"
              value="0"
              formControlName="cheque_versement" />
            <label for="cheque-non">Non</label>
          </div>
        </div>
        <div class="form-group">
          <label for="cabinet-sequestre">Banque sequestre</label>
          <app-input-slide
            inputType="text"
            formControlName="banque_sequestre"
            id="cabinet-sequestre"
            [forDesktop]="true"
            width="fit" />
        </div>
        <div class="form-group">
          <label for="cabinet-caisse">Caisse de garantie</label>
          <app-input-slide
            inputType="text"
            formControlName="caisse_de_garantie"
            id="cabinet-caisse"
            [forDesktop]="true"
            width="fit" />
        </div>
        <div class="form-group">
          <label for="cabinet-caisse-adresse">Adresse caisse de garantie</label>
          <app-input-slide
            inputType="text"
            formControlName="caisse_de_garantie_adresse"
            id="cabinet-caisse-adresse"
            [forDesktop]="true"
            width="fit" />
        </div>
        <div class="form-group">
          <label for="cabinet-caisse-montant">Montant garantie</label>
          <app-input-slide
            inputType="text"
            formControlName="caisse_de_garantie_montant"
            id="cabinet-caisse-montant"
            [forDesktop]="true"
            width="fit" />
        </div>
      </div>
      <div class="signataire">
        <h2>Signataire</h2>
        <div class="form-group">
          <p>Civilité</p>
          <div class="radio">
            <input
              type="radio"
              id="signataire-monsieur"
              [value]="civility.monsieur"
              formControlName="civilite" />
            <label for="cheque-oui">Monsieur</label>

            <input
              type="radio"
              id="signataire-madame"
              [value]="civility.madame"
              formControlName="civilite" />
            <label for="cheque-non">Madame</label>
          </div>
        </div>
        <div class="form-group">
          <label for="cabinet-nom-responsable">Nom du responsable</label>
          <app-input-slide
            inputType="text"
            formControlName="nom_responsable_cabinet"
            id="cabinet-nom-responsable"
            [forDesktop]="true"
            width="fit" />
        </div>
        <div class="form-group">
          <label for="cabinet-prenom-responsable">Prénom du responsable</label>
          <app-input-slide
            inputType="text"
            formControlName="prenom_responsable_cabinet"
            id="cabinet-prenom-responsable"
            [forDesktop]="true"
            width="fit" />
        </div>
        <div class="form-group">
          <label for="cabinet-mobile">Mobile de signature</label>
          <app-input-slide
            inputType="text"
            formControlName="mobile"
            id="cabinet-mobile"
            [forDesktop]="true"
            width="fit" />
        </div>
        <div class="form-group">
          <label for="cabinet-email">Email de signature</label>
          <app-input-slide
            inputType="text"
            formControlName="email_signature_electronique"
            id="cabinet-email"
            [forDesktop]="true"
            width="fit" />
        </div>
      </div>
    </div>
  </div>
  <div class="carte">
    <h2>Carte professionnelle</h2>
    <div class="form-group">
      <label for="cabinet-carte">Numéro</label>
      <app-input-slide
        inputType="text"
        formControlName="numero_carte_pro"
        id="cabinet-carte"
        [forDesktop]="true"
        width="fit" />
    </div>
    <div class="form-group">
      <label for="carte_pro_delivree_par">Délivrée par</label>
      <app-input-slide
        inputType="text"
        formControlName="carte_pro_delivree_par"
        id="carte_pro_delivree_par"
        [forDesktop]="true"
        width="fit" />
    </div>
    <div class="form-group">
      <label for="carte_pro_delivree_le">Délivrée le</label>
      <app-input-slide
        inputType="text"
        formControlName="carte_pro_delivree_le"
        id="carte_pro_delivree_le"
        [forDesktop]="true"
        width="fit" />
    </div>
    <div class="form-group">
      <label for="cabinet-mediateur">Médiateur</label>
      <app-input-slide
        inputType="text"
        formControlName="mediateur"
        id="cabinet-mediateur"
        [forDesktop]="true"
        width="fit" />
    </div>
    <div class="form-group">
      <label for="cabinet-nom_rcp">Organisme RCP</label>
      <app-input-slide
        inputType="text"
        formControlName="nom_rcp"
        id="cabinet-nom_rcp"
        [forDesktop]="true"
        width="fit" />
    </div>
    <div class="form-group">
      <label for="cabinet-adresse_rcp">Adresse RCP</label>
      <app-input-slide
        inputType="text"
        formControlName="adresse_rcp"
        id="cabinet-adresse_rcp"
        [forDesktop]="true"
        width="fit" />
    </div>
  </div>
  <div class="presentation">
    <h2>Présentation</h2>
    <div class="form-group">
      <label for="cabinet-web">Site web</label>
      <app-input-slide
        inputType="text"
        formControlName="siteweb"
        id="cabinet-web"
        [forDesktop]="true"
        width="fit" />
    </div>
    <div>
      <textarea formControlName="presentation"></textarea>
    </div>
  </div>
  <div class="btn">
    <app-btn text="Annuler" color="cancel" (btnClick)="cancelUpdate()" />
    <app-btn text="Enregistrer" (btnClick)="udpateAgency()" />
  </div>
</form>
