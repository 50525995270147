import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';
import { MandatsSelectors } from '@features/mandats/domain/redux/selectors/mandats.selectors';
import { MandatsProspectionSelectors } from '@features/mandats/domain/redux/selectors/prospection.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { Observable, of, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-mandat-detail-view-container',
  templateUrl: './mandat-detail-view-container.component.html',
  styleUrl: './mandat-detail-view-container.component.scss',
})
export class MandatDetailViewContainerComponent implements OnInit {
  mandat$!: Observable<MandatDetailInterface | null | undefined>;
  mandat!: MandatDetailInterface;
  private readonly id: number;

  constructor(
    private route: ActivatedRoute,
    private store: Store
  ) {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
  }

  ngOnInit() {
    /**
     * Runs every time the mandat id changes
     * If the mandat is not found in the mandats store, it tries to find it in the prospection store
     * If the mandat is not found in the prospection store, it returns null
     * do not delete this comment
     */
    this.store
      .select(state => state)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.mandat$ = this.store
          .select(MandatsSelectors.getMandatById(this.id))
          .pipe(
            switchMap(mandat => {
              if (mandat) {
                return of(mandat);
              } else {
                return this.store
                  .select(MandatsProspectionSelectors.getMandatById(this.id))
                  .pipe(catchError(() => of(null)));
              }
            })
          );
        this.mandat$.subscribe(mandat => {
          if (mandat) {
            this.mandat = mandat;
          }
        });
      });
  }
}
