<mat-card class="entreprise-card">
  <mat-card-header>
    <mat-card-title>{{ raisonSociale }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="info-container">
      <p>
        <mat-icon>confirmation_number</mat-icon>
        {{ sirenFormate }}
      </p>
      <p>
        <mat-icon>location_on</mat-icon>
        {{ adresseLigne1 }} {{ adresseLigne2 }} {{ ville }} {{ codePostal }}
      </p>
      <p>
        <mat-icon>work</mat-icon>
        {{ libelleCodeNaf }}
      </p>
    </div>
  </mat-card-content>
  <mat-card-footer>
    <button
      (click)="noCompanyChange()"
      (keyup.enter)="noCompanyChange()"
      class="first-part-in-footer"
      type="button">
      <p>Personne physique uniquement</p>
    </button>
    <button
      (click)="companyChange()"
      (keyup.enter)="companyChange()"
      class="second-part-in-footer"
      type="button">
      <p>Changer de personne morale</p>
    </button>
  </mat-card-footer>
</mat-card>
