<div class="agencies-header">
  <p>Barème honoraires de l'agence</p>
</div>

<form [formGroup]="cabinetFeesForm">
  <!-- Fonds de commerce -->
  <div class="card">
    <h2>Fonds de commerce</h2>
    <div class="form-line">
      <div class="first-container">
        <div class="form-group">
          <label for="tranche1fdc">De</label>
          <app-input
            [type]="'number'"
            formControlName="tranche1fdc"
            id="tranche1fdc"
            width="custom" />
          <div class="symbol">€</div>
        </div>
        <div class="form-group">
          <label for="tranche2fdc">A</label>
          <app-input
            [type]="'number'"
            formControlName="tranche2fdc"
            id="tranche2fdc"
            width="custom" />
          <div class="symbol">€</div>
        </div>
      </div>
      <div class="second-container">
        <div class="form-group">
          <label for="taux1fdc">Taux</label>
          <app-input
            [type]="'number'"
            formControlName="taux1fdc"
            id="taux1fdc"
            width="custom" />
          <div class="symbol">%HT</div>
        </div>
        <div class="form-group">
          <label for="fdcttc1">Soit</label>
          <app-input
            [type]="'number'"
            formControlName="fdcttc1"
            id="fdcttc1"
            width="custom" />
          <div class="symbol">%TTC</div>
        </div>
      </div>
    </div>
    <div class="form-line">
      <div class="first-container">
        <div class="form-group">
          <label for="tranche3fdc">De</label>
          <app-input
            [type]="'number'"
            formControlName="tranche3fdc"
            id="tranche3fdc"
            width="custom" />
          <div class="symbol">€</div>
        </div>
        <div class="form-group">
          <label for="tranche4fdc">A</label>
          <app-input
            [type]="'number'"
            formControlName="tranche4fdc"
            id="tranche4fdc"
            width="custom" />
          <div class="symbol">€</div>
        </div>
      </div>
      <div class="second-container">
        <div class="form-group">
          <label for="taux2fdc">Taux</label>
          <app-input
            [type]="'number'"
            formControlName="taux2fdc"
            id="taux2fdc"
            width="custom" />
          <div class="symbol">%HT</div>
        </div>
        <div class="form-group">
          <label for="fdcttc2">Soit</label>
          <app-input
            [type]="'number'"
            formControlName="fdcttc2"
            id="fdcttc2"
            width="custom" />
          <div class="symbol">%TTC</div>
        </div>
      </div>
    </div>
    <div class="form-line">
      <div class="first-container">
        <div class="form-group">
          <label for="tranche5fdc">De</label>
          <app-input
            [type]="'number'"
            formControlName="tranche5fdc"
            id="tranche5fdc"
            width="custom" />
          <div class="symbol">€</div>
        </div>
      </div>
      <div class="second-container">
        <div class="form-group">
          <label for="taux3fdc">Taux</label>
          <app-input
            [type]="'number'"
            formControlName="taux3fdc"
            id="taux3fdc"
            width="custom" />
          <div class="symbol">%HT</div>
        </div>
        <div class="form-group">
          <label for="fdcttc3">Soit</label>
          <app-input
            [type]="'number'"
            formControlName="fdcttc3"
            id="fdcttc3"
            width="custom" />
          <div class="symbol">%TTC</div>
        </div>
      </div>
    </div>
  </div>

  <!-- Droit au bail -->
  <div class="card">
    <h2>Droit au bail</h2>
    <div class="form-line">
      <div class="first-container">
        <div class="form-group">
          <label for="tranche1dab">De</label>
          <app-input
            [type]="'number'"
            formControlName="tranche1dab"
            id="tranche1dab"
            width="custom" />
          <div class="symbol">€</div>
        </div>
        <div class="form-group">
          <label for="tranche2dab">A</label>
          <app-input
            [type]="'number'"
            formControlName="tranche2dab"
            id="tranche2dab"
            width="custom" />
          <div class="symbol">€</div>
        </div>
      </div>
      <div class="second-container">
        <div class="form-group">
          <label for="taux1dab">Taux</label>
          <app-input
            [type]="'number'"
            formControlName="taux1dab"
            id="taux1dab"
            width="custom" />
          <div class="symbol">%HT</div>
        </div>
        <div class="form-group">
          <label for="dabttc1">Soit</label>
          <app-input
            [type]="'number'"
            formControlName="dabttc1"
            id="dabttc1"
            width="custom" />
          <div class="symbol">%TTC</div>
        </div>
      </div>
    </div>
    <div class="form-line">
      <div class="first-container">
        <div class="form-group">
          <label for="tranche3dab">De</label>
          <app-input
            [type]="'number'"
            formControlName="tranche3dab"
            id="tranche3dab"
            width="custom" />
          <div class="symbol">€</div>
        </div>
        <div class="form-group">
          <label for="tranche4dab">A</label>
          <app-input
            [type]="'number'"
            formControlName="tranche4dab"
            id="tranche4dab"
            width="custom" />
          <div class="symbol">€</div>
        </div>
      </div>
      <div class="second-container">
        <div class="form-group">
          <label for="taux2dab">Taux</label>
          <app-input
            [type]="'number'"
            formControlName="taux2dab"
            id="taux2dab"
            width="custom" />
          <div class="symbol">%HT</div>
        </div>
        <div class="form-group">
          <label for="dabttc2">Soit</label>
          <app-input
            [type]="'number'"
            formControlName="dabttc2"
            id="dabttc2"
            width="custom" />
          <div class="symbol">%TTC</div>
        </div>
      </div>
    </div>
    <div class="form-line">
      <div class="first-container">
        <div class="form-group">
          <label for="tranche5dab">De</label>
          <app-input
            [type]="'number'"
            formControlName="tranche5dab"
            id="tranche5dab"
            width="custom" />
          <div class="symbol">€</div>
        </div>
      </div>
      <div class="second-container">
        <div class="form-group">
          <label for="taux3dab">Taux</label>
          <app-input
            [type]="'number'"
            formControlName="taux3dab"
            id="taux3dab"
            width="custom" />
          <div class="symbol">%HT</div>
        </div>
        <div class="form-group">
          <label for="dabttc3">Soit</label>
          <app-input
            [type]="'number'"
            formControlName="dabttc3"
            id="dabttc3"
            width="custom" />
          <div class="symbol">%TTC</div>
        </div>
      </div>
    </div>
  </div>

  <!-- Location -->
  <div class="card">
    <h2>Location</h2>
    <div class="form-line">
      <div class="first-container"></div>
      <div class="second-container">
        <div class="form-group">
          <label for="taux_loc">Taux</label>
          <app-input
            [type]="'number'"
            formControlName="taux_loc"
            id="taux_loc"
            width="custom" />
          <div class="symbol">%HT</div>
        </div>
        <div class="form-group">
          <label for="locttc">Soit</label>
          <app-input
            [type]="'number'"
            formControlName="locttc"
            id="locttc"
            width="custom" />
          <div class="symbol">%TTC</div>
        </div>
        <app-input
          [type]="'text'"
          formControlName="descriptif_loc"
          id="descriptif_loc"
          width="custom" />
      </div>
    </div>
  </div>

  <!-- Vente murs -->
  <div class="card">
    <h2>Vente murs</h2>
    <div class="form-line">
      <div class="first-container">
        <div class="form-group">
          <label for="tranche1murs">De</label>
          <app-input
            [type]="'number'"
            formControlName="tranche1murs"
            id="tranche1murs"
            width="custom" />
          <div class="symbol">€</div>
        </div>
        <div class="form-group">
          <label for="tranche2murs">A</label>
          <app-input
            [type]="'number'"
            formControlName="tranche2murs"
            id="tranche2murs"
            width="custom" />
          <div class="symbol">€</div>
        </div>
      </div>
      <div class="second-container">
        <div class="form-group">
          <label for="taux1murs">Taux</label>
          <app-input
            [type]="'number'"
            formControlName="taux1murs"
            id="taux1murs"
            width="custom" />
          <div class="symbol">%HT</div>
        </div>
        <div class="form-group">
          <label for="mursttc1">Soit</label>
          <app-input
            [type]="'number'"
            formControlName="mursttc1"
            id="mursttc1"
            width="custom" />
          <div class="symbol">%TTC</div>
        </div>
      </div>
    </div>
    <div class="form-line">
      <div class="first-container">
        <div class="form-group">
          <label for="tranche3murs">De</label>
          <app-input
            [type]="'number'"
            formControlName="tranche3murs"
            id="tranche3murs"
            width="custom" />
          <div class="symbol">€</div>
        </div>
        <div class="form-group">
          <label for="tranche4murs">A</label>
          <app-input
            [type]="'number'"
            formControlName="tranche4murs"
            id="tranche4murs"
            width="custom" />
          <div class="symbol">€</div>
        </div>
      </div>
      <div class="second-container">
        <div class="form-group">
          <label for="taux2murs">Taux</label>
          <app-input
            [type]="'number'"
            formControlName="taux2murs"
            id="taux2murs"
            width="custom" />
          <div class="symbol">%HT</div>
        </div>
        <div class="form-group">
          <label for="mursttc2">Soit</label>
          <app-input
            [type]="'number'"
            formControlName="mursttc2"
            id="mursttc2"
            width="custom" />
          <div class="symbol">%TTC</div>
        </div>
      </div>
    </div>
    <div class="form-line">
      <div class="first-container">
        <div class="form-group">
          <label for="tranche5murs">De</label>
          <app-input
            [type]="'number'"
            formControlName="tranche5murs"
            id="tranche5murs"
            width="custom" />
          <div class="symbol">€</div>
        </div>
      </div>
      <div class="second-container">
        <div class="form-group">
          <label for="taux3murs">Taux</label>
          <app-input
            [type]="'number'"
            formControlName="taux3murs"
            id="taux3murs"
            width="custom" />
          <div class="symbol">%HT</div>
        </div>
        <div class="form-group">
          <label for="mursttc3">Soit</label>
          <app-input
            [type]="'number'"
            formControlName="mursttc3"
            id="mursttc3"
            width="custom" />
          <div class="symbol">%TTC</div>
        </div>
      </div>
    </div>
  </div>

  <!-- Minimum de facturation -->
  <div class="card">
    <h2>Minimum de facturation</h2>
    <div class="form-line">
      <div class="first-container">
        <div class="form-group">
          <label for="facturationmin">De</label>
          <app-input
            [type]="'number'"
            formControlName="facturationmin"
            id="facturationmin"
            width="custom" />
          <div class="symbol">€</div>
        </div>
        <div class="form-group">
          <label for="facturationminttc">A</label>
          <app-input
            [type]="'number'"
            formControlName="facturationminttc"
            id="facturationminttc"
            width="custom" />
          <div class="symbol">€</div>
        </div>
      </div>
    </div>
  </div>

  <div class="btn">
    <app-btn
      (btnClick)="updateFees()"
      [disabled]="cabinetFeesForm.invalid"
      [text]="'Enregistrer'">
    </app-btn>
  </div>
</form>
