import { FormSectionBase } from '@src/shared/forms/form.class';
import { FormControl, FormGroup } from '@angular/forms';

export class CabinetForm extends FormSectionBase {
  private idCabinet: string;

  constructor(idCabinet: string) {
    super();
    this.idCabinet = idCabinet;
  }

  createFormGroup() {
    return new FormGroup({
      id: new FormControl(''),
      id_cabinet: new FormControl(this.idCabinet),
      nom: new FormControl(''),
      civilite: new FormControl(''),
      nom_responsable_cabinet: new FormControl(''),
      prenom_responsable_cabinet: new FormControl(''),
      raison_sociale: new FormControl(''),
      forme_sociale: new FormControl(''),
      adresse: new FormControl(''),
      codepostal: new FormControl(''),
      ville: new FormControl(''),
      telephone: new FormControl(''),
      mobile: new FormControl(''),
      fax: new FormControl(''),
      email: new FormControl(''),
      email_signature_electronique: new FormControl(''),
      numero_carte_pro: new FormControl(''),
      carte_pro_delivree_par: new FormControl(''),
      carte_pro_delivree_le: new FormControl(''),
      caisse_de_garantie: new FormControl(''),
      caisse_de_garantie_adresse: new FormControl(''),
      caisse_de_garantie_montant: new FormControl(''),
      cheque_versement: new FormControl(true),
      banque_sequestre: new FormControl(''),
      nom_rcp: new FormControl(''),
      adresse_rcp: new FormControl(''),
      mediateur: new FormControl(''),
      siret: new FormControl(''),
      capital: new FormControl(''),
      num_tva: new FormControl(''),
      iban: new FormControl(''),
      bic: new FormControl(''),
      url_honoraires: new FormControl(''),
      siteweb: new FormControl(''),
      presentation: new FormControl(''),
      honoraires: new FormControl(''),
    });
  }
}
