import { Component, EventEmitter, Output } from '@angular/core';
import { ArchivedMandatFilterParams } from '@features/mandats/domain/entities/mandat-filter-params.interface';
import { SelectItem } from '@libs/select/select-options';
import { ActiviteInterface } from '@src/shared/activites/interfaces/activities.interface';
import { activitesObject } from '@src/shared/activites/objects/activities.object';

@Component({
  selector: 'app-archived-mandats-filter',
  templateUrl: './archived-mandats-filter.component.html',
  styleUrl: './archived-mandats-filter.component.scss',
})
export class ArchivedMandatsFilterComponent {
  @Output() filterTrigger: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() filterChange: EventEmitter<ArchivedMandatFilterParams> =
    new EventEmitter<ArchivedMandatFilterParams>();
  filters: ArchivedMandatFilterParams = {
    archivedMandats: [],
    activity: '',
    address: '',
    locality: '',
    search: '',
  };
  filterTriggered = true;
  activitiesItems: ActiviteInterface[] = [
    { id: 0, name: '-- Tous --' },
    ...activitesObject,
  ];
  displayedValueOfActivity = 'Tous';

  trigerFilter() {
    this.filterTriggered = !this.filterTriggered;
    this.filterTrigger.emit(this.filterTriggered);
  }

  onSearchChange(value: string) {
    this.filters.search = value;
    this.emitChanges();
  }

  /**
   * Handles the change of activity in the select item.
   *
   * A `displayedValueOfActivity` is introduced because the select element makes use of the item's id,
   * while the archived mandat interface requires searching by the item's name.
   *
   * @param {SelectItem} value - The selected item from the select dropdown
   */
  onActivityChange(value: SelectItem) {
    this.displayedValueOfActivity = value.id;
    this.filters.activity = value.name;
    this.emitChanges();
  }

  onAddressChange(value: string) {
    this.filters = { ...this.filters, address: value };
    this.emitChanges();
  }

  onLocalityChange(value: string) {
    this.filters = { ...this.filters, locality: value };
    this.emitChanges();
  }

  resetFilters() {
    this.filters = {
      archivedMandats: [],
      activity: '',
      address: '',
      locality: '',
      search: '',
    };
    this.emitChanges();
  }

  private emitChanges() {
    this.filterChange.emit(this.filters);
  }
}
