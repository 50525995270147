import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TransfertService {
  apiPath = environment.apiUrl;

  constructor(private http: HttpClient) {}

  updateMandats(body: {
    id_consultant_expediteur: string;
    id_consultant_destinataire_mandat: string;
  }) {
    return this.http.post(`${this.apiPath}/transferts/mandats`, body);
  }

  updateBuyers(body: {
    id_consultant_expediteur: string;
    id_consultant_destinataire_buyer: string;
  }) {
    return this.http.post(`${this.apiPath}/transferts/buyers`, body);
  }

  deleteConsultant(id: number) {
    return this.http.delete(`${this.apiPath}/users/${id}`);
  }
}
