import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ArchivateMandatInterface } from '@features/mandats/domain/entities/archivate-mandat.interface';
import { ArchivedMandatsSelectors } from '@features/mandats/domain/redux/selectors/archived-mandats.selectors';
import { LoadSilenceArchivedMandats } from '@features/mandats/domain/redux/actions/archived-mandats.actions';
import { ArchivedMandatFilterParams } from '@features/mandats/domain/entities/mandat-filter-params.interface';
import { ArchivedMandatsFilterClass } from '@features/mandats/domain/classes/archived-mandats-filter.class';

@UntilDestroy()
@Component({
  selector: 'app-archived-mandats.container',
  templateUrl: './archived-mandats.container.component.html',
  styleUrl: './archived-mandats.container.component.scss',
})
export class ArchivedMandatsContainerComponent implements OnInit {
  archivedMandats$!: Observable<ArchivateMandatInterface[] | undefined>;
  archivedMandats: ArchivateMandatInterface[] = [];
  filteredMandats: ArchivateMandatInterface[] = [];
  filterTriggered = true;
  filterParams: ArchivedMandatFilterParams = {
    archivedMandats: [],
    activity: '',
    address: '',
    locality: '',
    search: '',
  };

  constructor(private store: Store) {
    this.archivedMandats$ = this.store.select(
      ArchivedMandatsSelectors.archivedMandats
    );
  }

  ngOnInit() {
    this.fetchArchivedMandats();
  }

  filterTrigger($event: boolean) {
    this.filterTriggered = $event;
  }

  filterMandats(filters: ArchivedMandatFilterParams) {
    this.filterParams = {
      ...this.filterParams,
      activity: filters.activity,
      address: filters.address,
      locality: filters.locality,
      search: filters.search,
    };
    this.filteredMandats = ArchivedMandatsFilterClass.filterMandats({
      ...this.filterParams,
      archivedMandats: this.archivedMandats,
    });
    this.sortMandatsByDate(this.filteredMandats);
  }

  private fetchArchivedMandats() {
    this.store.dispatch(new LoadSilenceArchivedMandats());
    this.archivedMandats$
      .pipe(untilDestroyed(this))
      .subscribe(archivedMandats => {
        this.archivedMandats = archivedMandats || [];
        this.filteredMandats = this.archivedMandats;
        this.sortMandatsByDate(this.filteredMandats);
      });
  }

  private sortMandatsByDate(
    mandats: ArchivateMandatInterface[]
  ): ArchivateMandatInterface[] {
    return mandats.sort((a, b) => {
      if (!a.date_archive?.date || !b.date_archive?.date) return 0;
      return a.date_archive.date > b.date_archive.date ? -1 : 1;
    });
  }
}
