import { Component, Input } from '@angular/core';
import { BodaccService } from '@features/bodacc/adapters/secondary/real/bodacc-api.service';
import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';

@Component({
  selector: 'app-mandat-detail-bodacc',
  templateUrl: './mandat-detail-bodacc.component.html',
  styleUrl: './mandat-detail-bodacc.component.scss',
})
export class MandatDetailBodaccComponent {
  @Input() mandat!: MandatDetailInterface;

  constructor(private readonly bodaccService: BodaccService) {}
}
