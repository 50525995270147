import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AddressComponentInterface } from '@libs/google-places-autocomplete/formatted-address.interface';
import { GooglePlacesAutocompleteComponent } from '@libs/google-places-autocomplete/google-places-autocomplete.component';
import { civiliteItems } from '@libs/select/items/civiliteItems';
import { ContactInterface } from '@features/contacts/domain/entities/contacts.interface';

@Component({
  selector: 'app-bailleur-contact',
  templateUrl: './bailleur-contact.component.html',
  styleUrl: './bailleur-contact.component.scss',
})
export class BailleurContactComponent implements OnInit {
  @Input({ required: true }) bailleurForm!: FormGroup;
  @ViewChild(GooglePlacesAutocompleteComponent)
  shouldDisplayForm = true;
  backupForm!: FormGroup;
  googlePlacesComponent!: GooglePlacesAutocompleteComponent;
  protected readonly civiliteItems = civiliteItems;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.defineLayout();
  }

  onChange($event: AddressComponentInterface) {
    this.bailleurForm.patchValue($event);
  }

  onContactSelected($event: ContactInterface) {
    this.bailleurForm.patchValue({
      id: $event.id,
      prenom: $event.prenom,
      id_cabinet: $event.id_cabinet,
      nom: $event.nom,
      civilite: $event.civilite,
      email: $event.email,
      telephone: $event.telephone,
      mobile: $event.mobile,
      fax: $event.fax,
      place_id: $event.place_id,
      street_number: $event.street_number,
      indice: $event.indice,
      formatted_address: $event.formatted_address,
      lat: $event.lat,
      lng: $event.lng,
      url: $event.url,
      adresse_de_recherche: $event.adresse_de_recherche,
      type_voie: $event.type_voie,
      route: $event.route,
      comp_adresse: $event.comp_adresse,
      administrative_area_level_1: $event.administrative_area_level_1,
      administrative_area_level_2: $event.administrative_area_level_2,
      isNew: $event.isNew,
      postal_code: $event.postal_code,
      locality: $event.locality,
      contactConnu: $event.contactConnu,
      created_at: $event.created_at,
      updated_at: $event.updated_at,
      deleted_at: $event.deleted_at,
    });
    this.googlePlacesComponent.setInputValue($event.formatted_address);
  }

  getControl(name: string): FormControl {
    return this.bailleurForm.get(name) as FormControl;
  }

  defineLayout() {
    if (this.bailleurForm.get('id')?.value) {
      this.shouldDisplayForm = false;
      this.backupForm = this.bailleurForm;
    }
  }

  editContact() {
    this.backupForm = this.bailleurForm;
    this.shouldDisplayForm = true;
  }

  changeContact() {
    this.shouldDisplayForm = true;
    const formValues = this.bailleurForm.getRawValue();
    this.backupForm = this.fb.group(formValues);
    this.bailleurForm.reset();
  }
}
