import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArchivateMandatInterface } from '@features/mandats/domain/entities/archivate-mandat.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { ArchivedMandatsSelectors } from '@features/mandats/domain/redux/selectors/archived-mandats.selectors';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-archived-mandats-detail',
  templateUrl: './archived-mandats-detail.component.html',
  styleUrl: './archived-mandats-detail.component.scss',
})
export class ArchivedMandatsDetailComponent implements OnInit {
  archivedMandat$!: Observable<ArchivateMandatInterface | undefined>;
  archivedMandat!: ArchivateMandatInterface;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store
  ) {}

  ngOnInit() {
    this.activatedRoute.params.pipe(untilDestroyed(this)).subscribe(params => {
      const id = +params['id'];
      this.archivedMandat$ = this.store.select(
        ArchivedMandatsSelectors.getArchivedMandatById(id)
      );
      console.log('this.archivedMandat$', this.archivedMandat$);
    });
    this.archivedMandat$
      .pipe(untilDestroyed(this))
      .subscribe(archivedMandat => {
        if (archivedMandat) {
          this.archivedMandat = archivedMandat;
          console.log('archivedMandat', archivedMandat);
        }
      });
  }
}
