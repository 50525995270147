@if (this.shouldDisplayForm) {
  <form [formGroup]="contactMandantForm" class="create-form">
    <div class="civilite">
      <label class="required" for="civilite">Civilité </label>

      <app-select
        [items]="civiliteItems"
        class="form-control"
        formControlName="civilite"
        id="civilite"
        size="small" />
    </div>
    <div class="form-group">
      <app-select-contact
        (optionSelectedEvent)="onContactSelected($event)"
        [required]="true"
        class="form-control"
        formControlName="nom"
        placeholder="Nom"
        width="fit"></app-select-contact>
    </div>

    <div class="form-group">
      <app-input-slide
        [inputControl]="getControl('prenom')"
        [required]="true"
        class="form-control"
        formControlName="prenom"
        id="prenom"
        inputType="text"
        placeholder="Prénom"
        width="fit" />
    </div>
    <div class="form-group">
      <app-input-slide
        [inputControl]="getControl('mobile')"
        [required]="true"
        class="form-control"
        formControlName="mobile"
        id="mobile"
        inputType="text"
        placeholder="Téléphone mobile"
        width="fit" />
    </div>
    <div class="form-group">
      <app-input-slide
        class="form-control"
        formControlName="telephone"
        id="fixe"
        inputType="text"
        placeholder="Téléphone fixe"
        width="fit" />
    </div>
    <div class="form-group">
      <app-input-slide
        [inputControl]="getControl('email')"
        [required]="true"
        class="form-control"
        formControlName="email"
        id="email"
        inputType="text"
        placeholder="Email"
        width="fit" />
    </div>

    <div class="form-group">
      <app-google-places-autocomplete
        (formattedAddress)="onChange($event)"
        [initialValue]="getControl('formatted_address').value"
        [required]="true"
        placeholder="Adresse" />
    </div>
    <app-btn
      marginAuto="auto"
      (btnClick)="backupContact()"
      size="large"
      text="Annuler"
      color="alerte"></app-btn>
  </form>
}

@if (!this.shouldDisplayForm) {
  <app-contact-card-edit-mandat
    [civilite]="contactMandantForm.get('civilite')?.value"
    [email]="contactMandantForm.get('email')?.value"
    [mobile]="contactMandantForm.get('mobile')?.value"
    [nom]="contactMandantForm.get('nom')?.value"
    [prenom]="contactMandantForm.get('prenom')?.value"
    (editContact)="editContact()"
    (switchContact)="changeContact()"></app-contact-card-edit-mandat>
}
