import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  RendererFactory2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';
import { MandatStateEnum } from '@features/mandats/domain/entities/mandat.interface';
import {
  SetSearchAddress,
  SetSearchCity,
} from '@features/mandats/domain/redux/actions/mandats.actions';
import {
  AddMandatToVisitForm,
  RemoveMandatFromVisitForm,
} from '@features/visit-form/domain/redux/actions/visit-form.actions';
import { VisitFormSelectors } from '@features/visit-form/domain/redux/selectors/visit-form.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';

interface VisibilityFlags {
  hideConsultant: boolean;
  hideVille: boolean;
  hideRent: boolean;
  hidePrice: boolean;
  hideMandant: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-mandat-active-table',
  templateUrl: './mandat-active-table.component.html',
  styleUrls: ['./mandat-active-table.component.scss'],
  animations: [
    trigger('swipeRight', [
      state(
        'default',
        style({
          transform: 'translateX(0)',
        })
      ),
      state(
        'swipedRight',
        style({
          transform: 'translateX(0)',
        })
      ),
      transition('default => swipedRight', [animate('1s')]),
      transition('swipedRight => default', [animate('1s')]),
    ]),
  ],
})
export class MandatActiveTableComponent
  implements OnChanges, AfterViewInit, OnInit
{
  @Input() mandats!: MandatDetailInterface[];
  @Input() mandatsToDisplay!: MandatDetailInterface[];
  @Input() mandatShouldBeDisplayed!: boolean;
  @Input() isProspection: boolean = false;
  @ViewChild('scrollableDiv') scrollableDiv!: ElementRef;
  scrollableDivChanged = false;
  startX!: number | null;
  startY!: number | null;
  currentTdIndex!: number;
  swipedRightIndexes: { [key: number]: boolean } = {};
  limite = 30;
  renderer: Renderer2;
  mandatIds: number[] = [];
  windowWidth: number = window.innerWidth;
  visibilityFlags: { [key: number]: VisibilityFlags } = {};
  sortedMandats: MandatDetailInterface[] = [];
  currentSortColumn: string = '';
  isAsc: boolean = true;
  protected readonly MandatStateEnum = MandatStateEnum;
  protected readonly VisitFormSelectors = VisitFormSelectors;
  protected readonly window = window;

  constructor(
    private rendererFactory: RendererFactory2,
    protected store: Store
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.windowWidth = window.innerWidth;
    this.initializeVisibilityFlags();
  }

  ngOnInit() {
    this.store
      .select(VisitFormSelectors.mandatsIds)
      .pipe(untilDestroyed(this))
      .subscribe(ids => {
        this.mandatIds = ids;
      });
    this.initializeVisibilityFlags();
    this.sortedMandats = [...this.mandatsToDisplay];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['mandatsToDisplay']) {
      this.limite = 30;
      this.scrollToTop(this.scrollableDiv);
      this.initializeVisibilityFlags();
      this.sortedMandats = [...this.mandatsToDisplay];
    }
  }

  ngAfterViewInit() {
    if (this.scrollableDivChanged) {
      this.scrollableDiv.nativeElement.scrollTop = 0;
      this.scrollableDivChanged = false;
    }
  }

  initializeVisibilityFlags() {
    this.visibilityFlags = this.mandatsToDisplay.reduce(
      (acc, _, index) => {
        acc[index] = {
          hideConsultant: this.shouldHideConsultantName(index),
          hideVille: this.shouldHideVille(index),
          hideRent: this.shouldHideRent(index),
          hidePrice: this.shouldHidePrice(index),
          hideMandant: this.shouldHideMandant(index),
        };
        return acc;
      },
      {} as { [key: number]: VisibilityFlags }
    );
  }

  onTouchStart(event: TouchEvent, index: number) {
    this.startX = event.touches[0].clientX;
    this.startY = event.touches[0].clientY;
    this.currentTdIndex = index;
  }

  onTouchMove(event: TouchEvent, index: number) {
    if (!this.startX || !this.startY) {
      return;
    }

    const moveX = event.changedTouches[0].clientX;
    const moveY = event.changedTouches[0].clientY;

    const diffX = Math.abs(moveX - this.startX);
    const diffY = Math.abs(moveY - this.startY);

    if (diffX > 10 && diffX > diffY) {
      this.resetAllSwipes();
      if (event.cancelable) {
        event.preventDefault();
      }

      if (this.currentTdIndex !== index) {
        this.resetSwipe();
      }

      const swipeDirection = moveX > this.startX ? 'right' : 'left';
      this.swipedRightIndexes[index] = swipeDirection === 'left';
      this.currentTdIndex = index;
      this.initializeVisibilityFlags();
    }
  }

  resetAllSwipes() {
    Object.keys(this.swipedRightIndexes).forEach(key => {
      this.swipedRightIndexes[parseInt(key)] = false;
    });
    this.initializeVisibilityFlags();
  }

  resetSwipe() {
    this.swipedRightIndexes[this.currentTdIndex] = false;
    this.initializeVisibilityFlags();
  }

  onTableScroll(e: Event) {
    const target = e.target as HTMLElement;
    const isBottom =
      target.offsetHeight + target.scrollTop >= target.scrollHeight * 0.8;

    if (isBottom) {
      this.limite += 20;
    }
  }

  scrollToTop(el?: ElementRef) {
    this.renderer.setProperty(el?.nativeElement, 'scrollTop', 0);
  }

  addMandatToListVisitForm(mandat: MandatDetailInterface) {
    if (!this.mandatIds.includes(mandat.mandat.id)) {
      this.store.dispatch(new AddMandatToVisitForm(mandat));
    } else {
      this.store.dispatch(new RemoveMandatFromVisitForm(mandat.mandat.id));
    }
  }

  addAddresToSearchTab(route: string) {
    this.store.dispatch(new SetSearchAddress(route));
  }

  addCityToSearchTab(city: string) {
    this.store.dispatch(new SetSearchCity(city));
  }

  shouldHidePrice(index: number): boolean {
    return this.swipedRightIndexes[index] && this.windowWidth < 400;
  }

  shouldHideRent(index: number): boolean {
    return (
      this.swipedRightIndexes[index] &&
      this.windowWidth >= 400 &&
      this.windowWidth < 850
    );
  }

  shouldHideVille(index: number): boolean {
    return (
      this.swipedRightIndexes[index] &&
      this.windowWidth >= 849 &&
      this.windowWidth < 1399
    );
  }

  shouldHideMandant(index: number): boolean {
    return (
      this.swipedRightIndexes[index] &&
      this.windowWidth >= 1400 &&
      this.windowWidth < 1560
    );
  }

  shouldHideConsultantName(index: number): boolean {
    return this.swipedRightIndexes[index] && this.windowWidth >= 1560;
  }

  sortData(column: string) {
    if (this.currentSortColumn === column) {
      // Toggle sort direction
      this.isAsc = !this.isAsc;
    } else {
      // Set new sort column and default to ascending
      this.currentSortColumn = column;
      this.isAsc = true;
    }

    this.sortedMandats.sort((a, b) => {
      let valueA: string | number;
      let valueB: string | number;

      switch (column) {
        case 'enseigne':
          valueA = a.salePoints?.enseigne?.toLowerCase() || '';
          valueB = b.salePoints?.enseigne?.toLowerCase() || '';
          break;
        case 'cotation':
          valueA = this.getCotationValue(a.cotation ?? '');
          valueB = this.getCotationValue(b.cotation ?? '');
          break;
        case 'prix':
          valueA = a.mandat.prix_de_presentation || 0;
          valueB = b.mandat.prix_de_presentation || 0;
          break;
        case 'chiffreAffaire':
          valueA = this.parseNumber(a.salePoints?.ca);
          valueB = this.parseNumber(b.salePoints?.ca);
          break;
        case 'surfaceCommerciale':
          valueA = a.salePoints?.surface_commerciale || 0;
          valueB = b.salePoints?.surface_commerciale || 0;
          break;
        case 'loyer':
          valueA = this.parseNumber(a.salePoints?.loyer_annuel);
          valueB = this.parseNumber(b.salePoints?.loyer_annuel);
          break;
        case 'numero':
          valueA = this.getMandatNumberValue(a);
          valueB = this.getMandatNumberValue(b);
          break;
        default:
          valueA = '';
          valueB = '';
          break;
      }

      return this.compareValues(valueA, valueB, this.isAsc);
    });
  }

  compareValues(
    a: string | number,
    b: string | number,
    isAsc: boolean
  ): number {
    if (typeof a === 'string' && typeof b === 'string') {
      const comparison = a.localeCompare(b);
      return isAsc ? comparison : -comparison;
    } else if (typeof a === 'number' && typeof b === 'number') {
      const comparison = a - b;
      return isAsc ? comparison : -comparison;
    } else {
      // Handle mixed types or unexpected cases
      return 0;
    }
  }

  getCotationValue(cotation: string | undefined): number {
    const cotationOrder = ['A+', 'A', 'B', 'C', 'D'];
    if (!cotation) {
      return cotationOrder.length; // Assign the lowest priority
    }
    const index = cotationOrder.indexOf(cotation);
    return index !== -1 ? index : cotationOrder.length;
  }

  getMandatNumberValue(mandat: MandatDetailInterface): number | string {
    // Replace 'mandatNumberProperty' with the actual property name for 'Numéro'
    const numero = mandat.mandat.numero_registre_mandats || '';

    // If 'numero' is numeric, parse it as a number for numerical sorting
    const parsedNumero = parseInt(numero, 10);
    if (!isNaN(parsedNumero)) {
      return parsedNumero;
    }

    // If 'numero' is not numeric, return as string for lexicographical sorting
    return numero;
  }

  parseNumber(value: string | number | undefined | null): number {
    if (typeof value === 'number') {
      return value;
    }
    if (typeof value === 'string') {
      // Remove any non-numeric characters except for decimal points and minus signs
      const numericString = value.replace(/[^0-9.-]+/g, '');
      const parsedValue = parseFloat(numericString);
      return isNaN(parsedValue) ? 0 : parsedValue;
    }
    return 0;
  }
}
