import { ArchivateMandatDto } from '@features/mandats/domain/entities/archivate-mandat.interface';
import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';
import { MandatStateEnum } from '@features/mandats/domain/entities/mandat.interface';
import { getActivityIconById } from '@src/shared/activites/objects/activities.object';
import { getLegalFormNameById } from '@src/shared/legal-form/objects/legal-form.objects';

export const MandatDetailsToArchivateMapper = (
  globalMandat: MandatDetailInterface
): ArchivateMandatDto => {
  return {
    activite: getActivityIconById(globalMandat.mandat.id_activite),
    adresse_mandant:
      globalMandat.mandantContact.street_number +
      ' ' +
      globalMandat.mandantContact.route,
    adresse_pdv:
      globalMandat.salePoints.street_number +
      ' ' +
      globalMandat.salePoints.route,
    complement_adresse_pdv: globalMandat.salePoints.comp_adresse,
    cp_pdv: globalMandat.salePoints.postal_code,
    cp_mandant: globalMandat.mandantContact.postal_code,
    date_debut: globalMandat.mandat.date_debut?.date ?? null,
    date_fin: globalMandat.mandat.date_fin?.date ?? null,
    date_archive: {
      date: new Date().toISOString(),
      timezone_type: 3,
      timezone: 'Europe/Paris',
    },
    depot_garantie: globalMandat.salePoints.depot_garantie,
    description: globalMandat.mandat.description,
    ebe: globalMandat.salePoints.montant_ebe,
    echeance_bail: globalMandat.salePoints.echeance_bail,
    email_mandant: globalMandat.mandantContact.email,
    enseigne: globalMandat.salePoints.enseigne,
    etat: MandatStateEnum.archived,
    fixe_mandant: globalMandat.mandantContact.telephone,
    forme_juridique: getLegalFormNameById(
      globalMandat.mandat.id_forme_juridique
    ),
    honorairescabinet:
      globalMandat.mandat.honoraires_acquereur ??
      globalMandat.mandat.honoraires_vendeur,
    ident: globalMandat.mandat.numero_registre_mandats,
    charges_locatives: globalMandat.salePoints.charges_locatives,
    chiffre_affaires_N: globalMandat.salePoints.ca,
    chiffre_affaires_N1: globalMandat.salePoints.retraite_ca_n1,
    id_mandat: globalMandat.mandat.id,
    lineaire: globalMandat.salePoints.lineaire,
    loyer: globalMandat.salePoints.loyer_annuel,
    mobile_mandant: globalMandat.mandantContact.mobile,
    nom_mandant: globalMandat.mandantContact.nom,
    numero_registre_mandats: globalMandat.mandat.numero_registre_mandats,
    passerelles: null,
    prenom_mandant: globalMandat.mandantContact.prenom,
    prix_de_presentation: globalMandat.mandat.prix_de_presentation,
    siren_mandant: globalMandat.mandantCompanies?.siren ?? null,
    surface_annexe: globalMandat.salePoints.surface_annexe,
    surface_commerciale: globalMandat.salePoints.surface_commerciale,
    surface_terrain: globalMandat.salePoints.surface_terrain,
    surface_terrasse: globalMandat.salePoints.surface_terrasse,
    surface_totale: globalMandat.salePoints.surface_totale,
    titre: globalMandat.mandat.titre,
    ville_diffusion: globalMandat.mandat.vilcespme,
    ville_mandant: globalMandat.mandantContact.locality,
    ville_pdv: globalMandat.salePoints.locality,
    cp_diffusion: globalMandat.mandat.CPdiffusion,
    societe_mandant:
      globalMandat.mandantCompanies?.denomination ??
      globalMandat.mandantCompanies?.nom,
  };
};
