import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[appCotationColor]',
  standalone: true,
})
export class CotationColorDirective implements OnChanges {
  @Input() appCotationColor: string = 'A'; // defaults to 'A'

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnChanges() {
    this.setColor();
  }

  private setColor() {
    let color: string;

    switch (this.appCotationColor) {
      case 'A+':
        color = '#a8c10c';
        break;
      case 'A':
        color = '#28a745';
        break;
      case 'B':
        color = '#17a2b8';
        break;
      case 'C':
        color = '#ff8003';
        break;
      case 'D':
        color = '#dc3545';
        break;
      default:
        color = 'black';
        break;
    }

    this.renderer.setStyle(this.el.nativeElement, 'color', color);
    this.renderer.setStyle(this.el.nativeElement, 'font-weight', 'bold');
    this.renderer.setStyle(this.el.nativeElement, 'font-size', '14px');
  }
}
