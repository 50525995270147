import { DatePipe, registerLocaleData } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { isDevMode, LOCALE_ID, NgModule } from '@angular/core';
import { provideNativeDateAdapter } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AuthenticationModule } from '@features/authentification/authentication.module';
import { CabinetsModule } from '@features/cabinets/cabinets.module';
import { CabinetsState } from '@features/cabinets/domain/redux/state/cabinets.state';
import { ConsultantModule } from '@features/consultant/consultant.module';
import { ConsultantState } from '@features/consultant/domain/redux/state/consultant.state';
import { DashboardState } from '@features/dashboard/domain/redux/state/dashboard.state';
import { ArchivedMandatState } from '@features/mandats/domain/redux/state/archived-mandats.state';
import { MandatState } from '@features/mandats/domain/redux/state/mandats.state';
import { ProspectionMandatsState } from '@features/mandats/domain/redux/state/prospection-mandats.state';
import { MandatsModule } from '@features/mandats/mandats.module';
import { NavState } from '@features/nav/domain/redux/state/nav.state';
import { VisitFormState } from '@features/visit-form/domain/redux/state/visit-form.state';
import { BtnModule } from '@libs/btn/btn.module';
import { NavbarModule } from '@libs/navbar/navbar.module';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsModule } from '@ngxs/store';
import { environment } from '@src/environments/environment';
import { TokenInterceptor } from '@src/shared/interceptors/token.interceptor';
import { RetryInterceptor } from '@src/shared/retry-interceptor/retry-interceptor.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxsModule.forRoot(
      [
        MandatState,
        ArchivedMandatState,
        ProspectionMandatsState,
        CabinetsState,
        ConsultantState,
        VisitFormState,
        NavState,
        DashboardState,
      ],
      {
        developmentMode: !environment.production,
      }
    ),
    NgxsReduxDevtoolsPluginModule.forRoot({}),
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.production,
    }),
    NavbarModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    BtnModule,
    MandatsModule,
    CabinetsModule,
    AuthenticationModule,
    ConsultantModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    DatePipe,
    provideNativeDateAdapter(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'fr' },
    provideAnimationsAsync(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
