import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ShortPappersCompanyInterface } from '@src/shared/interaces/comapnies/entities/pappers-companies-short.interface';

enum MoraleOrPhysiqueEnum {
  morale = 'morale',
  physique = 'physique',
}

@Component({
  selector: 'app-mandant-companies',
  templateUrl: './mandant-companies.component.html',
  styleUrls: ['./mandant-companies.component.scss'],
})
export class MandantCompaniesComponent implements OnInit {
  @Input({ required: true }) companiesMandantForm!: FormGroup;
  @Input({ required: true }) mandatPredefinedForm!: FormGroup;
  @Input() isEditMode: boolean = false;
  moralOrPhysicalControl: FormControl<boolean | null> = new FormControl(false);
  shouldDisplaySearchCompaniesBar: boolean = true;
  backupCompaniesMandantForm: FormGroup = this.fb.group({});
  canCancelTheChangeCompany: boolean = false;

  constructor(
    public dialog: MatDialog,
    public fb: FormBuilder
  ) {}

  ngOnInit() {
    this.shouldDisplaySearchCompaniesBar =
      !this.isEditMode || !this.companiesMandantForm.value;

    if (this.companiesMandantForm.value) {
      this.backupCompaniesMandantForm = this.fb.group(
        this.companiesMandantForm.value
      );
    }
    this.mandatPredefinedForm
      .get('morale_or_physique_mandant')
      ?.setValue(
        this.moralOrPhysicalControl.value === true
          ? MoraleOrPhysiqueEnum.physique
          : MoraleOrPhysiqueEnum.morale
      );
  }

  getPappersResult(event: ShortPappersCompanyInterface): void {
    this.companiesMandantForm.patchValue({
      forme_juridique: event.forme_juridique,
      adresse_ligne_1: event.adresse_ligne_1,
      adresse_ligne_2: event.adresse_ligne_2,
      code_postal: event.code_postal,
      ville: event.ville,
      complement_adresse: event.complement_adresse,
      denomination: event.denomination,
      domaine_activite: event.domaine_activite,
      indice_repetition: event.indice_repetition,
      libelle_code_naf: event.libelle_code_naf,
      libelle_voie: event.libelle_voie,
      nic: event.nic,
      nom: event.nom,
      nom_entreprise: event.nom_entreprise,
      numero_voie: event.numero_voie,
      prenom: event.prenom,
      siren: event.siren,
      siren_formate: event.siren_formate,
      type_search: event.type_search,
      type_voie: event.type_voie,
    });
    this.shouldDisplaySearchCompaniesBar = false;
    this.backupCompaniesMandantForm = this.fb.group(
      this.companiesMandantForm.value
    );
    this.canCancelTheChangeCompany = true;
  }

  resetFormFromChildCall() {
    this.companiesMandantForm.reset();
    this.canCancelTheChangeCompany = true;
    this.mandatPredefinedForm.patchValue({
      morale_or_physique_mandant: MoraleOrPhysiqueEnum.physique,
    });
    this.shouldDisplaySearchCompaniesBar = false;
  }

  toggleBackToSearch() {
    this.shouldDisplaySearchCompaniesBar = true;
    this.mandatPredefinedForm.patchValue({
      morale_or_physique_mandant: MoraleOrPhysiqueEnum.morale,
    });
  }

  setOnlyPhysique() {
    this.shouldDisplaySearchCompaniesBar = false;
    this.companiesMandantForm.reset();
    this.canCancelTheChangeCompany = true;
    this.mandatPredefinedForm.patchValue({
      morale_or_physique_mandant: MoraleOrPhysiqueEnum.physique,
    });
  }

  changeCompanyFromChildCall() {
    this.shouldDisplaySearchCompaniesBar = true;
    this.companiesMandantForm.reset();
    this.canCancelTheChangeCompany = true;
    this.mandatPredefinedForm.patchValue({
      morale_or_physique_mandant: MoraleOrPhysiqueEnum.morale,
    });
  }

  backupForm() {
    this.companiesMandantForm.patchValue(this.backupCompaniesMandantForm.value);
    this.shouldDisplaySearchCompaniesBar = false;
    this.canCancelTheChangeCompany = false;
  }
}
