import { Injectable } from '@angular/core';
import { InvoicesInterface } from '@features/invoices/domain/entities/invoices.interface';
import { InvoicesApiPort } from '@features/invoices/domain/ports/api/invoices-api.port';
import {
  LoadSilenceAllInvoices,
  LoadSilenceInvoices,
} from '@features/invoices/domain/redux/actions/invoices.actions';
import { Action, NgxsOnInit, State, StateContext } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';

export class InvoicesStateModel {
  invoices!: InvoicesInterface[];
  allInvoices!: InvoicesInterface[];
}

export const defaultInvoicesState: InvoicesStateModel = {
  invoices: [],
  allInvoices: [],
};

@State<InvoicesStateModel>({
  name: 'invoices',
  defaults: defaultInvoicesState,
})
@Injectable()
export class InvoicesState implements NgxsOnInit {
  constructor(private invoicesService: InvoicesApiPort) {}

  ngxsOnInit(ctx: StateContext<InvoicesStateModel>) {
    ctx.dispatch(new LoadSilenceInvoices());
    ctx.dispatch(new LoadSilenceAllInvoices());
  }

  @Action(LoadSilenceInvoices)
  async loadSilenceInvoices(
    ctx: StateContext<InvoicesStateModel>
  ): Promise<void> {
    try {
      const invoices = await lastValueFrom(this.invoicesService.getInvoices());
      ctx.patchState({
        invoices: invoices,
      });
    } catch (error) {
      console.error(error);
    }
  }

  @Action(LoadSilenceAllInvoices)
  async loadSilenceAllInvoices(
    ctx: StateContext<InvoicesStateModel>
  ): Promise<void> {
    try {
      const invoices = await lastValueFrom(
        this.invoicesService.getAllInvoices()
      );
      ctx.patchState({
        allInvoices: invoices,
      });
    } catch (error) {
      console.error(error);
    }
  }
}
