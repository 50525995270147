<cdk-virtual-scroll-viewport
  (scrolledIndexChange)="onScroll()"
  class="virtual-scroll-viewport"
  itemSize="50">
  <table
    [dataSource]="tableDataSource"
    class="mat-elevation-z8"
    mat-table
    multiTemplateDataRows
    recycleRows>
    <ng-container matColumnDef="date">
      <th *matHeaderCellDef mat-header-cell>Date d'archive</th>
      <td *matCellDef="let element" mat-cell>
        {{ element.date_archive?.date | date: 'dd/MM/yyyy' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="numero">
      <th *matHeaderCellDef mat-header-cell>N° mandat</th>
      <td
        *matCellDef="let element"
        [routerLink]="['/mandats/archived/details', element.id]"
        class="link"
        mat-cell>
        {{ element.numero_registre_mandats }}
      </td>
    </ng-container>

    <ng-container matColumnDef="mandant">
      <th *matHeaderCellDef class="mandant" mat-header-cell>Mandant</th>
      <td *matCellDef="let element" class="mandant" mat-cell>
        {{ element.nom_mandant | uppercase }} {{ element.prenom_mandant }}
      </td>
    </ng-container>

    <ng-container matColumnDef="activite">
      <th *matHeaderCellDef mat-header-cell>Activité</th>
      <td *matCellDef="let element" mat-cell>
        {{ element.activite }}
      </td>
    </ng-container>

    <ng-container matColumnDef="prix">
      <th *matHeaderCellDef mat-header-cell>Prix</th>
      <td *matCellDef="let element" class="price" mat-cell>
        {{ element.prix_de_presentation | formatEuro }}
      </td>
    </ng-container>

    <ng-container matColumnDef="enseigne">
      <th *matHeaderCellDef class="enseigne" mat-header-cell>Enseigne</th>
      <td *matCellDef="let element" class="enseigne" mat-cell>
        {{ element.enseigne }}
      </td>
    </ng-container>

    <ng-container matColumnDef="adresse">
      <th *matHeaderCellDef class="adresse" mat-header-cell>Adresse</th>
      <td *matCellDef="let element" class="adresse" mat-cell>
        {{ element.adresse_pdv }}
      </td>
    </ng-container>

    <ng-container matColumnDef="ville">
      <th *matHeaderCellDef mat-header-cell>Ville</th>
      <td *matCellDef="let element" mat-cell>
        {{ element.ville_pdv }}
      </td>
    </ng-container>

    <tr
      *matHeaderRowDef="columnsToDisplay; sticky: true"
      class="sticky-header-cell"
      mat-header-row></tr>
    <tr *matRowDef="let element; columns: columnsToDisplay" mat-row></tr>
  </table>
</cdk-virtual-scroll-viewport>
