<cdk-virtual-scroll-viewport
  (scrolledIndexChange)="onScroll()"
  class="virtual-scroll-viewport"
  itemSize="50">
  <table [dataSource]="tableDataSource" class="mat-elevation-z8" mat-table>
    <ng-container matColumnDef="bailleur">
      <th *matHeaderCellDef class="bailleur" mat-header-cell>Bailleur</th>
      <td *matCellDef="let element" class="bailleur" mat-cell>
        {{ element.bailleurContact.nom | uppercase }}
        {{ element.bailleurContact.prenom }}
      </td>
    </ng-container>

    <ng-container matColumnDef="mobile">
      <th *matHeaderCellDef class="mobile" mat-header-cell>Mobile</th>
      <td *matCellDef="let element" class="mobile" mat-cell>
        {{ element.bailleurContact.mobile }}
      </td>
    </ng-container>

    <ng-container matColumnDef="activite">
      <th *matHeaderCellDef class="activite" mat-header-cell>Activité</th>

      <td *matCellDef="let element" class="activite" mat-cell>
        <app-activite-icons
          [activiteId]="element.mandat.id_activite"
          size="small">
        </app-activite-icons>
      </td>
    </ng-container>

    <ng-container matColumnDef="enseigne">
      <th *matHeaderCellDef class="enseigne" mat-header-cell>Enseigne</th>
      <td *matCellDef="let element" class="enseigne" mat-cell>
        <p [routerLink]="['/mandats/details', element.mandat.id]" class="link">
          {{ element.salePoints.enseigne }}
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="loyer">
      <th *matHeaderCellDef class="loyer" mat-header-cell>Loyer</th>
      <td *matCellDef="let element" class="loyer" mat-cell>
        {{ element.salePoints.loyer_annuel | formatEuro }}
      </td>
    </ng-container>

    <ng-container matColumnDef="adresse">
      <th *matHeaderCellDef class="adresse" mat-header-cell>Adresse du PDV</th>
      <td *matCellDef="let element" class="adresse" mat-cell>
        {{ element.salePoints.street_number }} {{ element.salePoints.route }}
        {{ element.salePoints.postal_code }}
        {{ element.salePoints.locality }}
      </td>
    </ng-container>

    <ng-container matColumnDef="ville">
      <th *matHeaderCellDef class="ville" mat-header-cell>Ville</th>
      <td *matCellDef="let element" class="ville" mat-cell>
        {{ element.salePoints.locality | uppercase }}
      </td>
    </ng-container>

    <tr
      *matHeaderRowDef="columnsToDisplay; sticky: true"
      class="sticky-header-cell"
      mat-header-row></tr>
    <tr *matRowDef="let row; columns: columnsToDisplay" mat-row></tr>
  </table>
</cdk-virtual-scroll-viewport>
