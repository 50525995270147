import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ContactCivilityEnum } from '@src/features/contacts/domain/entities/contacts.interface';
import { AddressComponentInterface } from '@src/libs/google-places-autocomplete/formatted-address.interface';
import { CabinetCreateForm } from '@src/shared/forms/cabinet/cabinet-create.form';
import { ShortPappersCompanyInterface } from '@src/shared/interaces/comapnies/entities/pappers-companies-short.interface';

@Component({
  selector: 'app-agency-create-container',
  templateUrl: './agency-create-container.component.html',
  styleUrl: './agency-create-container.component.scss',
})
export class AgencyCreateContainerComponent implements OnInit {
  cabinetCreateForm!: FormGroup;
  cabinetCreateFormBuilder!: CabinetCreateForm;
  civility = {
    monsieur: ContactCivilityEnum.mr,
    madame: ContactCivilityEnum.mrs,
  };
  pappersAdresse: string = '';

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.initializeCabinetCreateForm();
  }

  onSiretChanged(event: ShortPappersCompanyInterface) {
    console.log(event);

    this.cabinetCreateForm.patchValue({
      raison_sociale: event.denomination,
      forme_sociale: event.forme_juridique,
      // capital: event.capital,   // this field is not present in pappers company short interface
      adresse: event.adresse_ligne_1 + ' ' + event.adresse_ligne_2,
      codepostal: event.code_postal,
      ville: event.ville,
      siret: event.siren,
      // num_tva : event.tva,  // this field is not present in pappers company short interface
    });

    this.pappersAdresse = event.adresse_ligne_1 + ' ' + event.adresse_ligne_2;
  }

  onCabinetAdressChanged(event: AddressComponentInterface) {
    this.cabinetCreateForm.patchValue({
      adresse: event?.street_number + ' ' + event?.route,
      codepostal: event.postal_code,
      ville: event.locality,
      lat: event.latitude,
      lng: event.longitude,
    });
  }

  createCabinet() {
    console.log(this.cabinetCreateForm.value);
  }

  cancelCreateCabinet() {
    this.cabinetCreateForm.reset();
    this.router.navigate(['/']);
  }

  private initializeCabinetCreateForm() {
    this.cabinetCreateFormBuilder = new CabinetCreateForm();
    this.cabinetCreateForm = this.cabinetCreateFormBuilder.createFormGroup();
  }
}
