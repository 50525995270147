<div class="bottom-nav-container">
  <div class="nav-item">
    <a
      (click)="navigationGoback()"
      (keydown.enter)="navigationGoback()"
      role="button"
      tabindex="0">
      <img
        alt="Ouvrir/Fermer le menu"
        height="24"
        ngSrc="./assets/icons/arrow.svg"
        style="rotate: 90deg"
        width="24" />
    </a>
    <p class="icon-label">Retour</p>
  </div>
  <div class="nav-item">
    <img
      [queryParams]="{ prospection: 'false' }"
      [routerLink]="'/buyers/new/checking'"
      alt="Acquéreur"
      height="24"
      ngSrc="./assets/navbar/new.png"
      width="24" />
    <p class="icon-label">Créer</p>
  </div>
</div>
