<div class="bottom-nav-container">
  <div class="nav-item">
    <img
      [routerLink]="'/mandats'"
      alt="Mandat"
      height="24px"
      src="./assets/navbar/folder.svg"
      width="24px" />
    <p class="icon-label">Mandat</p>
  </div>
  <div class="nav-item">
    <img
      [routerLink]="'/buyers/false'"
      alt="Acquereurs"
      height="24px"
      src="./assets/navbar/people.svg"
      width="24px" />
    <p [routerLink]="'/buyers/false'" class="icon-label">Acquereurs</p>
  </div>
  <div class="nav-item">
    <img
      [routerLink]="'/business'"
      alt="Business"
      height="24px"
      src="./assets/navbar/hand-shake.svg"
      width="24px" />
    <p class="icon-label">Business</p>
  </div>
</div>
