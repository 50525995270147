import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyCardComponent } from './company-card.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [CommonModule, MatCardModule, MatIconModule, MatTooltipModule],
  declarations: [CompanyCardComponent],
  exports: [CompanyCardComponent],
})
export class AppCompanyCardModule {}
