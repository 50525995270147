<div class="lessors-header">
  <p>Bailleurs {{ filteredLessors.length }} / {{ lessors.length }}</p>
</div>
<div class="lessors-header-mobile">
  <p>Bailleurs {{ lessors.length }} / {{ filteredLessors.length }}</p>
</div>

<app-mandat-search-tab
  (filteredMandatsChange)="updateMandatList($event)"
  (showMandat)="handleFiltersActiveChange($event)"
  [mandats]="lessors">
  >
</app-mandat-search-tab>

<div *ngIf="this.filterTriggered">
  <app-lessor-table
    *ngIf="filteredLessors.length > 0"
    [dataSource]="filteredLessors" />
</div>
