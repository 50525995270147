import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { MandatsSelectors } from '@features/mandats/domain/redux/selectors/mandats.selectors';
import { MandatStateEnum } from '@features/mandats/domain/entities/mandat.interface';
import { LoadSilenceMandats } from '@features/mandats/domain/redux/actions/mandats.actions';

@UntilDestroy()
@Component({
  selector: 'app-lessors-container',
  templateUrl: './lessors-container.component.html',
  styleUrl: './lessors-container.component.scss',
})
export class LessorsContainerComponent implements OnInit {
  lessors$!: Observable<MandatDetailInterface[]>;
  lessors: MandatDetailInterface[] = [];
  filteredLessors: MandatDetailInterface[] = [];
  filterTriggered = true;

  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef
  ) {
    this.lessors$ = this.store.select(MandatsSelectors.lessors);
  }

  ngOnInit() {
    this.fetchLessors();
  }

  updateMandatList(lessors: MandatDetailInterface[]) {
    this.filteredLessors = this.sortMandats(lessors);
    this.cdr.detectChanges();
  }

  handleFiltersActiveChange($event: boolean) {
    this.filterTriggered = $event;
  }

  sortMandats(mandats: MandatDetailInterface[]): MandatDetailInterface[] {
    const priorityMap = new Map<MandatStateEnum, number>([
      [MandatStateEnum.judiciaryProcess, 1],
      [MandatStateEnum.preMandat, 2],
      [MandatStateEnum.active, 3],
      [MandatStateEnum.underCompromise, 3],
      [MandatStateEnum.underOffer, 3],
    ]);
    return mandats.sort((a, b) => {
      // Get priorities from the map or use a default value
      const priorityA = priorityMap.get(a.mandat.etat) ?? 99;
      const priorityB = priorityMap.get(b.mandat.etat) ?? 99;
      if (priorityA !== priorityB) {
        return priorityA - priorityB;
      }
      if (
        (a.mandat.etat === MandatStateEnum.active ||
          a.mandat.etat === MandatStateEnum.underCompromise ||
          a.mandat.etat === MandatStateEnum.underOffer) &&
        (b.mandat.etat === MandatStateEnum.active ||
          b.mandat.etat === MandatStateEnum.underCompromise ||
          b.mandat.etat === MandatStateEnum.underOffer)
      ) {
        const numA = parseInt(
          a.mandat.numero_registre_mandats.replace(/\D/g, ''),
          10
        );
        const numB = parseInt(
          b.mandat.numero_registre_mandats.replace(/\D/g, ''),
          10
        );
        return numB - numA; // Descending order
      }
      return 0;
    });
  }

  private fetchLessors() {
    this.store.dispatch(new LoadSilenceMandats());
    this.lessors$.pipe(untilDestroyed(this)).subscribe(lessors => {
      this.lessors = lessors || [];
      this.filteredLessors = this.lessors;
      console.log(this.filteredLessors);
    });
  }
}
