<div class="container">
  <div class="search-bar mt-10">
    <app-search-bar
      (input)="onSearchChange($any($event.target).value)"
      [(ngModel)]="filters.search"
      type="text" />
    <div style="position: relative; display: inline-block">
      <button
        (click)="trigerFilter()"
        style="border: none; background: none; padding: 0">
        <img
          alt="Filter"
          height="34"
          src="../../../../../../../../assets/icons/filter.svg"
          width="34" />
      </button>
    </div>
  </div>
  <div class="selection-container single-item">
    <div class="search-item">
      <label for="activity-select">Activité :</label>
      <app-select
        (changed)="onActivityChange($event)"
        [(ngModel)]="displayedValueOfActivity"
        [items]="activitiesItems"
        fontSize="small"
        id="activity-select">
      </app-select>
    </div>
  </div>
  <div class="selection-container">
    <div class="search-item">
      <app-input-slide
        (ngModelChange)="onAddressChange($event)"
        [forDesktop]="true"
        [ngModel]="filters.address"
        inputType="text"
        placeholder="Adresse" />
    </div>
    <div class="search-item">
      <app-input-slide
        (ngModelChange)="onLocalityChange($event)"
        [forDesktop]="true"
        [ngModel]="filters.locality"
        inputType="text"
        placeholder="Ville" />
    </div>
  </div>
</div>
<div class="search">
  <app-search-bar
    (input)="onSearchChange($any($event.target).value)"
    [(ngModel)]="filters.search"
    type="text" />
</div>

<div *ngIf="!filterTriggered" class="mobile-search">
  <app-select
    (changed)="onActivityChange($event)"
    [(ngModel)]="displayedValueOfActivity"
    [items]="activitiesItems"
    fontSize="small"
    placeholder="Activités"
    size="fit" />

  <app-input-slide
    (ngModelChange)="onAddressChange($event)"
    [ngModel]="filters.address"
    inputType="text"
    placeholder="Adresse" />

  <app-input-slide
    (ngModelChange)="onLocalityChange($event)"
    [ngModel]="filters.locality"
    inputType="text"
    placeholder="Ville" />

  <div class="btn-container">
    <app-btn
      (btnClick)="resetFilters(); trigerFilter()"
      color="alerte"
      text="Annuler" />
    <app-btn (btnClick)="trigerFilter()" color="validate" text="Valider" />
  </div>
</div>
