import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cabinetActive',
  standalone: true,
})
export class CabinetActivePipe implements PipeTransform {
  transform(value: string): string {
    if (value == '1') {
      return 'ACTIF';
    } else {
      return 'INACTIF';
    }
  }
}
