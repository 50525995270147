import { LegalFormInterface } from '@src/shared/legal-form/interfaces/legal-form.interface';

export const legalFormObject: LegalFormInterface[] = [
  { id: 1, parent_id: 1, name: 'Fonds de commerce' },
  { id: 2, parent_id: 1, name: 'Droit au bail' },
  { id: 3, parent_id: 2, name: 'Recherche de locataire' },
  { id: 4, parent_id: 3, name: 'Bien immobilier' },
];

export function getLegalFormNameById(id: number): string | null {
  const legalForm = legalFormObject.find(form => form.id === id);
  return legalForm ? legalForm.name : null;
}
