<div class="bottom-nav-container">
  <div class="nav-item">
    <a
      (click)="navigationGoback()"
      (keydown.enter)="navigationGoback()"
      tabindex="0">
      <img
        alt="Ouvrir/Fermer le menu"
        height="24px"
        ngSrc="./assets/icons/arrow.svg"
        style="rotate: 90deg"
        width="24px" />
    </a>
    <p class="icon-label">Retour</p>
  </div>
  <div class="nav-item">
    <img
      [routerLink]="'/buyers/false'"
      alt="Acquereurs Icon"
      height="24px"
      src="./assets/navbar/save.svg"
      width="24px" />
    <p>Enregistrers</p>
  </div>
</div>
