// src/features/mandats/adapters/primary/ui/containers/mandats-container/mandat-utils.ts

import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';
import { MandatStateEnum } from '@features/mandats/domain/entities/mandat.interface';

export function sortMandats(
  mandats: MandatDetailInterface[]
): MandatDetailInterface[] {
  const priorityMap = new Map<MandatStateEnum, number>([
    [MandatStateEnum.judiciaryProcess, 1],
    [MandatStateEnum.preMandat, 2],
    [MandatStateEnum.active, 3],
    [MandatStateEnum.underCompromise, 3],
    [MandatStateEnum.underOffer, 3],
  ]);

  return mandats.sort((a, b) => {
    const priorityA = priorityMap.get(a.mandat.etat) ?? 99;
    const priorityB = priorityMap.get(b.mandat.etat) ?? 99;

    if (priorityA !== priorityB) {
      return priorityA - priorityB;
    }

    if (
      (a.mandat.etat === MandatStateEnum.active ||
        a.mandat.etat === MandatStateEnum.underCompromise ||
        a.mandat.etat === MandatStateEnum.underOffer) &&
      (b.mandat.etat === MandatStateEnum.active ||
        b.mandat.etat === MandatStateEnum.underCompromise ||
        b.mandat.etat === MandatStateEnum.underOffer)
    ) {
      const numA = parseInt(
        a.mandat.numero_registre_mandats.replace(/\D/g, ''),
        10
      );
      const numB = parseInt(
        b.mandat.numero_registre_mandats.replace(/\D/g, ''),
        10
      );
      return numB - numA;
    }

    return 0;
  });
}
