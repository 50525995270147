<form [formGroup]="salePointForm" class="create-form">
  <div class="form-group">
    <app-input-slide
      [inputControl]="getControl('enseigne')"
      [required]="true"
      formControlName="enseigne"
      inputType="text"
      placeholder="Enseigne"
      width="fit" />
    <app-google-places-autocomplete
      (formattedAddress)="onChange($event)"
      [initialValue]="getControlContact('formatted_address').value"
      placeholder="Adresse" />
  </div>
  <div class="separator"></div>
  <p class="surface-title">Surface :</p>
  <div class="form-group-surface">
    <app-input-slide
      [inputControl]="getControl('surface_commerciale')"
      [required]="true"
      formControlName="surface_commerciale"
      inputType="surface"
      placeholder="Commerciale"
      type="number"
      width="fit" />
    <app-input-slide
      formControlName="surface_annexe"
      inputType="surface"
      placeholder="Annexe"
      type="number"
      width="fit" />

    <app-input-slide
      formControlName="surface_bureaux"
      inputType="surface"
      placeholder="Bureaux"
      type="number"
      width="fit" />
    <app-input-slide
      formControlName="surface_terrasse"
      inputType="surface"
      placeholder="Terrasse"
      type="number"
      width="fit" />

    <app-input-slide
      formControlName="surface_terrain"
      inputType="surface"
      placeholder="Terrain"
      type="number"
      width="fit" />
    <app-input-slide
      formControlName="lineaire"
      inputType="surface"
      placeholder="Linéaire"
      type="number"
      width="fit" />
    <div></div>
  </div>

  <p class="surface-total">
    Total (hors terrain / linéaire) :
    <span>{{ salePointForm.get('surface_totale')?.value || '0' }} m2 </span>
  </p>
  <div class="separator"></div>
  <div class="checkbox-container">
    <div>
      <div class="align-checkbox">
        <label for="extraction">Extraction :</label>
        <mat-checkbox
          (change)="updateValue($event, 'presence_extraction')"
          id="extraction"></mat-checkbox>
      </div>
      <div class="align-checkbox">
        <label for="copro">Copropriété :</label>
        <mat-checkbox
          (change)="updateValue($event, 'copropriete')"
          id="copro" />
      </div>
      <div class="align-checkbox">
        <label for="accessibilite">Accessibilité PMR :</label>
        <mat-checkbox
          (change)="updateValue($event, 'accessibilite_pmr')"
          id="accessibilite"></mat-checkbox>
      </div>
    </div>
    <div>
      <div class="align-checkbox">
        <label for="acces-pl">Acces PL :</label>
        <mat-checkbox
          (change)="updateValue($event, 'acces_pl')"
          id="acces-pl" />
      </div>
      <div class="align-checkbox">
        <label for="quai">Quai de déchargement :</label>
        <mat-checkbox
          (change)="updateValue($event, 'quai_dechargement')"
          id="quai" />
      </div>
    </div>
  </div>
</form>
