import { ArchivateMandatInterface } from '@features/mandats/domain/entities/archivate-mandat.interface';
import { ArchivedMandatFilterParams } from '@features/mandats/domain/entities/mandat-filter-params.interface';

export class ArchivedMandatsFilterClass {
  static readonly NO_SEARCH_VALUE = 0;
  static readonly NO_SEARCH_VALUE_STRING = '';

  static filterMandats(params: ArchivedMandatFilterParams) {
    return [...params.archivedMandats]
      .filter(this.filterSearchValue(params))
      .filter(this.filterAddressValue(params))
      .filter(this.filterLocalityValue(params))
      .filter(this.filterActivityValue(params));
  }

  static resetParams(mandat: ArchivateMandatInterface[]) {
    return {
      archivedMandats: mandat,
      search: this.NO_SEARCH_VALUE_STRING,
      activity: this.NO_SEARCH_VALUE_STRING,
      address: this.NO_SEARCH_VALUE_STRING,
      locality: this.NO_SEARCH_VALUE_STRING,
    };
  }

  private static filterSearchValue(params: ArchivedMandatFilterParams) {
    return (mandat: ArchivateMandatInterface) =>
      params.search === this.NO_SEARCH_VALUE_STRING ||
      mandat.numero_registre_mandats
        ?.toLowerCase()
        .includes(params.search.toLowerCase()) ||
      mandat.nom_mandant?.toLowerCase().includes(params.search.toLowerCase()) ||
      mandat.prenom_mandant
        ?.toLowerCase()
        .includes(params.search.toLowerCase()) ||
      mandat.enseigne?.toLowerCase().includes(params.search.toLowerCase());
  }

  private static filterAddressValue(params: ArchivedMandatFilterParams) {
    return (mandat: ArchivateMandatInterface) =>
      params.address === this.NO_SEARCH_VALUE_STRING ||
      mandat.adresse_pdv.toLowerCase().includes(params.address.toLowerCase());
  }

  private static filterLocalityValue(params: ArchivedMandatFilterParams) {
    return (mandat: ArchivateMandatInterface) =>
      params.locality === this.NO_SEARCH_VALUE_STRING ||
      mandat.ville_pdv.toLowerCase().includes(params.locality.toLowerCase());
  }

  private static filterActivityValue(params: ArchivedMandatFilterParams) {
    return (mandat: ArchivateMandatInterface) =>
      params.activity === this.NO_SEARCH_VALUE_STRING ||
      mandat.activite === params.activity;
  }
}
