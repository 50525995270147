import {
  ArchivedMandatState,
  ArchivedMandatStateModel,
} from '@features/mandats/domain/redux/state/archived-mandats.state';
import { createSelector, Selector } from '@ngxs/store';
import { ArchivateMandatInterface } from '@features/mandats/domain/entities/archivate-mandat.interface';

export class ArchivedMandatsSelectors {
  @Selector([ArchivedMandatState])
  static archivedMandats(
    state: ArchivedMandatStateModel
  ): ArchivateMandatInterface[] | undefined {
    return state.archivedMandats;
  }

  static readonly getArchivedMandatById = (id: number) => {
    return createSelector(
      [ArchivedMandatState],
      (state: ArchivedMandatStateModel) => {
        if (!state.archivedMandats) return;
        return state.archivedMandats.find(mandats => mandats.id === id);
      }
    );
  };
}
