import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavSelectors } from '@features/nav/domain/redux/selectors/nav.selectors';
import { VisitFormSelectors } from '@features/visit-form/domain/redux/selectors/visit-form.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { Logout } from '@features/authentification/domain/redux/actions/authentication.action';

@UntilDestroy()
@Component({
  selector: 'app-bottom-nav',
  templateUrl: './bottom-nav.component.html',
  styleUrl: './bottom-nav.component.scss',
  animations: [
    trigger('menuState', [
      state(
        'void, closed',
        style({
          transform: 'translateY(100%)',
        })
      ),
      state(
        '*, open',
        style({
          transform: 'translateY(0)',
        })
      ),
      transition('void <=> *, closed <=> open', animate('400ms ease-in-out')),
    ]),
    trigger('moveInAndOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('400ms 200ms ease', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        animate('400ms  ease', style({ transform: 'translateX(-100%)' })),
      ]),
    ]),
  ],
})
export class BottomNavComponent implements OnInit {
  public fullScreenMenuOpen = false;
  public navState = 'moveRight';
  public actualUrl!: string;
  public previousUrl!: string;
  public numberOfSelectedMandatsInVisitForm!: number;

  constructor(
    private store: Store,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.store
      .select(NavSelectors.actualUrl)
      .pipe(untilDestroyed(this))
      .subscribe(url => {
        this.actualUrl = url;
      });

    this.store
      .select(NavSelectors.previousUrl)
      .pipe(untilDestroyed(this))
      .subscribe(url => {
        this.previousUrl = url;
      });

    this.store
      .select(VisitFormSelectors.mandatsLength)
      .pipe(untilDestroyed(this))
      .subscribe(numberOfSelectedMandatsInVisitForm => {
        this.numberOfSelectedMandatsInVisitForm =
          numberOfSelectedMandatsInVisitForm;
      });
  }

  toggleFullScreenMenu() {
    this.fullScreenMenuOpen = !this.fullScreenMenuOpen;
    this.navState = this.navState === 'moveRight' ? 'moveLeft' : 'moveRight';
  }

  navigationGoback() {
    this.router.navigate(['../']);
  }

  isMandatUpdateRoute(): boolean {
    const path = this.router.url.split('/');
    return path[1] === 'mandats' && path[2] === 'update' && !isNaN(+path[3]);
  }

  logout() {
    this.store.dispatch(new Logout());
    this.toggleFullScreenMenu();
  }
}
