import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-activate-mandat-pop-up',
  templateUrl: './activate-mandat-pop-up.component.html',
  styleUrls: ['./activate-mandat-pop-up.component.scss'],
})
export class ActivateMandatPopUpComponent {
  mandatForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ActivateMandatPopUpComponent>
  ) {
    this.mandatForm = this.fb.group({
      date_debut: [new Date().toISOString().split('T')[0], Validators.required],
      numero_registre: ['', Validators.required],
      duree_en_mois: ['24', [Validators.required, Validators.min(1)]],
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    if (this.mandatForm.valid) {
      this.dialogRef.close(this.mandatForm.value);
    }
  }
}
