<nav class="nav-header">
  <img
    (click)="goBack()"
    (keyup.enter)="goBack()"
    alt="Retour"
    height="20"
    ngSrc="../../../../../../../../assets/mandat-detail/arrow-left-white.svg"
    tabindex="0"
    width="20" />

  <app-activite-icons
    [activiteId]="this.idActivity"
    class="app-activite-icons"
    size="small"></app-activite-icons>

  <h1 class="title">{{ this.salePointName }}</h1>
  <div class="btn-container">
    <ng-container *ngIf="this.mandat.mandat.etat === MandatStateEnum.preMandat">
      <app-btn
        (btnClick)="activateMandat()"
        [matIcon]="'check_circle_outline'"
        color="validate"
        size="small"
        text="Activer" />
    </ng-container>
    <ng-container
      *ngIf="
        this.mandat.mandat.etat === MandatStateEnum.preMandat ||
        this.mandat.mandat.etat === MandatStateEnum.prospection
      ">
      <app-btn
        (btnClick)="onPreviewSignatureElectronique()"
        *ngIf="!shouldShowSignatureButton"
        [matIcon]="'description'"
        color="secondary"
        size="small"
        text="Prévisualiser" />
      <app-btn
        (btnClick)="swapToProcedure()"
        [matIcon]="'balance'"
        color="alerte"
        size="small"
        text="Procédure" />
    </ng-container>

    <app-btn
      [matIcon]="'edit'"
      [routerLink]="['/mandats/update', mandat.mandat.id]"
      color="ternary"
      size="small"
      text="Modifier" />
    <ng-container *ngIf="this.mandat.mandat.etat === MandatStateEnum.preMandat">
      <app-btn
        (btnClick)="onSendSignatureElectronique()"
        *ngIf="this.shouldShowSignatureButton"
        [matIcon]="'edit_document'"
        color="secondary"
        size="small"
        text="Signer" />
    </ng-container>
    <ng-container
      *ngIf="
        this.mandat.mandat.etat === MandatStateEnum.preMandat ||
        this.mandat.mandat.etat === MandatStateEnum.judiciaryProcess
      ">
      <app-btn
        (btnClick)="swapToProspection()"
        [matIcon]="'home'"
        color="secondary"
        size="small"
        text="Prospection" />
    </ng-container>
    <ng-container
      *ngIf="
        this.mandat.mandat.etat === MandatStateEnum.judiciaryProcess ||
        this.mandat.mandat.etat === MandatStateEnum.prospection
      ">
      <app-btn
        (btnClick)="createPm()"
        [matIcon]="'create_new_folder'"
        color="validate"
        size="small"
        text="Créer PM" />
    </ng-container>
    <ng-container
      *ngIf="
        this.mandat.mandat.etat !== MandatStateEnum.prospection &&
        this.mandat.mandat.etat !== MandatStateEnum.judiciaryProcess &&
        this.mandat.mandat.etat !== MandatStateEnum.preMandat
      ">
      <app-btn
        (btnClick)="archivateMandat()"
        [matIcon]="'inventory_2'"
        color="alerte"
        size="small"
        text="Archiver" />
    </ng-container>
  </div>

  <span
    [matBadgeHidden]="!mandatIds.includes(mandat.mandat.id)"
    matBadge="V"
    matBadgeColor="primary"
    matBadgePosition="before"
    matBadgeSize="small">
    <img
      (click)="addMandatToListVisitForm(mandat)"
      (keyup.enter)="addMandatToListVisitForm(mandat)"
      *ngIf="
        mandatState !== MandatStateEnum.preMandat &&
        mandatState !== MandatStateEnum.judiciaryProcess &&
        mandatState !== MandatStateEnum.prospection
      "
      alt="cart icon"
      height="22"
      ngSrc="../../../../../../../../assets/mandat-detail/cart.svg"
      style="margin-left: 6px"
      tabindex="0"
      width="22" />
    <img
      *ngIf="mandatState === MandatStateEnum.prospection"
      alt="cart icon"
      height="22"
      ngSrc="../../../../../../../../assets/navbar/extract-w.png"
      style="margin-left: 6px"
      width="22" />
  </span>
</nav>
