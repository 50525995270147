import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe that formats a number or string by adding thousands separators and optional decimal places.
 *
 * This pipe formats a numeric value by inserting a dot `.` as the thousands separator
 * and a comma `,` as the decimal separator, following certain European number formatting conventions.
 * It can optionally display or hide decimal places based on the `includeDecimals` parameter.
 *
 * **Usage in Template:**
 * ```html
 * <!-- With decimals (default behavior) -->
 * {{ value | thousandsSeparator }}
 *
 * <!-- Without decimals -->
 * {{ value | thousandsSeparator:false }}
 * ```
 *
 * **Examples:**
 * ```html
 * <!-- Assuming value = 1234567.89 -->
 *
 * <!-- Outputs: "1.234.567,89" -->
 * {{ value | thousandsSeparator }}
 *
 * <!-- Outputs: "1.234.567" -->
 * {{ value | thousandsSeparator:false }}
 * ```
 *
 * @export
 * @class ThousandsSeparatorPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'thousandsSeparator',
  standalone: true,
})
export class ThousandsSeparatorPipe implements PipeTransform {
  transform(value: number | string, includeDecimals: boolean = true): string {
    if (value === null || value === undefined || value === 0) {
      return '-';
    }

    let formattedNumber: string;

    // Convertir la valeur en nombre
    const numericValue = typeof value === 'number' ? value : parseFloat(value);

    if (includeDecimals) {
      formattedNumber = numericValue.toFixed(2); // Conserver deux décimales
    } else {
      formattedNumber = Math.trunc(numericValue).toString(); // Enlever les décimales
    }

    // Séparer la partie entière et décimale
    const [integerPart, decimalPart] = formattedNumber.split('.');

    // Ajouter le séparateur de milliers à la partie entière
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    // Combiner la partie entière avec la partie décimale si nécessaire
    if (includeDecimals && decimalPart) {
      return `${formattedInteger},${decimalPart}`;
    } else {
      return formattedInteger;
    }
  }
}
