import {
  Component,
  ElementRef,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
import { CabinetsInterface } from '@features/cabinets/domain/entities/cabinets.interface';
import { Store } from '@ngxs/store';
import { CabinetsSelectors } from '@features/cabinets/domain/redux/selectors/cabinets.selectors';
import { AuthenticationSelectors } from '@features/authentification/domain/redux/selectors/authentication.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from '@src/environments/environment.production'; //TODO change to environment

@UntilDestroy()
@Component({
  selector: 'app-agency-info-container',
  templateUrl: './agency-info-container.component.html',
  styleUrl: './agency-info-container.component.scss',
})
export class AgencyInfoContainerComponent implements OnInit {
  userCabinetId$: Observable<number | undefined> = inject(Store).select(
    AuthenticationSelectors.userCabinetId
  );
  cabinet$!: Observable<CabinetsInterface | undefined>;
  file!: File | null;
  filePreviewUrl: string | ArrayBuffer | null = null;
  isFileSelected = false;
  apiUrl = environment.apiUrl;

  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  constructor(private store: Store) {}

  ngOnInit() {
    this.userCabinetId$.pipe(untilDestroyed(this)).subscribe(id => {
      if (id) {
        this.cabinet$ = this.store.select(CabinetsSelectors.getCabinetById(id));
      }
    });
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files ? input.files[0] : null;
    if (file) {
      this.file = file;
      this.isFileSelected = true;
      console.log('Fichier sélectionné:', file);
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target) {
          this.filePreviewUrl = e.target.result;
        }
      };
      reader.readAsDataURL(file);
    }
  }

  editPasserelleImage() {
    this.fileInput.nativeElement.click();
  }

  uploadPasserelleImage() {
    // TODO : submit the file to the server
    this.isFileSelected = false;
  }

  cancelUpload() {
    this.isFileSelected = false;
    this.filePreviewUrl = null;
  }

  editCabinet() {}
}
