import { Component, EventEmitter, Output } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Logout } from '@features/authentification/domain/redux/actions/authentication.action';

@Component({
  selector: 'app-opened-menu',
  templateUrl: './opened-menu.component.html',
  styleUrl: './opened-menu.component.scss',
  animations: [
    trigger('menuState', [
      state(
        'void, closed',
        style({
          transform: 'translateY(100%)',
        })
      ),
      state(
        '*, open',
        style({
          transform: 'translateY(0)',
        })
      ),
      transition('void <=> *, closed <=> open', animate('400ms ease-in-out')),
    ]),
  ],
})
export class OpenedMenuComponent {
  @Output() closeMenu = new EventEmitter<void>();
  public fullScreenMenuOpen = true;

  constructor(
    private router: Router,
    private store: Store
  ) {}

  toggleFullScreenMenu() {
    this.closeMenu.emit();
  }

  logout() {
    this.store.dispatch(new Logout());
    this.toggleFullScreenMenu();
  }
}
