import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { VisiteInterface } from '@features/visites/domain/models/visite-interface';
import { environment } from '@src/environments/environment';
import { BuyerDetailInterface } from '@features/buyers/domain/entities/buyers.interface';
import { MandatInterface } from '@features/mandats/domain/entities/mandat.interface';

@Injectable({
  providedIn: 'root',
})
export class VisitApiService {
  apiPath = environment.apiUrl;

  constructor(private readonly http: HttpClient) {}

  create(body: {
    date_visite: string;
    acquereurs: BuyerDetailInterface[];
    mandats: MandatInterface[];
    id_consultant: number;
  }): Observable<VisiteInterface> {
    return this.http.post<VisiteInterface>(`${this.apiPath}/visits`, body);
  }
}
