<div
  *ngIf="fullScreenMenuOpen"
  [@menuState]="fullScreenMenuOpen ? 'open' : 'closed'"
  class="opened-menu-container">
  <p>Tableau de bord</p>
  <ul>
    <li>
      <a (click)="toggleFullScreenMenu()" [routerLink]="['/dashboard']">
        Dashboard
      </a>
    </li>
  </ul>
  <p>Mandat</p>
  <ul>
    <li>
      <a (click)="toggleFullScreenMenu()" [routerLink]="['/mandats']">
        Mandat actif
      </a>
    </li>
    <li>
      <a
        (click)="toggleFullScreenMenu()"
        [routerLink]="['/mandats/prospections']">
        Fichier de prospection</a
      >
    </li>
    <li>Bailleur</li>
    <li>
      <a
        (click)="toggleFullScreenMenu()"
        [queryParams]="{ prospection: false }"
        [routerLink]="['/mandats/checking']">
        Créer un pré-mandat
      </a>
    </li>
    <li>
      <a
        (click)="toggleFullScreenMenu()"
        [queryParams]="{ prospection: true }"
        [routerLink]="['/mandats/checking']">
        Créer une fiche de prospection
      </a>
    </li>
  </ul>
  <p>Acquereurs</p>
  <ul>
    <li>
      <a (click)="toggleFullScreenMenu()" [routerLink]="['/buyers/false']"
        >Mes acquéreurs
      </a>
    </li>
    <li>
      <a (click)="toggleFullScreenMenu()" [routerLink]="['/buyers/true']"
        >Nos acquéreurs</a
      >
    </li>
    <li>
      <a
        (click)="toggleFullScreenMenu()"
        [routerLink]="['/buyers/new/checking']">
        Créer un acquéreur
      </a>
    </li>
    <li>
      <a
        (click)="toggleFullScreenMenu()"
        [routerLink]="['/business/new/create']">
        Créer une offre d'achat</a
      >
    </li>
  </ul>
  <p>Affaires</p>
  <ul>
    <li>
      <a (click)="toggleFullScreenMenu()" [routerLink]="['/business']"
        >Affaires en cours
      </a>
    </li>
    <li>
      <a (click)="toggleFullScreenMenu()" [routerLink]="['/invoices']"
        >Factures
      </a>
    </li>
  </ul>
  <p>Archives</p>
  <ul>
    <li>
      <a (click)="toggleFullScreenMenu()" [routerLink]="['/mandats/archived']"
        >Mandats archivés</a
      >
    </li>
    <li>
      <a (click)="toggleFullScreenMenu()" [routerLink]="['/buyers/archived']"
        >Acquéreurs archivés
      </a>
    </li>
    <li>
      <a (click)="toggleFullScreenMenu()" [routerLink]="['/business/handled']"
        >Affaires traitées</a
      >
    </li>
  </ul>

  <p>Connexion</p>
  <ul>
    <li (click)="logout()" (keydown)="logout()" tabindex="0">Se déconnecter</li>
  </ul>

  <button (click)="toggleFullScreenMenu()" class="close-button">
    <img
      alt="Fermer le menu"
      height="30px"
      src="./assets/icons/arrow.svg"
      width="30px" />
  </button>
</div>
