import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AgencyInfoContainerComponent } from '@features/cabinets/adapters/primary/ui/containers/agency-info-container/agency-info-container.component';

import { AgencyEditContainerComponent } from './adapters/primary/ui/containers/agency-edit-container/agency-edit-container.component';
import { AgenciesListContainerComponent } from './adapters/primary/ui/containers/agencies-list-container/agencies-list-container.component';
import { AgencyCreateContainerComponent } from './adapters/primary/ui/containers/agency-create-container/agency-create-container.component';
import { AgencyFeesContainerComponent } from './adapters/primary/ui/containers/agency-fees-container/agency-fees-container.component';

const routes: Routes = [
  {
    path: 'informations',
    component: AgencyInfoContainerComponent,
  },
  {
    path: 'edit',
    component: AgencyEditContainerComponent,
  },
  {
    path: 'list',
    component: AgenciesListContainerComponent,
  },
  {
    path: 'create',
    component: AgencyCreateContainerComponent,
  },
  {
    path: 'fees',
    component: AgencyFeesContainerComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CabinetsRoutingModule {}
