import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  NavigateToPreviousUrl,
  NavigateToUrl,
  SetActualUrl,
  SetPreviousUrl,
} from '@features/nav/domain/redux/actions/nav.actions';
import { Action, State, StateContext } from '@ngxs/store';

export class NavStateModel {
  actualUrl!: string;
  previousUrl!: string;
}

export const defaultNavState: NavStateModel = {
  actualUrl: '',
  previousUrl: '',
};

@State<NavStateModel>({
  name: 'nav',
  defaults: defaultNavState,
})
@Injectable()
export class NavState {
  constructor(private router: Router) {}

  // set actual url
  @Action(SetActualUrl)
  setActualUrl(ctx: StateContext<NavStateModel>, action: SetActualUrl): void {
    const state = ctx.getState();
    ctx.setState({ ...state, actualUrl: action.url });
  }

  @Action(SetPreviousUrl)
  setPreviousUrl(
    ctx: StateContext<NavStateModel>,
    action: SetPreviousUrl
  ): void {
    const state = ctx.getState();
    ctx.setState({ ...state, previousUrl: action.url });
  }

  @Action(NavigateToPreviousUrl)
  navigateToPreviousUrl(ctx: StateContext<NavStateModel>): void {
    const state = ctx.getState();
    this.router.navigateByUrl(state.previousUrl);
  }

  // navigate to url
  @Action(NavigateToUrl)
  navigateToUrl(ctx: StateContext<NavStateModel>, action: NavigateToUrl): void {
    this.router.navigateByUrl(action.url);
  }
}
