import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@features/authentification/adapters/secondary/guard/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    data: { hideNavbar: true },
    loadChildren: () =>
      import('src/features/authentification/authentication.module').then(
        m => m.AuthenticationModule
      ),
    title: 'Connexion',
  },
  {
    path: 'account',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('src/features/account/account.module').then(m => m.AccountModule),
    title: 'Mon compte',
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('src/features/dashboard/dashboard.module').then(
        m => m.DashboardModule
      ),
    title: 'Tableau de bord',
  },

  {
    path: 'mandats',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('src/features/mandats/mandats.module').then(m => m.MandatsModule),
    title: 'Mandats actifs',
  },
  {
    path: 'bon-de-visite',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('src/features/visit-form/visit-form.module').then(
        m => m.VisitFormModule
      ),
  },
  {
    path: 'buyers',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('src/features/buyers/buyers.module').then(m => m.BuyersModule),
  },
  {
    path: 'business',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('src/features/business/business.module').then(
        m => m.BusinessModule
      ),
  },
  {
    path: 'invoices',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('src/features/invoices/invoices.module').then(
        m => m.InvoicesModule
      ),
  },
  {
    path: 'admin/invoice-network',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('src/features/invoice-network/invoice-network.module').then(
        m => m.InvoiceNetworkModule
      ),
  },
  {
    path: 'agency',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('src/features/cabinets/cabinets.module').then(
        m => m.CabinetsModule
      ),
  },

  {
    path: 'super-admin/agencies',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('src/features/cabinets/cabinets.module').then(
        m => m.CabinetsModule
      ),
  },
  {
    path: 'super-admin/invoice-network',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('src/features/invoice-network/invoice-network.module').then(
        m => m.InvoiceNetworkModule
      ),
  },
  {
    path: 'super-admin/consultants',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('src/features/consultant/consultant.module').then(
        m => m.ConsultantModule
      ),
  },
  {
    path: 'super-admin/passerelles',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('src/features/passerelles/passerelles.module').then(
        m => m.PasserellesModule
      ),
  },
  {
    path: 'passerelles',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('src/features/passerelles/passerelles.module').then(
        m => m.PasserellesModule
      ),
  },

  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
