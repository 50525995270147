<div class="bottom-nav-container">
  <div class="nav-item">
    <img
      (click)="navigationGoback()"
      (keyup.enter)="navigationGoback()"
      alt="Ouvrir/Fermer le menu"
      height="30"
      ngSrc="./assets/icons/arrow.svg"
      style="rotate: 90deg"
      tabindex="0"
      width="30" />
    <p class="icon-label">Retour</p>
  </div>
  <div class="nav-item">
    <img
      [queryParams]="{ prospection: true }"
      [routerLink]="'mandats/checking'"
      alt="Mandat"
      height="30px"
      src="./assets/navbar/new.png"
      width="30px" />
    <p class="icon-label">Créer</p>
  </div>
  <div class="nav-item">
    <img
      alt="Mandat"
      height="30px"
      src="./assets/navbar/extract.png"
      width="30px" />
    <p class="icon-label">Exporter</p>
  </div>
</div>
