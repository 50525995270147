import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { AuthenticationSelectors } from '@src/features/authentification/domain/redux/selectors/authentication.selectors';
import { CabinetsInterface } from '@src/features/cabinets/domain/entities/cabinets.interface';
import { CabinetsSelectors } from '@src/features/cabinets/domain/redux/selectors/cabinets.selectors';
import { CabinetFeesForm } from '@src/shared/forms/cabinet/cabinet-fees.form';

@UntilDestroy()
@Component({
  selector: 'app-agency-fees-container',
  templateUrl: './agency-fees-container.component.html',
  styleUrl: './agency-fees-container.component.scss',
})
export class AgencyFeesContainerComponent implements OnInit {
  cabinetId$ = this.store.select(AuthenticationSelectors.userCabinetId);
  cabinetId!: number;
  cabinet!: CabinetsInterface;
  cabinetFeesForm!: FormGroup;
  cabinetFeesFormBuilder!: CabinetFeesForm;

  constructor(private store: Store) {}

  ngOnInit() {
    this.fetchCabinet();
    this.initializeForm();
  }

  private fetchCabinet() {
    this.cabinetId$.subscribe(cabinetId => {
      if (cabinetId) this.cabinetId = cabinetId;
    });
    this.store
      .select(CabinetsSelectors.getCabinetById(this.cabinetId))
      .pipe(untilDestroyed(this))
      .subscribe(cabinet => {
        if (cabinet) {
          this.cabinet = cabinet;
        }
      });
  }

  private initializeForm() {
    this.cabinetFeesFormBuilder = new CabinetFeesForm(
      this.cabinetId.toString()
    );
    this.cabinetFeesForm = this.cabinetFeesFormBuilder.createFormGroup();
    this.cabinetFeesForm.patchValue(this.cabinet);
  }

  updateFees() {
    console.log(this.cabinetFeesForm.value);
  }
}
