import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BottomNavComponent } from './bottom-nav/bottom-nav.component';
import { NavbarComponent } from './navbar/navbar.component';
import { MatBadge } from '@angular/material/badge';
import { RouterLink } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { ConsultantModule } from '@features/consultant/consultant.module';
import { OpenedMenuComponent } from './opened-menu/opened-menu.component';
import { ProspectionBottomMenuComponent } from './bottom-nav/prospection-bottom-menu/prospection-bottom-menu.component';
import { AcquereurBottomMenuComponent } from './bottom-nav/acquereur-bottom-menu/acquereur-bottom-menu.component';
import { MandatUpdateBottomMenuComponent } from './bottom-nav/mandat-update-bottom-menu/mandat-update-bottom-menu.component';
import { MandatListBottomMenuComponent } from './bottom-nav/mandat-list-bottom-menu/mandat-list-bottom-menu.component';
import { DefaultBottomMenuComponent } from './bottom-nav/default-bottom-menu/default-bottom-menu.component';

@NgModule({
  declarations: [
    NavbarComponent,
    BottomNavComponent,
    OpenedMenuComponent,
    ProspectionBottomMenuComponent,
    AcquereurBottomMenuComponent,
    MandatUpdateBottomMenuComponent,
    MandatListBottomMenuComponent,
    DefaultBottomMenuComponent,
  ],
  exports: [NavbarComponent, BottomNavComponent],
  imports: [
    CommonModule,
    NgOptimizedImage,
    BrowserAnimationsModule,
    MatBadge,
    RouterLink,
    MatMenuModule,
    MatButtonModule,
    MatIcon,
    ConsultantModule,
  ],
})
export class NavbarModule {}
