import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-company-card-edit-mandat',
  templateUrl: './company-card.component.html',
  styleUrls: ['./company-card.component.scss'],
})
export class CompanyCardComponent {
  @Input() raisonSociale!: string;
  @Input() sirenFormate!: string;
  @Input() adresseLigne1!: string;
  @Input() adresseLigne2!: string;
  @Input() ville!: string;
  @Input() codePostal!: string;
  @Input() libelleCodeNaf!: string;
  @Output() editCompany = new EventEmitter();
  @Output() noCompany = new EventEmitter();

  constructor() {}

  companyChange() {
    this.editCompany.emit();
  }

  noCompanyChange() {
    this.noCompany.emit();
  }
}
