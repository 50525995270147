import { CdkScrollable } from '@angular/cdk/overlay';
import {
  CdkFixedSizeVirtualScroll,
  CdkVirtualScrollViewport,
} from '@angular/cdk/scrolling';
import {
  CdkStep,
  CdkStepperNext,
  CdkStepperPrevious,
} from '@angular/cdk/stepper';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocomplete, MatOption } from '@angular/material/autocomplete';
import { MatBadge } from '@angular/material/badge';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRecycleRows,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableModule,
} from '@angular/material/table';
import { ConsultantModule } from '@features/consultant/consultant.module';
import { MandatStateColorDirective } from '@features/mandats/adapters/primary/directives/mandat-state-color.directive';
import { MandatStateDisplayerPipe } from '@features/mandats/adapters/primary/pipes/mandat-state-displayer.pipe';
import { MandatStateShortenedDisplayerPipe } from '@features/mandats/adapters/primary/pipes/mandat-state-shortened-displayer.pipe';

import { ThousandsSeparatorPipe } from '@features/mandats/adapters/primary/pipes/thousands-separator.pipe';
import { ArchivedMandatsFilterComponent } from '@features/mandats/adapters/primary/ui/components/archived/archived-mandats-filter/archived-mandats-filter.component';
import { ArchivedMandatsTableComponent } from '@features/mandats/adapters/primary/ui/components/archived/archived-mandats-table/archived-mandats-table.component';
import { MandatActiveTableComponent } from '@features/mandats/adapters/primary/ui/components/mandat-list/mandat-active-table/mandat-active-table.component';
import { MandatHeaderComponent } from '@features/mandats/adapters/primary/ui/components/mandat-list/mandat-header/mandat-header.component';
import { MandatSearchTabComponent } from '@features/mandats/adapters/primary/ui/components/mandat-list/mandat-search-tab/mandat-search-tab.component';
import { MandatDetailBailleurComponent } from '@features/mandats/adapters/primary/ui/components/preview/mandat-detail-bailleur/mandat-detail-bailleur.component';
import { MandatDetailCardMoraleComponent } from '@features/mandats/adapters/primary/ui/components/preview/mandat-detail-card-morale/mandat-detail-card-morale.component';
import { MandatDetailCardPhysiqueComponent } from '@features/mandats/adapters/primary/ui/components/preview/mandat-detail-card-physique/mandat-detail-card-physique.component';
import { MandatDetailListItemComponent } from '@features/mandats/adapters/primary/ui/components/preview/mandat-detail-list-item/mandat-detail-list-item.component';
import { MandatDetailMandantComponent } from '@features/mandats/adapters/primary/ui/components/preview/mandat-detail-mandant/mandat-detail-mandant.component';
import { MandatDetailMobileHeaderComponent } from '@features/mandats/adapters/primary/ui/components/preview/mandat-detail-mobile-header/mandat-detail-mobile-header.component';
import { MandatDetailSalePointComponent } from '@features/mandats/adapters/primary/ui/components/preview/mandat-detail-sale-point/mandat-detail-sale-point.component';
import { MandatDetailSubHeaderComponent } from '@features/mandats/adapters/primary/ui/components/preview/mandat-detail-sub-header/mandat-detail-sub-header.component';
import { MandatDetailViewContainerComponent } from '@features/mandats/adapters/primary/ui/containers/mandat-detail-view-container/mandat-detail-view-container.component';
import { MandatUpdateContainerComponent } from '@features/mandats/adapters/primary/ui/containers/mandat-update-container/mandat-update.container.component';
import { MandatsContainerComponent } from '@features/mandats/adapters/primary/ui/containers/mandats-container/mandats-container.component';
import { MandatsApiService } from '@features/mandats/adapters/secondary/real/mandats-api.service';
import { MandatApiPort } from '@features/mandats/domain/ports/api/mandat-api.port';
import { ActiviteIconsModule } from '@libs/activite-icons/activite-icons.module';
import { BtnModule } from '@libs/btn/btn.module';
import { AppCitySearchModule } from '@libs/city-search';
import { AppCompanyCardModule } from '@libs/company-card';
import { AppContactCardModule } from '@libs/contact-card/contact-card.module';
import { GooglePlacesAutocompleteComponent } from '@libs/google-places-autocomplete/google-places-autocomplete.component';
import { InputSlideModule } from '@libs/input-slide/input-slide.module';
import { AppInputModule } from '@libs/input/input.module';
import { SearchBarModule } from '@libs/search-bar/search-bar.module';
import { SearchPappersModule } from '@libs/search-pappers/search-pappers.module';
import { AppSelectContactModule } from '@libs/select-contact';
import { SelectModule } from '@libs/select/select.module';
import { ActivitiesNamePipe } from '@src/shared/activites/pipe/activities-name.pipe';
import { CotationColorDirective } from '@src/shared/cotation/cotation-color.directive';
import { DisableScrollDirective } from '@src/shared/no-scroll/disable-scroll.directive';
import { BooleanToYesOrNoPipe } from '@src/shared/pipes/boolean-to-yes-or-no.pipe';
import { BusinessStatePipe } from '@src/shared/pipes/business-state.pipe';
import { ExclusivePipe } from '@src/shared/pipes/exclusive.pipe';
import { FormatAreaPipe } from '@src/shared/pipes/format-area.pipe';
import { FormatEuroWithNullPipe } from '@src/shared/pipes/format-euro-with-null.pipe';
import { FormatEuroPipe } from '@src/shared/pipes/format-euro.pipe';
import { IdToLegalFormPipe } from '@src/shared/pipes/formule-juridique.pipe';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ActivateMandatPopUpComponent } from './adapters/primary/ui/components/activate-mandat-pop-up/activate-mandat-pop-up.component';
import { AnnonceComponent } from './adapters/primary/ui/components/create/annonce/annonce.component';
import { BailleurCompaniesComponent } from './adapters/primary/ui/components/create/bailleur-companies/bailleur-companies.component';
import { BailleurContactComponent } from './adapters/primary/ui/components/create/bailleur-contact/bailleur-contact.component';
import { CheckingSalePointComponent } from './adapters/primary/ui/components/create/checking-sale-point/checking-sale-point.component';
import { DiffusionComponent } from './adapters/primary/ui/components/create/diffusion/diffusion.component';
import { FinancialComponent } from './adapters/primary/ui/components/create/financial/financial.component';
import { GeneralInformationComponent } from './adapters/primary/ui/components/create/general-information/general-information.component';
import { MandantCompaniesComponent } from './adapters/primary/ui/components/create/mandant-companies/mandant-companies.component';
import { MandantContactComponent } from './adapters/primary/ui/components/create/mandant-contact/mandant-contact.component';
import { MandatCreateStepperComponent } from './adapters/primary/ui/components/create/mandat-create-stepper/mandat-create-stepper.component';
import { PriceComponent } from './adapters/primary/ui/components/create/price/price.component';
import { SalePointComponent } from './adapters/primary/ui/components/create/sale-point/sale-point.component';
import { MandatDetailBodaccComponent } from './adapters/primary/ui/components/preview/mandat-detail-bodacc/mandat-detail-bodacc.component';
import { MandatDetailDiffusionComponent } from './adapters/primary/ui/components/preview/mandat-detail-diffusion/mandat-detail-diffusion.component';
import { MandatDetailVisitesComponent } from './adapters/primary/ui/components/preview/mandat-detail-visites/mandat-detail-visites.component';
import { ArchivedMandatsContainerComponent } from './adapters/primary/ui/containers/archived-mandats.container/archived-mandats.container.component';
import { MandatCreateContainerComponent } from './adapters/primary/ui/containers/mandat-create-container/mandat-create-container.component';
import { MandatsRoutingModule } from './mandats-routing.module';
import { ArchivedMandatsDetailComponent } from './adapters/primary/ui/components/archived/archived-mandats-detail/archived-mandats-detail.component';
import { LessorsContainerComponent } from './adapters/primary/ui/containers/lessors-container/lessors-container.component';
import { LessorTableComponent } from './adapters/primary/ui/components/lessors/lessor-table/lessor-table.component';

@NgModule({
  imports: [
    NgxSkeletonLoaderModule.forRoot({
      animation: 'progress-dark',
      loadingText: 'This item is actually loading...',
      theme: {
        height: '36px',
      },
    }),
    CommonModule,
    MandatsRoutingModule,
    ActivitiesNamePipe,
    CotationColorDirective,
    ReactiveFormsModule,
    FormsModule,
    SearchBarModule,
    DisableScrollDirective,
    NgOptimizedImage,
    BtnModule,
    AppInputModule,
    SelectModule,
    ActiviteIconsModule,
    FormatEuroPipe,
    MandatStateDisplayerPipe,
    MandatStateColorDirective,
    InputSlideModule,
    ConsultantModule,
    MatIcon,
    CdkStepperNext,
    CdkStepperPrevious,
    MatCheckbox,
    CdkStep,
    SearchPappersModule,
    GooglePlacesAutocompleteComponent,
    FormatEuroWithNullPipe,
    FormatAreaPipe,
    BooleanToYesOrNoPipe,
    IdToLegalFormPipe,
    ExclusivePipe,
    CdkScrollable,
    AppCitySearchModule,
    MatAutocomplete,
    MatOption,
    MatBadge,
    AppSelectContactModule,
    MatButton,
    MatFormField,
    MatInput,
    ThousandsSeparatorPipe,
    MandatStateShortenedDisplayerPipe,
    AppContactCardModule,
    AppCompanyCardModule,
    BusinessStatePipe,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRecycleRows,
    MatRow,
    MatRowDef,
    MatTable,
    CdkFixedSizeVirtualScroll,
    CdkVirtualScrollViewport,
    MatTableModule,
  ],
  declarations: [
    MandatsContainerComponent,
    MandatSearchTabComponent,
    MandatActiveTableComponent,
    MandatHeaderComponent,
    MandatCreateStepperComponent,
    MandatCreateContainerComponent,
    GeneralInformationComponent,
    MandantContactComponent,
    MandantCompaniesComponent,
    BailleurCompaniesComponent,
    BailleurContactComponent,
    SalePointComponent,
    FinancialComponent,
    PriceComponent,
    AnnonceComponent,
    DiffusionComponent,
    CheckingSalePointComponent,
    MandatDetailMobileHeaderComponent,
    MandatDetailMandantComponent,
    MandatDetailListItemComponent,
    MandatDetailCardPhysiqueComponent,
    MandatDetailCardMoraleComponent,
    MandatDetailBailleurComponent,
    MandatDetailSubHeaderComponent,
    MandatDetailSalePointComponent,
    MandatDetailViewContainerComponent,
    MandatDetailBodaccComponent,
    MandatDetailDiffusionComponent,
    MandatDetailVisitesComponent,
    MandatUpdateContainerComponent,
    ActivateMandatPopUpComponent,
    ArchivedMandatsContainerComponent,
    ArchivedMandatsFilterComponent,
    ArchivedMandatsTableComponent,
    ArchivedMandatsDetailComponent,
    LessorsContainerComponent,
    LessorTableComponent,
  ],
  exports: [MandatsContainerComponent],
  providers: [{ provide: MandatApiPort, useClass: MandatsApiService }],
})
export class MandatsModule {}
