import { Injectable } from '@angular/core';
import { MandatApiPort } from '@features/mandats/domain/ports/api/mandat-api.port';
import {
  ArchivateMandat,
  LoadSilenceArchivedMandats,
} from '@features/mandats/domain/redux/actions/archived-mandats.actions';
import { LoadSilenceMandats } from '@features/mandats/domain/redux/actions/mandats.actions';
import { MandatStateModel } from '@features/mandats/domain/redux/state/mandats.state';
import { NavigateToPreviousUrl } from '@features/nav/domain/redux/actions/nav.actions';
import { SweetAlertService } from '@libs/sweet-alert/sweet-alert.service';
import { Action, NgxsOnInit, State, StateContext } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import { ArchivateMandatInterface } from '@features/mandats/domain/entities/archivate-mandat.interface';

export class ArchivedMandatStateModel {
  archivedMandats: ArchivateMandatInterface[] | undefined;
}

export const defaultArchivedMandatState: ArchivedMandatStateModel = {
  archivedMandats: [],
};

@State<ArchivedMandatStateModel>({
  name: 'archivedMandats',
  defaults: defaultArchivedMandatState,
})
@Injectable()
export class ArchivedMandatState implements NgxsOnInit {
  constructor(
    public mandatService: MandatApiPort,
    private sweetAlertService: SweetAlertService
  ) {}

  ngxsOnInit(ctx: StateContext<MandatStateModel>) {
    ctx.dispatch(new LoadSilenceArchivedMandats());
  }

  @Action(LoadSilenceArchivedMandats)
  async loadSilenceArchivedMandats(
    ctx: StateContext<ArchivedMandatStateModel>
  ): Promise<void> {
    try {
      const archivedMandats = await lastValueFrom(
        this.mandatService.getArchivedMandats()
      );
      ctx.patchState({
        archivedMandats: archivedMandats,
      });
    } catch (error) {
      console.error('Erreur lors du chargement des mandats archivés', error);
    }
  }

  @Action(ArchivateMandat)
  async archivateMandat(
    ctx: StateContext<ArchivedMandatStateModel>,
    action: ArchivateMandat
  ): Promise<void> {
    try {
      const result = await this.sweetAlertService.showConfirmationAlert(
        'Voulez-vous vraiment archiver ce mandat ?',
        {
          text: 'Une fois archivé, le mandat ne pourra plus être modifié',
          confirmButtonText: 'Oui, je veux archiver',
        }
      );

      if (result.isConfirmed) {
        this.mandatService.archivateMandat(action.payload);
        this.sweetAlertService.showToastSuccess('Le mandat a bien été archivé');
        ctx.dispatch(new LoadSilenceArchivedMandats());
        ctx.dispatch(new LoadSilenceMandats());
        setTimeout(() => {
          ctx.dispatch(new NavigateToPreviousUrl());
        }, 1000);
      }
    } catch (error) {
      console.error("Erreur lors de l'archivage du mandat", error);
      this.sweetAlertService.showToastError(
        "Une erreur s'est produite lors de l'archivage du mandat"
      );
    }
  }
}
